/* https://github.com/vercel/next.js/issues/16630#issuecomment-1037305875 */
/* Very interesting workaround, DO NOT REMOVE THIS */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "bootstrap.min.css";
@import "swiper-bundle.min.css";

@font-face {
    font-family: 'Century Gothic';
    src: url('../font/CenturyGothic.eot');
    src: url('../font/CenturyGothic.eot?#iefix') format('embedded-opentype'),
        url('../font/CenturyGothic.woff2') format('woff2'),
        url('../font/CenturyGothic.woff') format('woff'),
        url('../font/CenturyGothic.ttf') format('truetype'),
        url('../font/CenturyGothic.svg#CenturyGothic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic';
    src: url('../font/CenturyGothic-Bold.eot');
    src: url('../font/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
        url('../font/CenturyGothic-Bold.woff2') format('woff2'),
        url('../font/CenturyGothic-Bold.woff') format('woff'),
        url('../font/CenturyGothic-Bold.ttf') format('truetype'),
        url('../font/CenturyGothic-Bold.svg#CenturyGothic-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

:root{
    --primaryColor: #bb9b6a;
    --secondaryColor: #0e1f33;

    --colorWhite: #ffffff;
    --colorGold: #bb9b6a;
    --colorBlue: #102945;
    --colorDarkBlue: #0e1f33;
    --colorBlack: #1e1e20;

    --colorRed: #ff0000;
    --colorGrey: #9f9f9f;
    --colorGreen: #00d459;
    --colorDarkGreen: #43a047;
    /* --linearGradient:  */

    --fontSize1: 40px;
    --fontSize2: 30px;
    --fontSize3: 24px;
    --fontSize4: 18px;
    --fontSize5: 16px;
    --fontSize6: 14px;
}

body {
    background: #f2f2f2;
    font-family: 'Century Gothic';
    font-weight: normal;
}

a.active,
a:focus,
button:focus,
button.active {
    outline: none;
}

a {
    text-decoration: none;
}

a:focus,
a:hover,
a:active {
    outline: 0;
    box-shadow: none;
}

a:focus,
a:hover {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-family: 'Century Gothic';
}

a {
    text-decoration: none;
    transition: all .5s ease-in-out;
}

a:hover {
    text-decoration: none;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul li {
    list-style: none;
}

.article-content ul,
.article-content ol {
    margin: unset;
    padding: unset;
    /* list-style: unset; */
    padding-left: 2rem;
}

.article-content ol {
    list-style: decimal;
}

.article-content ul {
    list-style: disc;
}

.article-content a {
    text-decoration: underline;
}

.article-content ul li {
    list-style: unset;
}

p {
    margin: 0;
    padding: 0;
}

input:focus,
select:focus,
textarea:focus {
    outline: none;
}

img {
    border-style: none;
    display: inline-block;
    max-width: 100%;
    height: auto;
}
.wrapper {
    overflow: hidden;
}
.navbar-toggler:focus{
    box-shadow: unset;
}
/***** Custom Use *****/

.custom_container{
    max-width: 1370px;
    padding:0 50px;
    margin: 0 auto;
}
.custom_container_withoutPadding{
    max-width: 1370px;
    margin: 0 auto;
}
.custom_container2{
    max-width: 1150px;
    padding:0 50px;
    margin: 0 auto;
}
.custom_container2_withoutPadding{
    max-width: 1150px;
    margin: 0 auto;
}
.custom_minHeight{
    min-height: calc(100vh - 119px - 3rem - 410px);
}
.custom_colorPrimary{
    color: var(--primaryColor);
}
.custom_colorSecondary{
    color: var(--secondaryColor);
}
.custom_colorWhite{
    color: var(--colorWhite)
}
.custom_colorRed{
    color: var(--colorRed)
}
.custom_colorGrey{
    color: var(--colorGrey);
}
.custom_colorGreen{
    color: var(--colorGreen);
}
.custom_colorDarkGreen{
    color: var(--colorDarkGreen)
}


.custom_title_icon_size{
    width: 90px;
}
.custom_icon_size{
    width: 75px;
}
.custom_icon_size_small{
    width: 40px;
}
.custom_h1{
    font-size: 40px;
    line-height: 1.3;
    font-weight: bold;
}
.custom_h2{
    font-size: 53px;
    line-height: 1.3;
    font-weight: bold;
}
.custom_h3{
    font-size: 40px;
    line-height: 1.3;
    font-weight: bold;
}
.custom_h4{
    font-size: 30px;
    line-height: 1.3;
    font-weight: bold;
}
.custom_p{
    font-size: 30px;
    line-height: 1.3;
}
.custom_p2{
    font-size: 18px;
    line-height: 1.3;
}
.custom_p3{
    font-size: 14px;
    line-height: 1.3;
}
.custom_bottom_border_gold{
    border-bottom: 3px solid #bb9b6a;
}
.custom_border_gold_withWidth{
    width: 150px;
    border-bottom: 3px solid #bb9b6a;
    /* display: flex; */
    margin: -50px auto 20px;
}

/* custom - accordion */
.custom_accordion{
    border: unset;
}
.custom_accordion .accordion-button{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 5px;
}
.custom_accordion .accordion-button:focus{
    border-color: unset;
    box-shadow: unset;
}
.custom_accordion .accordion-button > h4:first-child{
    flex: 1;
}
.custom_accordion .accordion-button:not(.collapsed){
    box-shadow: unset;
}
.custom_accordion .accordion-button:after{
    background-image: unset;
}
.custom_accordion .accordion-button:not(.collapsed):after{
    background-image: unset;
}
.custom_accordion .accordion-button .custon_accordion_icon_close, .custom_accordion .accordion-button:not(.collapsed) .custon_accordion_icon_open{
    display: none
}
.custom_accordion .accordion-button .custon_accordion_icon_open, .custom_accordion .accordion-button:not(.collapsed) .custon_accordion_icon_close{
    display: block;
}
.custom_accordion .accordion-body {
    padding: 16px 5px;
}

h1{
    font-size: var(--fontSize1);
    line-height: 1.3;
    font-weight: bold;
}
h2{
    font-size: var(--fontSize2);
    line-height: 1.3;
    font-weight: bold;
}
h3{
    font-size: var(--fontSize3);
    line-height: 1.3;
    font-weight: bold;
}
h4{
    font-size: var(--fontSize4);
    line-height: 1.3;
    font-weight: bold;
}
h5{
    font-size: var(--fontSize5);
    line-height: 1.3;
    font-weight: bold;
}
h6{
    font-size: var(--fontSize6);
    line-height: 1.3;
    font-weight: bold;
}

p{
    font-size: var(--fontSize4);
    line-height: 1.3;
}
.custom_p2{
    font-size: var(--fontSize5);
    line-height: 1.3;
}
.custom_p3{
    font-size: var(--fontSize6);
    line-height: 1.3;
}
/****** HEADER *****/
header {
    border-bottom: 2px solid #bb9b6a;
    padding: 40px 0;
    box-shadow: 1px 6px 20px 6px rgb(0 0 0 / 10%);
    z-index: 100;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}
.header-left-empty-content {
    width: 20%;
    min-width: 100px;
}
.navigation {
    flex: 1;
}
.navigation .navbar {
    padding: 0;
    margin: 0;
    flex: 1;
}
.navbar-brand {
    padding: 0;
    margin: 0 100px;
}
.navbar-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 0.5;
}
.navbar-nav .nav-item {
    position: relative;
    width: 50%;
    display: flex;
    /* padding: 9px 0; */
}
.navbar-nav-left .nav-item{
    justify-content: center;
}
.navbar-nav-right .nav-item{
    justify-content: center;
}
.navbar-nav .nav-item:last-child {
    margin: 0;
}
.navbar-nav .nav-item .nav-link {
    padding: 9px 0;
    color: #0e1f33;
    font-weight: normal;
    font-size: var(--fontSize5);
    cursor: pointer;
}
.nav-link.disabled {
    color: #6c757d !important;
}
.navbar-nav .nav-item .nav-link:hover {
    color: #bb9b6a;
}
.navbar-nav .nav-item:hover .submenu{
    display: block;
}
.submenu {
    position: absolute;
    left: 55%;
    /* top: 35px; */
    top: 100%;
    background-color: #fff;
    width: auto;
    padding: 10px 0px;
    transform: translate(-50%, 0);
    display: none;
    min-width: 205px;
}
.submenu ul li a {
    color: #0e1f33;
    font-size: var(--fontSize5);
    font-weight: normal;
    padding: 12px 60px;
    display: block;
}
.submenu ul li a:hover{
    color: #bb9b6a;
}
.navbar-nav-right {
    padding: 0;
}
.navbar-expand-xl .navbar-collapse {
    flex: 1;
}
.header-right {
    margin: 0 -45px 0 0px;
}
.header-right ul {
    display: flex;
    align-items: center;
}
.diamond-login {
    width: 85px;
}
.diamond-user {
    background-color: #bb9b6a;
    display: flex;
    justify-content: space-between;
    border-top-right-radius: 10%;
    border-bottom-right-radius: 10%;
    /* font-size: 18px; */
}
.diamond-user .diamond-icon {
    margin-left: -17px;
}
.diamond-user .diamond-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}
.diamond-content .diamond-amount {
    width: 100%;
    text-align: center;
}
.diamond-content .diamond-plus {
    margin-left: 10px;
    margin-right: 5px;
}
.navbar-right-menu {
    width: 20%;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    min-width: 100px;
}
.navbar-right-menu-icon {
    width: 30px;
}

/****** GENERAL *****/
.page-content {
    background-image: linear-gradient(rgba(255, 247, 232) 0%, rgb(255, 255, 255, 0) 30%);
    background-image: -moz-linear-gradient(rgba(255, 247, 232) 0%, rgb(255, 255, 255, 0) 30%);
    background-image: -webkit-linear-gradient(rgba(255, 247, 232) 0%, rgb(255, 255, 255, 0) 30%);
    background-image: -ms-linear-gradient(rgba(255, 247, 232) 0%, rgb(255, 255, 255, 0) 30%);
}
.close-btn img {
    height: 40px;
}
.btn-default {
    background-color: #bb9b6a;
    height: 77px;
    width: 210px;
    font-size: 25.57px;
    font-style: normal;
    display: flex;
    align-items: center;
    border-radius: 10px;
    color: #fff;
    justify-content: center;
    margin-left: 25px;
}
.general-box {
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.11);
    border-radius: 15px;
    padding: 68px 70px 45px;
    /* background-color: #fff; */
    margin: 40px 0;
    background: #fef9e8;
    background: -moz-linear-gradient(top,  #fef9e8 0%, #ffffff 100%);
    background: -webkit-linear-gradient(top,  #fef9e8 0%,#ffffff 100%);
    background: linear-gradient(to bottom,  #fef9e8 0%,#ffffff 100%);
}
.general-box h1 {
    color: #bb9b6a;
    font-size: var(--fontSize1);
    font-weight: bold;
    margin: 0 0 21px 0;
}
.general-box p {
    color: #0e1f33;
    font-size: 30px;
    font-weight: 400;
    line-height: 1.2;
}
/* .accordion-button::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
} */

/****** BREADCRUMB *****/
.breadcrumb .breadcrumb-item a {
    color: #0b0b0b;
    font-size: 23.83px;
    font-weight: normal;
    line-height: 1;
    /* text-transform: uppercase; */
}
.breadcrumb .breadcrumb-item a:hover {
    color: #bb9b6a;
}
.breadcrumb .breadcrumb-item {
    color: #0b0b0b;
    font-size: 23.83px;
    line-height: 1;
    font-weight: normal;
    /* text-transform: uppercase; */
}
ol.breadcrumb::-webkit-scrollbar {
    display: none;
}
ol.breadcrumb {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.breadcrumb-item+.breadcrumb-item::before {
    /* float: left; */
    padding-right: 0;
    /* color: #0b0b0b; */
    content: '';
    font-size: 14px;
    /* content: ">"; */
    /* font-size: 23.83px; */
}

/****** HOMEPAGE *****/
.homepage-sec-banner-image {
    width: 170px;
    margin: 0 auto 20px;
}
.homepage-sec-banner-title{
    max-width: 500px;
    margin: 0 auto;
}
.homepage-img-trust {
    width: 80%;
}
.homepage-content-top-title {
    font-weight: bold;
    color: #bb9b6a;
    font-size: 45px;
}
.homepage-content-top {
    font-size: 26px;
}
.homepage-content-middle-title {
    margin: 0 auto;
    font-weight: bold;
    font-size: 45px;
}
.homepage-content-middle-box-left {
    overflow: hidden;
    border: 1px solid #dee2e6;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
}
.homepage-content-middle-box-layout-left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fde7c6;
}
.homepage-content-middle-subtitle {
    margin: 0 auto;
    font-weight: bold;
    font-size: 40px;
    color: #bb9b6a;
}
.homepage-content-middle-text {
    font-size: 22px;
}
.homepage-content-feature {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #dee2e6;
}
.homepage-check {
    color: #43a047;
    font-size: 22px;
    line-height: 1.6;
}
.homepage-content-middle-box-right {
    overflow: hidden;
    border: 1px solid #dee2e6;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}
.homepage-content-middle-box-layout-right {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fde7c6;
}
.homepage-faq {
    font-size: 26px;
}
.homepage-faq-ans {
    font-size: 20px;
}
.homepage-swiper .swiper{
    padding-bottom: 50px;
}
.homepage-swiper .swiper-pagination-bullet{
    width: 10px;
    height: 10px;
    margin: 0 10px;
}
.homepage-swiper .swiper-pagination-bullet-active{
    background-color: var(--colorGold);
}
.homepage-bonus-swiper .swiper {
    max-height: 80px;
}
.homepage-bonus-latest-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    width: 100%;
    border: 2px solid #ececec;
    border-radius: 10px;
    padding: 10px 30px;
}
.homepage-bonus-latest-img {
    width: 50px;
}
.homepage-bonus-title {
    font-size: 16px;
    font-weight: bold;
}
.homepage-bonus-text-green {
    color: #00d100;
}
.homepage-bonus-text-yellow {
    color: #d1d100;
}
.homepage-bonus-text-red {
    color: #d10000;
}
.homepage-bonus-text-black {
    color: #000000;
}
.homepage-bonus-button {
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: var(--colorGreen);
    font-size: 14px;
    color: var(--colorWhite);
}
.homepage-bonus-button:hover {
    background-color: var(--colorDarkGreen);
}
.homepage-recent-company-sec{
    margin: 30px 0;
}
.homepage-recent-company-content{
    display: flex;
    padding-top: 20px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
}
.homepage-recent-company-box{
    padding: 5px;
    width: 100%;
}
.homepage-recent-company-box-inner{
    background-color: white;
    border-radius: 10px;
    width: 100%;
    padding: 20px;
    position: relative;
    overflow: hidden;
    width: 100%;
    background-image: linear-gradient(rgba(255, 255, 255) 0%, rgba(241, 241, 241) 30%);
    box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.3);
    -webkit-box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.3);
}
.homepage-recent-company-box-content{
    display: flex;
}
.homepage-recent-company-logo{
    width: 170px;
    margin-right: 15px;
}
.homepage-recent-company-detail{
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 10px;
}
.homepage-recent-company-title{
    font-weight: 700;
    font-size: 28px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.homepage-recent-company-star-box{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
}
.homepage-recent-company-star{
    display: flex;
    width: fit-content;
}
.homepage-recent-company-star li{
    width: 30px;
}
.homepage-recent-company-diamond-box{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.homepage-recent-company-diamond{
    max-width: 45px;
}
.homepage-recent-company-diamond-amt{
    font-size: 32px;
    padding-left: 3px;
}
.homepage-recent-company-button-box{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}
.homepage-recent-company-button{
    border: none;
    background-color: var(--primaryColor);
    border-radius: 10px;
    font-size: 20px;
    color: white;
    padding: 15px 20px;
    font-weight: 700;
}
.homepage-recent-company-button:hover{
    background-color: var(--colorBlue);
}
.homepage-recent-company-tag{
    position: absolute;
    top: 10px;
    right: 0;
    width: 100px;
    transform: translate(25px, 0px) rotate(45deg);
    background-color: #f22300;
    text-align: center;
    color: white;
    font-weight: 700;
    font-size: 18px;
}
.homepage-latest-article{
    margin-top: 30px;
    /* background-color: #102843; */
    padding-top: 30px;
    padding-bottom: 50px;
}
.homepage-latest-article-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.homepage_title{
    font-size: var(--fontSize1);
}
.homepage-view-more{
    text-decoration: underline;
    color: #1b1b1b;
}
.homepage-latest-article-highlight{
    display: flex;
    margin-top: 10px;
    grid-gap: 20px;
    margin-bottom: 10px;
}
.homepage-article-highlight-inner{
    flex: 0.5;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
}
.homepage-article-title{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1b1b1b;
}
.homepage-article-highlight-content{
    display: flex;
    grid-gap: 10px;
    flex-direction: column;
}
.homepage-article-paragaph{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #1b1b1b;
}
.homepage-article-highlight-datetime{
    display: flex;
    justify-content: space-between;
}
.homepage-article-remaining{
    flex: 0.5;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
}
.homepage-article-box{
    display: flex; 
    grid-gap: 5px;
}
.homepage-article-box-left{
    flex: 0.3
}
.homepage-article-box-right{
    flex: 0.7
}
.homepage-article-box-content{
    align-items: space-between;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: flex-end;
}
.homepage-article-box-title{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}
.homepage-rank-box{
    /* border-radius: 15px; */
    /* margin: 0 10px; */
    /* background: #ffffff; */
    /* padding: 10px 0; */
    /* width: 100%; */
    /* background-image: linear-gradient(180deg, #ffffff ,#f1f1f1); */
    /* box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%); */
    position: relative;
}
.homepage-ranking-sec{
    margin-top: 30px;
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 50px;
    margin-bottom: 50px;
}
.homepage-ranking-box{
    flex: 0.5 1;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
}
.homepage-rank-box-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.homepage-rank-box-row{
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid #1b1b1b;
}
.homepage-rank-box-row:last-child{
    border-bottom: none;
}
.homepage-rank-box-content{
    display: flex;
    align-items: center;
    width: 100%;
}
.homepage-rank-box-rank{
    flex: 0.1;
    color: var(--colorGold);
}
.homepage-rank-box-rank-title{
    flex: 0.6;
    display: flex;
    align-items: center;
}
.homepage-rank-box-rank-logo{
    width: 40px;
    margin-right: 30px;
}
.homepage-rank-box-rank-logo img{
    border-radius: 50%;
}
.homepage-rank-box-rank-company{
    flex: 1;
    text-align: left;
}
.homepage-rank-box-star{
    flex: 0.3;
    display: flex;
    width: fit-content;
}
.homepage-rank-box-star li{
    flex: 1;
}
.homepage-rank-box-vote-title{
    flex: 0.5;
    display: flex;
    align-items: center;
}
.homepage-rank-box-vote{
    flex: 0.4;
    display: flex;
    width: fit-content;
    justify-content: flex-end;
}
.homepage-rank-box-diamond{
    width: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.homepage-rank-box-diamond-img{
    max-width: 40px;
}

/****** ABOUT US / CONTACT US / TERMS & CONDITIONS *****/
.about-us-inner, 
.contact-us-inner,
.tnc-inner {
    margin: 65px 0;
}
.about-us-inner h1,
.contact-us-inner h1,
.tnc-inner h1 {
    color: #bb9b6a;
    font-size: 50px;
    font-weight: bold;
    line-height: 1;
    margin: 0px 0 50px 0;
}
.about-us-inner h2:first-letter,
.contact-us-inner h2:first-letter,
.tnc-inner h2:first-letter {
    text-transform: uppercase;
}
.about-us-inner .about-us-subtitle h2,
.contact-us-inner .contact-us-subtitle h2,
.tnc-inner .tnc-subtitle h2 {
    color: #0e1f33;
    margin-bottom: 30px;
}
.about-us-inner p,
.contact-us-inner p,
.tnc-inner p {
    color: #0e1f33;
    font-size: 30px;
    font-weight: normal;
    line-height: 1.2;
    padding-bottom: 30px;
}
.about-us-inner p a,
.contact-us-inner p a,
.tnc-inner p a {
    color: #0e1f33;
}
.about-us-with-tick-icon {
    display:flex;
    align-items: flex-start;
    grid-gap: 5px;
}

/***** NEWSLETTER *****/
.call-action-sec {
    padding: 80px 0 60px 0;
}
/* .call-action-left h4 {
    color: #000;
    font-size: 25px;
    font-weight: normal;
} */
.call-action-left form {
    display: flex;
    align-items: center;
    padding: 20px 0 30px 0;
}
.call-action-left form .form-control {
    border: 1px solid #b7b7b7;
    color: #6a6a6a;
    font-size: 20px;
    font-weight: normal;
    border-radius: 10px;
    height: 76px;
    box-shadow: none;
    padding-left: 32px;
    flex: 1;
}
.call-action-left form .form-control:focus {
    border-color: #bb9b6a;
}
.call-action-left-button {
    background-color: #bb9b6a !important;
    height: 77px !important;
    width: 210px !important;
    font-size: 25.57px !important;
    font-style: normal !important;
    display: flex !important;
    align-items: center !important;
    border-radius: 10px !important;
    color: #fff !important;
    justify-content: center !important;
    margin-left: 25px !important;
}
.call-action-left-button:hover {
    background-color: #102945 !important;
    color: #fff !important;
}
.call-action-left p {
    color: #7f7f7f;
    font-size: var(--fontSize6);
    /* font-weight: normal; */
    /* line-height: 1.2; */
}
.follow-us {
    max-width: 265px;
    width: 100%;
    margin-left: auto;
}
/* .follow-us h4 {
    color: #0e1f33;
    font-size: 25px;
    font-weight: normal;
} */
.follow-us ul {
    display: flex;
    align-items: center;
    margin-top: 22px;
}
.follow-us ul li {
    margin-left: 16px;
}
.follow-us ul li:first-child {
    margin: 0;
}
.follow-us ul li img {
    height: 47px;
}

/***** FOOTER *****/
footer {
    border-top: 1px solid #acacac;
    padding: 35px 0 0;
}
footer .footer-content {
    padding-bottom: 35px;
}
footer .footer-content a {
    color: #102945;
}
footer .footer-content a:hover {
    color: #bb9b6a;
}

/***** SEARCH BOX *****/
.search-dropdown {
    background-color: #fff;
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgb(12 12 12 / 11%);
    padding: 70px 32px 65px;
    position: absolute;
    top: 120px;
    z-index: 100;
    right: 0;
    width: 100%;
    display: none;
}
.search-dropdown.active{
    display: block;
}
.search-dropdown form {
    position: relative;
    margin: 0 0 46px 0;
}
.search-dropdown form span{
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    left: 0;
}
.search-dropdown form span img{
    height: 30px;
}
.search-dropdown form .form-control{
    color: #707070;
    font-size: 19.35px;
    font-weight: normal;
    padding: 0 0 0 74px;
    box-shadow: none;
    border: none;
}
.search-dropdown form a{
    display: flex;
    align-items: center;
    top: 50%;
    transform: translate(0,-50%);
    right: 0;
    position: absolute;
}
.search-dropdown .text-box {
    margin: 0 0 70px 0;
}
.search-dropdown .text-box h4{
    color: #0e1f33;
    font-size: 16.89px;
    font-weight: normal;
    margin-bottom: 12px;
}
.search-dropdown .text-box ul{
    display: flex;
    align-items: center;
    margin: 0 -5px;
}
.search-dropdown .text-box ul li{
    padding: 0 5px;
}
.search-dropdown .text-box ul li a{
    border: 1px solid #bb9b6a;
    border-radius: 5px;
    height: 32px;
    color: #0e1f33;
    font-size: 15.4px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 9px;
}
.search-dropdown .text-box ul li a img {
    margin-right: 10px;
    height: 10px;
}

/***** CONTACT US *****/
.contact-form .form-group {
    margin-bottom: 50px;
}
.contact-form .relative {
    position: relative;
    width: 100%;
}
.contact-form .form-control {
    border-radius: 10px;
    height: 50px;
    box-shadow: none;
    font-size: 22px;
    font-weight: 400;
    color: #0e1f33;
    padding-left: 15px;
    /* text-transform: capitalize; */
}
.contact-form .form-control:focus {
    border-color: #bb9b6a;
}
.contact-form .form-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.down-arrow {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 13px;
}
select:focus~.down-arrow img {
    transform: rotate(180deg);
    transition: all 0.5s ease-in-out;
}
.down-arrow img {
    transition: all 0.5s ease-in-out;
}
.contact-form .form-error-content {
    display:flex;
    color: red;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}
.contact-form textarea{
    height: unset !important;
}

/***** GUIDE LISTING PAGE *******/
.guide-sport {
    padding: 35px 70px;
}
.guide-sport-text .media {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 23px 0;
}
.guide-sport-text .media .guide-media-text {
    display: flex;
    align-items: center;
    max-width: 70px;
    white-space: nowrap;
}
.guide-sport-text .media h2 {
    color: #0e1f33;
    font-size: 30px;
    font-weight: bold;
    padding-right: 10px;
}
.guide-sport-text .media a {
    color: #0e1f33;
    font-size: 30px;
    text-decoration: underline;
    font-weight: 400;
}
.guide-sport-text .media a:hover {
    color: #bb9b6a;
}
.guide-sport-text p {
    color: #0e1f33;
    font-size: 30px;
    font-weight: 400;
    line-height: 1.2;
}
.guide-sport-inner {
    margin: 0 -5px;
}
.guide-sport-inner .swiper {
    padding: 10px 5px;
}
.guide-sport-box {
    box-shadow: 1.954px 4.603px 5.76px 0.24px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
}
.guide-sport-box .image-holder img {
    width: 100%;
}
.guide-sport-box .text-box .text-box-inner {
    padding: 13px 20px;
}
.guide-sport-box .text-box .text-box-inner ul {
    display: flex;
    align-items: center;
}
.guide-sport-box .text-box .text-box-inner ul li {
    color: #7f7f7f;
    font-size: 15.61px;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin-right: 20px;
}
.guide-sport-box .text-box .text-box-inner ul li:first-child {
    text-transform: uppercase;
}
.guide-sport-box .text-box .text-box-inner ul li:last-child {
    margin: 0;
}
.guide-sport-box .text-box .text-box-inner ul li img {
    margin-right: 8px;
}
.guide-sport-box .text-box .text-box-inner h3 {
    color: #0e1f33;
    font-size: 30px;
    font-weight: bold;
    margin: 5px 0 8px;
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.guide-sport-box .text-box .text-box-inner p {
    color: #7f7f7f;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.2;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.guide-sport-list {
    border-top: 1px solid #b7b7b7;
    padding: 12px 20px 22px;
}
.guide-sport-list ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap:10px;
    max-height: 75px;
    overflow: hidden;
}
.guide-sport-list ul li {
    /* margin-right: 25px;
    margin-bottom: 10px */
}
.guide-sport-list ul li:last-child {
    margin-right: 0;
}
.guide-sport-list ul li a {
    color: #0e1f33;
    font-size: 16.53px;
    font-weight: normal;
    border: 2px solid #bb9b6a;
    border-radius: 10px;
    /* height: 32px; */
    display: flex;
    padding: 0 5px;
    align-items: center;
    justify-content: center;
    height: auto;
    /* max-height: 64px; */
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.guide-sport-list ul li a:hover {
    background-color: #bb9b6a;
    color: #fff;
}
.guide-swiper-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 30px 0;
}
.guide-swiper-btn .swiper-button-next, .guide-swiper-btn .swiper-button-prev {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    border: 4px solid #bb9b6a;
    background-color: #fff;
    width: 82px;
    height: 73px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    transition: all 0.5s ease-in-out;
    color: #bb9b6a;
    margin-left: 22px;
}
.guide-swiper-btn .swiper-button-next:hover, .guide-swiper-btn .swiper-button-prev:hover {
    background-color: #bb9b6a;
    transition: all 0.5s ease-in-out;
}
.guide-swiper-btn .swiper-button-next:hover img, .guide-swiper-btn .swiper-button-prev:hover img {
    filter: brightness(390.5%);
    transition: all 0.5s ease-in-out;
}
.guide-swiper-btn .swiper-button-next::after, .guide-swiper-btn .swiper-button-prev::after {
    display: none;
}
.guide-clock-icon{
    margin-right: 5px
}
.all-article-sec {
    margin: 30px 0 0 0;
}
.all-article-inner {
    margin: 0;
}
.all-article-inner .guide-sport-box {
    margin: 20px 0;
}
.more-history {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0 0 0;
}
.more-history a img {
    margin-bottom: 19px;
}
.more-history a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #0e1f33;
    font-size: 30.52px;
    font-weight: bold;
    text-transform: uppercase;
}

/***** MONTHLY SPONSORED *******/
.monthly-sponsored-inner a {
    color: #bb9b6a;
}
.monthly-sponsored-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
}
.monthly-sponsored-img {
    max-width: 130px;
    padding-bottom: 10px;
}
.monthly-sponsored-title h2 {
    color: #0e1f33;
    font-size: 30px;
    font-weight: bold;
}
.monthly-sponsored-content {
    padding: 70px;
}
.marketing-parnership-section {
    padding: 0 70px;
}
.marketing-parnership-content {
    display: flex;
    align-items: center;
}
.marketing-parnership-img {
    max-width: 70px;
    padding-left: 10px;
}
.marketing-parnership-content h2 {
    color: #0e1f33;
    font-size: 30px;
    font-weight: bold;
}
.marketing-parnership-content p {
    font-size: 26px;
    font-weight: normal;
}
.marketing-parnership-content:last-child {
    padding-bottom: 50px;
}
/** Added By CM Start **/
.contact-modalOpened {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1055;
    height: fit-content;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 5%;
}
.contact-modalOverlayOpened {
    z-index: 1000;
    position: fixed;
    background-color: rgba(14, 31, 51, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow: auto;
}
.contact-modal .modal-acknowledge {
    border: unset !important;
    border-radius: 20px !important;
}
/**** CONTACT MODAL *****/
.contact-modal .modal-dialog {
    max-width: 1150px;
    /* margin: 30px auto; */
}
.contact-modal .modal-content {
    border: 7px solid #bb9b6a;
    border-radius: 30px;
}
.contact-modal .modal-body {
    position: relative;
    padding: 50px 34px 35px 45px;
}
.contact-modal .modal-body h4 {
    color: #bb9b6a;
    font-weight: bold;
    font-size: 52.97px;
    margin: 0 0 70px 0;
}
.modal-acknowledge-yes-no button {
    height: 70px;
    max-width: 300px;
    width: 100%;
    border-radius: 18px;
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.11);
    font-size: 40px;
    font-weight: normal;
    border: none;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
}
.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    transition: opacity 0.2s ease-out, transform 0.6s ease-out;
    will-change: opacity, visibility;
}
.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}
.fade-in-section-left {
    opacity: 0;
    transform: translateX(-20vw);
    transition: opacity 0.2s ease-out, transform 0.6s ease-out;
    transition-delay: 0.8s;
    will-change: opacity, visibility;
}
.fade-in-section-left.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}
.hover-opacity-100:hover {
    opacity: 1 !important;
}
.btn.btn-default.btn-outline {
    background-color: transparent;
    border: 2px solid rgb(181 149 102);
    color: rgb(181 149 102);
}
.modal-acknowledge-yes-no button.btn-outline:hover {
    background-color: transparent;
    border: 2px solid rgb(16, 41, 69);
    color: rgb(16, 41, 69);
}
.gradient-hover-effect {
    background-size: 500%;
    /* background-image: linear-gradient(to right, rgb(207, 172, 125), rgb(169, 126, 93),rgb(14, 31, 51), rgb(16, 41, 69)); */
    color: #fff;
    /* transition: background-position ease; */
    background-position: left;
}
.gradient-hover-effect:hover, .gradient-hover-effect:focus {
    background-position: right;
    color: white;
    background-color: #102945;
}
/***** REGISTER / LOGIN *******/
.register-sec {
    padding: 80px 0;
    padding-bottom: 100px;
}
.register-success-sec {
    padding: 80px 0;
    padding-bottom: 350px;
}
.login-form-layout {
    /* max-width: 985px; */
    margin: auto;
}
.login-form-size {
    max-width: 785px;
}
.login-form-layout-title {
    font-size: 76px;
    line-height: 75px;
    color: #102945;
    font-weight: bold;
    text-align: center;
    margin-bottom: 44px;
}
.login-form-layout-message {
    font-size: 33px;
    line-height: 33px;
    color: #102945;
    text-align: center;
    margin-bottom: 50px;
}
.login-form-layout-message a {
    color: #2aa7ff;
}
.login-inputs .form-control {
    font-size: 33px;
    line-height: 33px;
    color: #1e1e20;
    padding: 13px 2px;
    border: none;
    border-bottom: 1px solid #acacac;
    background-color: transparent;
}
.login-inputs {
    margin-bottom: 24px;
}
.login-inputs .login-input-space{
    margin-bottom: 30px;
}
.login-inputs .form-check-input {
    background: #00a3ee;
    height: 23px;
    width: 23px;
    margin-right: 10px;
}
.login-inputs .form-check-label {
    font-size: 19px;
    color: #1e1e20;
}
.login-inputs .form-check-label a {
    font-size: 19px;
    color: #1e1e20;
}
.login-inputs .signup-checkbox-info {
    width: 500px;
}
.login-btn-pill {
    width: 308px;
    height: 84px;
    border-radius: 42px;
    background-color: #bb9b6a;
    border: 5px solid #ba9b6a;
    /* background-color: transparent; */
    font-size: 36px;
    line-height: 36px;
    /* color: #ba9b6a; */
    color: #fff;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 30px;
}
.login-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.login-info {
    font-size: 17px;
    color: #7f7f7f;
    width: 500px;
}
.login-input-outline {
    margin-bottom: 20px;
}
.login-input-outline .form-label {
    font-size: 22px;
    line-height: 33px;
    color: #1e1e20;
    margin-bottom: 6px;
}  
.login-input-outline .form-control {
    padding-top: 12px;
    padding-bottom: 7px;
    border-radius: 4px !important;
    border: 1px solid #acacac;
    font-size: 20px;
    line-height: 33px;
    color: #1e1e20;
    padding-left: 23px;
    padding-right: 10px;
}
.login-input-error {
    color: red;
    text-align: right;
    font-size: 15px;
}
.login-input-outline .form-select {
    font-size: 20px;
    line-height: 33px;
    color: #1e1e20;
    padding-top: 12px;
    padding-bottom: 7px;
    border: 1px solid #acacac;
    padding-left: 23px;
    padding-right: 10px;
    background-color: #f2f2f2;
}
.login-input-outline .select-left-input-right {
    font-size: 20px;
    line-height: 33px;
    color: #1e1e20;
}
.login-input-outline .form-select-left {
    padding-top: 12px;
    padding-bottom: 7px;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border: 1px solid #acacac;
    padding-left: 23px;
    padding-right: 10px;
    width: 30%;
}
.login-input-outline .form-control-right {
    padding-top: 12px;
    padding-bottom: 7px;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border: 1px solid #acacac;
    border-left: 0;
    color: #1e1e20;
    padding-left: 23px;
    padding-right: 10px;
    width: 70%;
    background-color: #f2f2f2;
}
.login-btn-pill-bg {
    border-radius: 42px;
    background-color: #ba9b6a;
    border: 5px solid #ba9b6a;
    width: 308px;
    height: 84px;
    font-size: 36px;
    line-height: 36px;
    color: #ffffff;
    text-align: center;
    margin-top: 40px;
    margin-bottom:16px;
}
.login-form-layout-title-otp {
    font-size: 33px;
    line-height: 33px;
    color: #102945;
    text-align: center;
    margin-bottom: 70px;
}
.login-form-layout-message-otp {
    font-size: 33px;
    line-height: 33px;
    color: #1e1e20;
    font-weight: bold;
    margin-bottom: 45px;
}
.verification-content {
    font-size: 28px;
    line-height: 1.5;
    color: #1e1e20;
}
.login-form-layout-message-title-otp {
    font-size: 33px;
    line-height: 33px;
    color: #102945;
    margin-bottom: 70px;
}
.terms-style li a {
    font-size: 17px;
    color: #2aa7ff;
}
.terms-style .divider {
    margin-left: 3px;
    margin-right: 3px;
}
.ms-dd {
    background: transparent !important;
    width: 30% !important;
}
.ms-dd .ms-dd-header {
    border: 1px solid #acacac !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.ms-dd .ms-list-option.option-selected, .ms-dd .ms-optgroup ul .ms-list-option.option-selected {
    background: #f2f2f2 !important;
}
.ms-dd .ms-dd-header .option-selected {
    padding-top: 16px !important;
}
.otp-form {
    margin-top: 65px;
}
.otp-form .title {
    max-width: 400px;
    margin: auto;
    text-align: center;
    margin-bottom: 25px;
}
.otp-input-fields {
    margin: auto;
    background-color: white;
    max-width: 400px;
    width: auto;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 0 40px;
    margin-bottom: 70px;
    /* background-color: #f2f2f2; */
}
.otp-input-fields input {
    width: 73px;
    height: 84px;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #acacac;
    text-align: center;
    outline: none;
    font-size: 28px;
}
.otp-input-fields input::-webkit-outer-spin-button, .otp-input-fields input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.otp-input-fields input[type=number] {
    -moz-appearance: textfield;
}
.otp-input-fields input:focus {
    border-width: 2px;
    border-color: #acacac;
    font-size: 20px;
}
/***** USER PROFILE *******/
.profile-sec {
    padding-bottom: 150px;
}
.profile-content {
    position: relative;
    padding: 81px 0 0 0;
}
.profile-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}
.profile-tab-content {
    padding-top: 130px;
}
.profile-top-section {
    background: #fefefe;
    height: 200px;
    border-radius: 30px;
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.11);
    position: relative;
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
}
.profile-icon {
    border: 5px solid #ffffff;
    background-color: red;
    width: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    max-width: 200px;
    height: 200px;
    margin-left: 0px;
}
.profile-icon img {
    height: 100px;
}
.profile-edit {
    position: absolute;
    bottom: -20px;
}
.profile-edit-img {
    height: 50px;
}
.profile-brief {
    display: flex;
    flex-direction: column;
}
.profile-name {
    padding-top: 40px;
    margin-left: -60px;
    width: 50%;
}
.profile-name h2 {
    font-weight: bold;
    line-height: 1.1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.profile-status {
    display: flex;
    padding-top: 10px;
    margin-left: -40px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.verify-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.verify-phone-verify {
    width: 50px;
    height: 50px;
    background: #1c4470;
    margin-right: 12px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #bb9b6a;
}
.verify-phone-not-verify {
    width: 50px;
    height: 50px;
    background: #cccccc;
    margin-right: 12px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #ffffff;
}
.verify-phone-verified {
    position: absolute;
    right: -4px;
    bottom: -13px;
    color: #43a047;
    font-size: 21px;
}
.verify-text {
    margin-right: 30px;
    font-size: 13px;
}
.verify-text .verify-text-highlight {
    color: #bb9b6a;
    font-weight: 600;
}
.profile-mid-section {
    margin-top: 50px;
    background: #fefefe;
    min-height: 200px;
    border-radius: 30px;
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.11);
    position: relative;
    padding: 70px 56px;
    z-index: 1;
}

/***** USER PROFILE MODAL *******/
.profile-modalOverlayOpened {
    z-index: 1000;
    position: fixed;
    background-color: rgba(248, 248, 248, 0.5);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    overflow: auto;
}
.profile-modal-content {
    margin-top: 70px;
    border-radius: 10px;
    padding: 10px;
    min-height: 200px;
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.11);
    position: relative;
}
.profile-modal-title {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}
.profile-modal-detail {
    margin-top: 30px;
}
.dropzone-profile-input-box-inner {
    border: none !important;
    background-color: #ced4da !important;
    min-height: 180px !important;
}
.dropzone-profile-input-box-inner2 {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    height: 150px !important;
}
.file-upload-label {
    border-radius: 10px;
    padding: 10px;
    width: 100%;
    min-height: 200px;
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgb(12 12 12 / 11%);
    background: #c1c7cf;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    position: relative;
}
.profile-submit-button {
    font-size: 16px !important;
    float: right;
    height: 50px !important;
    width: 180px !important;
    padding-bottom: 0px;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}

/***** PROFILE EDIT PROFILE *******/
.change-password-form .verify-tick-unverified {
    margin-left: -15px;
    margin-top: 10px;
    font-size: 25px;
    color: #acacac;
}
.change-password-form .verify-tick-verified {
    margin-left: -15px;
    margin-top: 10px;
    font-size: 25px;
    color: #43a047;
}

/***** PROFILE CHANGE PASSOWRD *******/
.profile-password h2 {
    color: #b09063;
    font-size: 40px;
    font-weight: normal;
    margin: 0 0 30px 0;
}
.change-password-form .password-form-input {
    color: #0e1f33;
    font-size: 32px;
    font-weight: 400;
}
.change-password-form .form-control {
    padding-top: 12px;
    padding-bottom: 7px;
    border-radius: 4px !important;
    border: 1px solid #acacac;
    font-size: 20px;
    line-height: 33px;
    color: #1e1e20;
    padding-left: 23px;
    padding-right: 10px;
}
.change-password-form .form-error-content {
    display:flex;
    color: red;
    flex-direction: row;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
    font-size: 15px;
}
.profile-button-section {
    text-align: right;
}
.profile-button-pill {
    border-radius: 10px;
    background-color: #ba9b6a;
    border: 5px solid #ba9b6a;
    width: 250px;
    height: 65px;
    font-size: 27px;
    line-height: 30px;
    color: #ffffff;
    text-align: center;
    margin: 20px 0;
}

/***** PROFILE MANAGE PAYMENT *******/
.profile-card-list-box {
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
}
.profile-card-list-title {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 25px;
}
.profile-card-list-content {
    display: flex;
    flex-direction: column;
    font-size: 20px;
}
.profile-card-list-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d1d1d1;
    padding: 10px 0;
}
.profile-card-list-row:last-child {
    border-bottom: 1px solid #d1d1d1;
}
.profile-card-default {
    border: 1px solid green;
    font-size: 15px;
    padding: 5px;
    margin: 0 10px;
    border-radius: 5px;
    color: green;
}
.profile-remove-card-pill {
    border-radius: 10px;
    background-color: #ba9b6a;
    border: 5px solid #ba9b6a;
    width: 90px;
    height: 30px;
    font-size: 15px;
    line-height: 10px;
    color: #ffffff;
    text-align: center;
    margin: 5px;
}
.profile-set-card-pill {
    border-radius: 10px;
    background-color: #ba9b6a;
    border: 5px solid #ba9b6a;
    width: 120px;
    height: 30px;
    font-size: 15px;
    line-height: 10px;
    color: #ffffff;
    text-align: center;
    margin: 5px;
}
.profile-save-payment-pill {
    border-radius: 10px;
    background-color: #ba9b6a;
    border: 5px solid #ba9b6a;
    width: 130px;
    height: 45px;
    font-size: 20px;
    line-height: 20px;
    color: #ffffff;
    text-align: center;
    margin: 20px 10px;
    margin-bottom: 10px;
}
.add-payment-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #d1d1d1;
    border-radius: 10px;
    padding: 30px;
}
.add-payment-box-title {
    display: flex;
    align-items: center;
    font-size: 25px;
    width: 100%;
    cursor: pointer;
}
.add-payment-box-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-top: 15px;
}
.add-payment-box-field1 {
    width: 40%;
    padding-right: 10px;
}
.add-payment-box-field2 {
    width: 30%;
    padding-right: 10px;
}
.add-payment-box-field3 {
    width: 30%;
    padding-right: 10px;
}
.add-payment-sec {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/***** PROFILE MANAGE SUBSCRIPTION *******/
.profile-subscription-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    padding-top: 20px;
}
.profile-subscription-detail {
    display: flex;
    flex-direction: column;
}
.profile-subscription-detail-subtitle {
    font-size: 14px;
}
.profile-subscription-detail-overdue {
    border: 1px solid red;
    border-radius: 5px;
    padding: 1px 8px;
    margin-top: 2px;
    color: red;
}
.profile-subscription-cancel-tag {
    border: 2px solid #d1d1d1;
    border-radius: 10px;
    padding: 0 10px;
    color: #d1d1d1;
}

/***** PROFILE BUY DIAMOND *******/
.profile-buy-diamond h2 {
    color: #b09063;
    font-size: 45px;
    font-weight: normal;
    margin: 0 0 30px 0;
}
.diamond-package-box {
    position: relative;
    border-radius: 10px;
    background-image: linear-gradient(to top right, #bb9b6a, #9a6e4d);
    margin-bottom: 25px;
}
.diamond-package-text-box {
    position: absolute;
    left: 20px;
    top: 15px;
}
.diamond-package-timer {
    position: absolute;
    top: 42px;
    right: 0;
    width: 120px;
    background-color: #b55542;
    opacity: 0.8;
    color: #ffffff;
    font-weight: 700;
    padding-left: 10px;
    font-size: 16px;
}
.diamond-package-text-box h3 {
    color: #ffffff;
    font-size: 23px;
    font-weight: 700;
}
.diamond-package-detail {
    position: absolute;
    z-index: 10;
    text-align: center;
    color: #ffffff;
    bottom: 0;
    left: 0;
    right: 0;
}
.diamond-package-flash-sales {
    background-image: linear-gradient(to right, #47d95b, transparent);
    padding: 2px 2px 2px 15px;
    margin-top: 0px;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.diamond-package-price {
    background-image: linear-gradient(to right, #0f2945, #3c77a0);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 5px 2px;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.diamond-package-price .strikethrough {
    /* background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%); */
    position: relative;
    margin-left: 10px;
    font-size: 12px;
    margin-top: 1px;
    color: #a6b5c1;
}
.diamond-package-price .strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: #db0c11;
    -webkit-transform:rotate(-5deg);
    -moz-transform:rotate(-5deg);
    -ms-transform:rotate(-5deg);
    -o-transform:rotate(-5deg);
    transform:rotate(-5deg);
}

/***** PROFILE HISTORY *******/
.history-diamond h2 {
    color: #b09063;
    font-size: 45px;
    font-weight: normal;
    margin: 0 0 30px 0;
}
.history-diamond-inner table {
    width: 100%;
}
.history-diamond-inner table thead tr th {
    color: #0e1f33;
    font-size: 32px;
    border-bottom: 3px solid #bb9b6a;
    font-weight: 400;
    padding: 0 0 3px 0;
}
.history-diamond-inner table tbody tr:first-child td {
    padding: 60px 20px 35px 0;
}
.history-diamond-inner table tbody tr {
    border-bottom: 1px solid #a9a9a9;
}
.history-diamond-inner table tbody tr td {
    color: #0e1f33;
    font-size: 22px;
    font-weight: normal;
    padding: 35px 20px 35px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
}
.history-diamond-inner table tbody tr:last-child td {
    border-bottom: none;
}
.download-invoice-btn{
    width: 120px;
    height: 40px;
    border-radius: 30px;
    background-color: var(--primaryColor);
    border: 5px solid var(--primaryColor);
    font-size: 15px;
    line-height: 1.1;
    color: #fff;
    text-align: center;
}
.download-invoice-btn:hover{
    background-color: var(--secondaryColor);
    border: 5px solid var(--secondaryColor);
    color: #fff;
}

/***** NAVIGATION TAB *******/
.profile-bottom-section {
    padding-left: 150px;
}
.profile-bottom-section h3 {
    color: #b09063;
    font-size: 35px;
    font-weight: normal;
    margin: 30px 0 20px 0;
}
.navigation-tab {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
}
.navigation-tab a {
    color: #0e1f33;
}
.navigation-box {
    background: #fefefe;
    border-radius: 20px;
    min-height: 50px;
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.11);
    position: relative;
    z-index: 0;
    padding: 20px 30px;
    display: flex;
    align-items: center;
}
.navigation-box span{
    padding-left: 20px;
    font-size: 20px;
}

/***** PROFILE TAB *******/
.profile-tab {
    background-color: #253d56;
    color: white;
    position: absolute;
    min-width: 320px;
    min-height: 320px;
    left: 0;
    top: 450px;
    z-index: 1;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    opacity: 0.85;
}
.profile-tab-list {
    padding: 40px;
    font-size: 30px;
}
.profile-tab-list li {
    padding-left: 0px;
    border-bottom: 2px solid #ba9b6a;
    padding: 10px;
}
.profile-tab-list li:last-child {
    border-bottom: none;
}
.profile-tab-list li img {
    margin-left: 15px;
}
.profile-tab-list li a {
    color: #ffffff;
}
.profile-tab-list li .active {
    color: #ba9b6a;
}

/****** SINGLE ARTICLE LEFT *******/
.article-text {
    border-bottom: 2px solid #b7b7b7;
    padding: 0 0 20px 0;
}
.article-text h1 {
    color: #000000;
    font-size: 35px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 25px 0;
}
.article-text ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.article-text ul li {
    color: #909090;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: normal;
}
.single-article-box {
    margin-bottom: 85px;
}
.article-content img {
    margin-top: 36px;
}
.article-content figcaption {
    text-align: center;
    padding-bottom: 36px;
}
.article-content p {
    color: #0e1f33;
    font-size: 25px;
    font-weight: 400;
    line-height: 1.3;
    margin: 0 0 36px 0;
    padding-top: 12px;
}
.article-content h2 {
    color: #000000;
    font-size: 30px;
    font-weight: 700;
    display: inline-block;
    text-transform: uppercase;
    padding-top: 10px;
    padding-bottom: 20px;
    margin-bottom: 43px;
    border-bottom: 4px solid #bb9b6a;
}
.article-content::-webkit-scrollbar {
    display: none;
}
.article-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.single-article-faq .accordion-item {
    border: none;
}
.single-article-faq .accordion-button {
    color: #000000;
    background-color: transparent;
}
.accordion-button {
    font-size: 25px;
    font-weight: 500;
}
.article-accordion-button:after {
    content: none;
}
/*
.single-article-faq .accordion-button::after{
    background-image: url("/assets/images/faq-expand.png") !important;
    transform: unset;
    color: #000000;
}
.single-article-faq .accordion-button:not(.collapsed)::after{
    background-image: url("/assets/images/faq-collapse.png") !important;
    transform: unset;
    color: #000000;
}
*/
.accordion-body {
    background: transparent;
    font-size: 20px;
}
.sponsord-tag {
    display: flex !important;
    align-items: center;
    margin-top: 30px;
    flex-wrap: wrap;
    grid-gap: 10px;
}
.sponsord-tag li {
    /* padding: 0 10px; */
}
.sponsord-tag li a {
    /* margin-top: 30px; */
    color: #0e1f33;
    font-size: 22px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.11);
    border: 2px solid rgb(187, 155, 106);
    height: auto;
    border-radius: 11px;
    padding: 0 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.sponsord-tag li a:hover {
    background-color: rgb(187, 155, 106);
    color: #fff;
}
.article-social {
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgb(12 12 12 / 11%);
    border-radius: 10px;
    padding: 10px 28px;
    display: inline-block;
    margin: 28px 0 0 0;
}
.article-social ul {
    display: flex;
    align-items: center;
    margin: 0 -5px;
}
.article-social ul li {
    padding: 0 5px;
}
.sponsord-add {
    border-radius: 25px;
    background-image: -moz-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    background-image: -webkit-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    background-image: -ms-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.11);
    margin: 30px 0 0 0;
    padding: 8px 20px 20px;
}
.sponsord-add .media {
    display: flex;
    align-items: center;
    margin: 0 0 10px 0;
}
.sponsord-add .media h4 {
    color: #000000;
    font-size: 25px;
    font-weight: normal;
    /* width: 300px; */
    margin-right: 10px;
}
.sponsord-add .media h4 a {
    color: #000000;
}
.sponsord-add .media span {
    display: flex;
    align-items: center;
}
.sponsord-add .media span img {
    box-shadow: 0.557px 3.961px 9.6px 0.4px rgba(12, 12, 12, 0.3);
    border-radius: 50%;
    margin-left: 0;
}
.sponsord-add-image{
    width: 50px;
}

/****** SINGLE ARTICLE POLL BOTTOM *******/
.sponsord-survey-form {
    border-radius: 25px;
    background-image: -moz-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    background-image: -webkit-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    background-image: -ms-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.11);
    margin: 30px 0 0 0;
    padding: 8px 40px 40px 40px;
}
.sponsord-survey-form h3 {
    padding-top: 10px;
    font-size: 25px;
    font-weight: bold;
    padding-bottom: 10px;
}
.survey-form-question-btm {
    font-size: 23px;
    padding-bottom: 20px;
}
.survey-form-options-btm {
    font-size: 20px;
    border: 1px solid #a6a6a6;
    padding: 5px 10px;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    z-index: 4;
    position: relative;
}
.month-sponsord-add {
    padding: 27px 11px 25px 18px;
    margin: 50px 0 0 0;
}
.survey-progress {
    width: 100%;
    /* min-height: 40px; */
    position: relative;
    border: 1px solid #a6a6a6;
    border-radius: 0px;
    /* margin-bottom: 7px; */
    height: 100%;
}
.survey-progress-bar {
    overflow: unset;
    background-color: #bb9b6a;
    color: #000000;
}
.survey-progress-submitted {
    padding-left: 10px;
    border: none;
    margin-bottom: 0px;
    display: flex;
}
.survey-option-text-submitted {
    padding-left: 10px;
    padding-right: 40px;
    width: 100%;
    color: black;
}
.survey-submitted-percentage {
    position: absolute;
    right: 10px;
    top: 3px;
    font-size: 17px;
    top: unset;
}

/****** SINGLE ARTICLE POLL LEFT *******/
.survey-form-float {
    background-color: #3e4964;
    color: white;
    position: fixed;
    width: 350px;
    min-height: 300px;
    left: 0;
    top: 30%;
    z-index: 5;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    display: none;
}
.survey-form-float-content {
    padding: 15px;
    padding-right: 30px;
    position: relative;
}
.survey-form-float-title {
    font-size: 22px;
    font-weight: bold;
    color: #bb9b6a;
    padding-bottom: 20px;
}
.survey-form-float-question {
    font-size: 16px;
    padding-bottom: 10px;
}
.survey-form-float-options {
    border: 1px solid white;
    padding: 5px 10px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
}
.survey-form-float-triangle-click {
    position: absolute;
    top: 45%;
    right: -25px;
    transform: rotate(180deg);
}
.survey-form-float-hide {
    background-color: #3e4964;
    position: fixed;
    width: 30px;
    min-height: 300px;
    left: 0;
    top: 30%;
    z-index: 1;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    display: block;
}
.survey-form-float-triangle-click-close {
    position: absolute;
    top: 45%;
    right: -25px;
}
.survey-float-progress-outlet{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
}
.survey-float-progress {
    border: 1px solid #a6a6a6;
    min-height: 32px;
    position: relative;
    border-radius: 0px;
    margin-bottom: 7px;
    height: 100%;
    margin-bottom: unset;
}
.survey-float-progress-bar {
    overflow: unset;
    background-color: #bb9b6a;
    color: #000000;
}
.survey-float-progress-submitted {
    border: none;
    margin-bottom: 0px;
    font-size: 16px;
    position: relative;
    z-index: 5;
}
.survey-form-float-options-all {
    height: 170px;
    overflow-y: auto;
    padding-right: 5px;
}
.survey-form-float-options-all::-webkit-scrollbar {
    width: 5px;
}
.survey-form-float-options-all::-webkit-scrollbar-track {
    background: #1e2f41;
    border-radius: 10px;
}
.survey-form-float-options-all::-webkit-scrollbar-thumb {
    background: #c0a273;
    border-radius: 10px;
}

/****** SINGLE ARTICLE RIGHT *******/
.latest-news {
    border-radius: 15px;
    background-image: -moz-linear-gradient(180deg, #ffffff ,#f1f1f1);
    background-image: -webkit-linear-gradient(180deg, #ffffff ,#f1f1f1);
    background-image: -ms-linear-gradient(180deg, #ffffff ,#f1f1f1);
    background-image: linear-gradient(180deg, #ffffff ,#f1f1f1);
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    padding: 40px 24px 20px 30px;
    margin-bottom: 50px;
}
.latest-news:last-child{
    margin: 0;
}
.latest-news h2{
    color: #0e1f33;
    font-size: 25px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 35px 0;
}
.latest-news-box {
    position: relative;
    margin: 30px 0;
}
.latest-news-box .image-holder img{
    width: 100%;
}
.latest-news-box .text-box{
    position: absolute;
    bottom: 0;
    left: 0;
    padding-left: 20px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 16px;
    padding-right: 20px;
    background: #000000;
    opacity: 0.8;
}
.latest-news-box .text-box h4{
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.news-recommendations-box{
    display: flex;
    align-items: center;
    padding: 15px 0;
}
.news-recommendations-box .image-holder{
    max-width: 136px;
    width: 100%;
}
.news-recommendations-box .text-box{
    flex: 1;
    padding-left: 20px;
}
.news-recommendations-box .text-box h4{
    color: #0b0b0b;
    font-size: 25px;
    text-transform: uppercase;
    font-weight: bold;
}
.news-recommendations-box .text-box p{
    color: #0e1f33;
    font-size: 25px;
    display: flex;
    align-items: center;
    font-weight: bold;
}
.news-recommendations-box .text-box p span{
    color: #be956b;
    display: inline-block;
}
.news-recommendations-box .text-box p img{
    height: 22px;
    margin-right: 9px;
}
.news-recommendations-box .text-box .ratting{
    margin: 6px 0px 8px; 
}

/***** SINGLE ARTICLE RIGHT LATEST BONUS *****/
.article-latest-bonus-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
    background-color: var(--colorWhite);
}
.article-latest-bonus-box-green{
    border: 3px solid #00d100;
}
.article-latest-bonus-box-yellow{
    border: 3px solid #d1d100;
}
.article-latest-bonus-box-red{
    border: 3px solid #d10000;
}
.article-latest-bonus-box-black{
    border: 3px solid #d1d1d1;
}
.article-latest-bonus-img{
    width: 80px;
}
.article-latest-bonus-title{
    font-size: 22px;
    font-weight: bold;
}
.article-latest-bonus-text-green{
    color: #00d100;
}
.article-latest-bonus-text-yellow{
    color: #d1d100;
}
.article-latest-bonus-text-red{
    color: #d10000;
}
.article-latest-bonus-text-black{
    color: #000000;
}
.article-latest-bonus-field{
    display: flex;
    width: 100%;
    font-size: 16px;
    padding: 5px 30px;
}
.article-latest-bonus-field-left{
    width: 60%;
    font-weight: bold;
}
.article-latest-bonus-field-right{
    width: 40%;
    font-weight: bold;
}
.article-latest-bonus-button-sec{
    padding-top: 20px;
}
.article-latest-bonus-button{
    border: none;
    border-radius: 5px; 
    padding: 10px;
    background-color: var(--colorGreen);
    font-size: 20px;
    color: var(--colorWhite);
}
.article-latest-bonus-button:hover{
    background-color: var(--colorDarkGreen);
}
.article-latest-bonus-desktop .swiper-button-next2, .article-latest-bonus-desktop .swiper-button-prev2{
    color: #d1d1d1;
}

/***** Vote Ranking *****/
.vote-ranking-whatisdiamond{
    padding: 35px 75px;
}
.vote-ranking-diamondRankingBoard{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
}
.vote-ranking-diamondRankingBoard-title{
    display: flex;
    align-items: center;
}
.vote-ranking-diamondRankingBoard-title-image{
    width: 90px;
    margin-right: 30px;
}
.vote-ranking-diamondRankingBoard-title-icon{
    font-size: 35px;
    margin-left:10px;
    display: none;
}
.vote-ranking-diamondRankingBoard-board{
    width: 1000px;
    margin-top: 30px;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 20px;
}
.vote-ranking-diamondRankingBoard-board-column{
    flex: 4;
    text-align: center;
    color: #bb9b6a;
    border-left: 1px solid #ffffff;
    padding: 10px 0 40px;
    background-color: #0f2945;
}
.vote-ranking-diamondRankingBoard-board-column:first-child{
    flex: 1.5;
    border-left: unset;
    border-top-left-radius: 20px;
}
.vote-ranking-diamondRankingBoard-board-column:last-child{
    flex: 5;
}
.vote-ranking-diamondRankingBoard-board-column:last-child{
    border-top-right-radius: 20px;
}
.vote-ranking-diamondRankingBoard-board-list{
    margin-top: -30px;
    position: relative;
    z-index: 2;
}
.vote-ranking-diamondRankingBoard-board-item{
    height: 300px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #000000;
}
.vote-ranking-diamondRankingBoard-board-item:first-child{
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}
.vote-ranking-diamondRankingBoard-board-item:last-child{
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    border-bottom: unset;
}
.vote-ranking-diamondRankingBoard-board-item-column{
    flex: 4;
    border-left: 1px solid #000000;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.vote-ranking-diamondRankingBoard-board-item-column:first-child{
    flex: 1.5;
    border-left: unset;
}
.vote-ranking-diamondRankingBoard-board-item-column:last-child{
    flex: 5;
    cursor: pointer;
}
.vote-ranking-diamondRankingBoard-board-item-column:nth-child(2){
    flex-direction: column;
    position: relative;
}
.vote-ranking-diamondRankingBoard-board-item-column:nth-child(2) > h4{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}
.vote-ranking-diamondRankingBoard-board-item-column-company-icon{
    width: 170px;
    padding-bottom:20px;
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdownIcon{
    margin-top: 10px;
    margin-left: 10px;
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box{
    background-color: #d7d7d7;
    border-bottom: 1px solid #000000;
    padding: 30px 20px;
    min-height: 270px;
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box:last-child{
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-row{
    display: flex;
    align-items: center;
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-row2{
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-column{
    flex: 5.25
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-column:last-child{
    flex: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-column > div > p{
    flex: 1;
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-column > div > p:first-child{
    flex: unset;
    width: 65px;
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-vote-button{
    width: 64px;
    padding: 8px 0 7px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    color: #ffffff;
    margin-right: 10px;
    margin-bottom: 10px;
    background-image: url('/assets/images/vote-button.png');
    cursor: pointer;
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-vote-button:hover, .vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-vote-button-active{
    background-image: url('/assets/images/vote-button-selected.png');
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-confirm-button{
    background-color: #00a651;
    padding: 10px 30px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-remainWallet{
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    width: 150px;
    background-color: #ddc9aa;
    margin-top: 30px;
    margin-left: 15px;
    padding: 10px 0px 10px 30px;
    justify-content: center;
    position: relative;
}
.vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-remainWallet-image{
    position: absolute;
    left: -32.5px;
    width: 65px;
    top: 0;
}
.vote-ranking-diamondRankingBoard-board-mobileItem{
    width: 50%;
    padding: 20px;
}

.vote-ranking-diamondRankingBoard-board-mobileItem-inner{
    border-radius: 20px;
    -webkit-box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 75%);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 75%);
    background-color: #ffffff;
}
.vote-ranking-diamondRankingBoard-board-mobileItem-rank{
    width: 33.33%;
    width: 182px;
    background-color: #bb9b6a;
    clip-path: polygon(0 0, 100% 0, 90% 100%, 10% 100%);
    /* max-width: 182px; */
    text-align: center;
    padding: 2px 0;
}
.vote-ranking-diamondRankingBoard-board-mobileItem-image{
    width: 250px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top:10px;
}
.vote-ranking-diamondRankingBoard-board-mobileItem-bottom{
    padding: 20px;
    text-align: center;
    background-color: #0f2945;
    border-radius: 0 0 20px 20px;
    width: calc(100% + 2px);
    margin-left: -1px;
}
.vote-ranking-diamondRankingBoard-board-mobileItem-diamondImage{
    width: 40px;
}
.vote-ranking-diamondRankingBoard-mobile-modal-voteConfirm{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
    margin-top:30px
}
.vote-ranking-diamondRankingBoard-mobile-modal-closeButton-absolute{
    position: absolute;
    right: -5px;
    top: 0px;
    padding: 10px;
}
.vote-ranking-diamondRankingBoard-mobile-modal-closeButton{
    font-size: 22px;
}

/* grade-ranking */
.ranking-year-category-box{
    background: #FFFFFF;
    -webkit-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%);
    box-shadow: 0px 1px 5px 0px rgb(0 0 0 / 20%);
}
.ranking-year-category-box-item{
    color: #9f9f9f;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.ranking-year-category-swiper .swiper-slide{
    width: 200px;
}
.ranking-year-category-swiper .swiper-slide.slide-isActive{
    border-bottom: 3px solid #bb9b6a;
}
.ranking-year-category-swiper .swiper-slide.slide-isActive .ranking-year-category-box-item{
    color: #000000;
}
.ranking-year-category-box .swiper-button-prev{
    left: -20px;
    font-weight: bold;
    color: #000000;
}
.ranking-year-category-box .swiper-button-next{
    right: -20px;
    font-weight: bold;
    color: #000000;
}
.ranking-year-top10{
    padding: 35px 75px 0;
}
.ranking-casino-list{
    margin-top: 60px;
}
.ranking-casino-item{
    width: 100%;
    background-color: #FFFFFF;
    background-image: linear-gradient(180deg, #ffffff ,#f1f1f1);
    border-radius: 15px;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    position: relative;
    display: flex;
    align-items: center;
    padding: 50px 30px 30px;
    margin: 30px 0;
}
.ranking-casino-item-image{
    height: 200px;
    width: 260px;
    border-right: 1px solid #000000;
    padding: 0 20px 0 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ranking-casino-logo {
    border-radius: 15px;
}
.ranking-casino-item-rank{
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 60px;
    background-color: #102945;
    border-top-left-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ranking-casino-item-content{
    flex: 1
}
.ranking-casino-item-content-inner{
    padding: 0 0 0 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ranking-casino-item-content-left{
    flex: 1;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 20px;
}
.ranking-casino-item-content-left-amount{
    display: flex;
    align-items: flex-end;
}
.ranking-casino-item-content-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 200px;
}
.ranking-casino-item-content-right-button{
    /* background-image: linear-gradient(180deg, #bb9b6a, #9b6f4e); */
    background-color: var(--colorGold);
    padding: 25px 40px;
    border-radius: 15px;
    cursor: pointer;
}
.ranking-casino-item-content-right-button:hover{
    background-color: var(--colorBlue)
}
.ranking-casino-item-content-right > p {
    cursor: pointer;
}
.ranking-casino-item-content-vote-box{
    width: fit-content;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    padding: 10px;
    border-radius: 5px;
}
.ranking-casino-item-content-vote-star{
    width: 35px;
    height: 35px;
    margin-right: 5px;
}
.award-best-casino{
    padding: 35px 75px 0;
}
.award-best-casino-list{
    display: grid;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
}
.award-best-casino-item-outer{
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: calc(33.33% - 40px);
}
.award-best-casino-item-outer > h4 {
    margin-top: 10px;
    cursor: pointer;
    text-align: center;
}
.award-best-casino-item{
    /* box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    border-radius: 15px;
    padding: 20px;
    border: 4px solid transparent; */
    cursor: pointer;
    
}
/*
.award-best-casino-item:hover{
    border: 4px solid #b99b60
}
*/
.award-best-casino-item-image{
    position:relative;
    width: 100%;
}
.award-best-casino-item-image img:hover{
    -webkit-box-shadow: 0px 0px 15px 5px rgba(185,155,96, 1);
    -moz-box-shadow: 0px 0px 15px 5px rgba(185,155,96, 1);
    box-shadow: 0px 0px 15px 5px rgba(185,155,96, 1);
    border-radius: 15px;
    filter: brightness(110%);
}
.award-best-casino-item-imageCasino{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 180px;
    height: 180px;
    transform: translate(-50%, -65%);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

/* complaint - list */
.complaintList-page-complaint-button-row{
    display: flex;
    justify-content: flex-start;
}
.complaintList-page-complaint-button{
    padding: 20px 40px;
    /* background-image: linear-gradient(180deg, #bb9b6a, #9b6f4e); */
    background-color: var(--colorGold);
    border-radius: 20px;
    text-align: center;
    cursor: pointer;
}
.complaintList-page-complaint-button:hover{
    background-color: var(--colorBlue);
}
.complaintList-page-complaint-search-row{
    display: flex;
    justify-content: flex-end;
}
.complaintList-page-complaint-search{
}
.complaintList-page-complaint-search-input{
    font-size: 30px !important;
    padding: 20px 60px 20px 20px !important;
    border-radius: 20px !important;
    /* border: 1px solid #000000 !important; */
    height: unset !important;
}
.complaintList-page-complaint-search-input-rightSection{
    width: 50px !important;
}
.complaintList-page-complaint-search-input-icon{
    font-size: 30px;
    margin-right: 20px;
    color: #ced4da;
    cursor: pointer;
}
.complaintList-page-list{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.complaintList-page-item{
    display: grid;
    grid-template-columns: 220px 1fr;
    width: 100%;
    background-image: linear-gradient(180deg, #ffffff ,#f1f1f1);
    padding: 20px 20px 2px 50px;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    margin: 30px 0;
    border-radius: 30px;
    align-items: center;
}
.complaintList-page-item-image{
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-right: 20px;
}
.complaintList-page-item-content{
    display: flex;
    flex-direction: column;
    flex: 1
}
.complaintList-page-item-topContent{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    padding: 30px 0;
}
.complaintList-page-item-top{
    display: flex;
    align-items: flex-start;
}
.complaintList-page-item-center{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
}
.complaintList-page-item-left{

}
.complaintList-page-item-right{

}
.complaintList-page-item-right-button{
    /* background-image: linear-gradient(180deg, #bb9b6a, #9b6f4e); */
    background-color: var(--colorGold);
    padding: 15px 60px;
    border-radius: 20px;
    cursor: pointer;
}
.complaintList-page-item-right-button:hover{
    background-color: var(--colorBlue);
}
.complaintList-page-item-bottomContent{
    border-top: 1px solid #000000;
    display: flex;
    justify-content: flex-end;
    padding: 5px 0;
}

/* complaint - single */
.complaintSingle-header-box{
    background-image: linear-gradient(180deg, #ffffff ,#f1f1f1);
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 40px;
    padding: 30px;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    border-radius: 20px;
    margin-top:40px;
}
.complaintSingle-header-left{
    display: flex;
    align-items: center;
    justify-content: center;
}
.complaintSingle-header-left-image{
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.complaintSingle-header-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.complaintSingle-header-content-right{
    height: 100%;
    display: flex;
    align-items: flex-end;
}
.complaintSingle-header-content-right-button{
    padding: 20px 40px;
    /* background-image: linear-gradient(180deg, #bb9b6a, #9b6f4e); */
    background-color: var(--colorGold);
    border-radius: 20px;
    margin-bottom: 10px;
}
.complaintSingle-header-content-right-button:hover{
    background-color: var(--colorBlue);
}
.complaintSingle-content-status-category-list{
    margin: 30px 0;
    padding: 0 30px;
    display: flex;
    align-items: center;
}
.complaintSingle-content-status-category-item{
    padding: 20px;
    width: calc(25% - 20px);
    margin: 0 10px;
    background-image: linear-gradient(180deg, #ffffff ,#f1f1f1);
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
}
.complaintSingle-content-status-category-item.active{
    background-image: linear-gradient(180deg, #bb9b6a, #9b6f4e);
}
.complaintSingle-content-status-category-item-text{
    color: #bb9b6a
}
.complaintSingle-content-status-category-item-text.active{
    color: #FFFFFF
}
.complaintSingle-content-status-category-item-image{
    width: 60px;
    height: 60px;
}
.complaintSingle-content-item{
    display: grid;
    grid-template-columns: 260px 1fr;
    align-items: center;
    padding: 30px;
    background-image: linear-gradient(180deg, #ffffff ,#f1f1f1);
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    margin-top: 60px;
    border-radius: 20px;
    position: relative;
    z-index: 2;
}
.complaintSingle-content-item.active{
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.complaintSingle-content-item-left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    padding: 0 60px 0 0;
}
.complaintSingle-content-item-left-image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    background-color: #a1a1a1;
    border-radius: 50%;
    overflow: hidden;
}
.complaintSingle-content-item-left-text{
    font-size: 150px;
    font-weight: bold;
    color: white;
}
.complaintSingle-content-item-left > h4 {
    text-align: center;
    margin-top: 10px;
}
.complaintSingle-content-item-right-title-row{
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #000000;
}
.complaintSingle-content-item-right-title-row > .custom_h3{
    flex: 1
}
.complaintSingle-content-item-right-title-row > .custom_p2{
    /* width: 280px; */
    padding-left: 30px;
    text-align: end;
}
.complaintSingle-content-item-right-contentBox{
    display: flex;
    /* grid-template-columns: 170px 1fr; */
    justify-content: flex-start;
    align-items: stretch;
    margin-top: 20px;
    /* min-height: 200px; */
}
.complaintSingle-content-item-right-contentBox-image{
    width: 150px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    max-height: 200px;
    overflow: hidden;
    height: fit-content;
    border: 3px solid #b99b60;
    cursor: pointer;
}
.complaintSingle-content-item-right-contentBox-inner{
    flex: 1;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.complaintSingle-content-item-right-contentBox-inner-bottom{
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid #000000;
    align-self: flex-end; */
    margin-top: 30px;
    cursor: pointer;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 10px;
}
.complaintSingle-content-viewMore{
    display: flex;
    border-bottom: 1px solid #000000;
}
.mobile-complaintSingle-content-item-right-contentBox-inner-bottom{
    display: none;
}
.complaintSingle-content-item-collapse-box{
    padding: 30px;
    background-color: #d9d9d9;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    position: relative;
    width: calc(100% + 2px);
    z-index: 1;
    border-radius: 0 0 30px 30px;
}
.complaintSingle-content-item-collapse-box-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.complaintSingle-content-item-collapse-box-content{
    display: flex;
    /* grid-template-columns: 1fr 150px; */
    justify-content: flex-start;
    margin-top: 30px;
    grid-gap: 30px;
    margin-bottom: 30px;
}
.complaintSingle-content-item-collapse-box-image{
    display: flex;
    grid-gap: 0 10px;
}
.complaintSingle-content-item-collapse-box-content-image{
    width: 150px;
    max-height: 200px;
    overflow: hidden;
    border: 3px solid #b99b60;
    cursor: pointer;
    height: fit-content;
}


.pop-fullImage{
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    cursor: zoom-out;
    z-index: 1;
}
.pop-fullImage-closeButtonBox{
    position: absolute;
    width: 50px;
    height: 50px;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: rgba(255,255,255, 0.75);
    z-index: 2;
    cursor: pointer;
}

/* complaint - submit */
.complaint-submit{
    width: 900px;
}
.complaint-submit-requirements-step {
    display: grid;
    grid-template-columns: 70px 1fr;
}
.complaint-submit-list{
    display: grid;
    grid-template-columns: 320px 550px;
    grid-gap: 40px 30px;
    align-items: center;
    margin-top: 50px;
    justify-items: stretch;
}
.complaint-submit-list > .text-area, .complaint-submit-list > .dropzone-input{
    align-self: flex-start;
}
.complaint-submit-modal{
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}
.complaint-submit-modal-inner{
    width: 100% !important;
    border: 5px solid #b99b60 !important;
    border-radius: 10px !important;
    padding: 40px !important;
    max-width: 700px !important;
}
.complaint-submit-modal-closeButton{
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 10px;
    z-index: 2;
}
.complaint-submit-modal-closeButton-icon{
    font-size: 25px;
}
.complaint-submit-modal > .custom_h4{
    margin-top: 30px;
    text-align: center;
}
.complaint-submit-modal > .custom_p{
    margin-top: 10px;
}
.complaint-submit-icon{
    font-size: 120px;
}
.complaint-submit-modal-button{
    width: 100%;
    max-width: 150px;
    background-image: linear-gradient(180deg, #bb9b6a, #9b6f4e);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 5px 0;
    margin-top: 20px;
}
.dropzone-input-box{
    display: flex;
    width: 100%;
}
.dropzone-input-box-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 30px;
    background-color: #FFFFFF;
    border: 2px dashed #ced4da;
    border-radius: 5px;
    flex: 1;
}
.dropzone-input-box-inner2{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dropzone-input-box-button{
    display:flex;
    align-items: center;
    background-image: linear-gradient(180deg,#fff,#f1f1f1);
    box-shadow: 1px 2px 5px 0px rgb(0 0 0 / 20%);
    border-radius: 5px;
    padding: 5px 30px;
}
.dropzone-input-box-button-icon{
    font-size: 40px;
    margin-right: 10px;
}
.complaint-submit-button{
    padding: 10px 0;
    width: 180px;
    display: flex;
    /* background-image: linear-gradient(180deg, #bb9b6a, #9b6f4e); */
    background-color: var(--colorGold);
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    margin: 20px 0 10px;
    border: none;
}
.complaint-submit-button:hover{
    background-color: var(--colorBlue);
}

.thumbsContainer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.thumb{
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    width: 112px;
    height: 112px;
    padding: 4px;
    margin-left: 10px;
}
.thumbInner{
    display: flex;
    min-width: 0;
    overflow: hidden;
    position: relative;
}
.thumbInnerClose{
    width: 25px;
    height: 25px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(221, 221, 221, 0.8);
    cursor: pointer;
}
.thumbInnerImage {
    display: block;
    width: auto;
    height: 100%
}

/* payment checkout */
.checkout-payment-sec{
    padding-top: 20px;
}
.checkout-payment{
    display: grid;
    grid-template-columns: 1fr 450px;
    grid-gap: 70px;
    align-items: center;
}
.checkout-payment-card{

}
.checkout-payment-card-inner{
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
}
.checkout-payment-card-title{
    display: flex;
    align-items: center;
    grid-gap: 10px;
}
.checkout-payment-card-title > .custom_colorGreen{
    font-size: 50px;
}
.checkout-payment-card-box{
    position:relative;
    width: 100%;
    padding-top: 63.0563%;
}
.checkout-payment-card-box-1, .checkout-payment-card-box-2{
    position: absolute;
    width: 75%;
    height: 75%;
    border-radius: 10px;
}

.checkout-payment-card-box-1{
    top: 0;
    left: 0;
    background-color:#eaeaea;
    z-index: 2;
    border: 2px solid #d1d1d1;
    border-radius: 10px;
}
.checkout-payment-card-box-2{
    bottom: 0;
    right: 0;
    background-color:#d1d1d1;
    z-index: 1;
}
.checkout-payment-card-box-1-inner, .checkout-payment-card-box-2-inner{
    position:relative;
    width: 100%;
    height: 100%;
}
.checkout-payment-card-box-1-inner{
    padding: 40px 50px;
    display: grid;
}
.checkout-payment-card-box-1-row1{
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 2fr 1fr;
}
.checkout-payment-card-box-2-dark{
    position: absolute;
    left: 0;
    right: 0;
    background-color: #c0c0c0;
    bottom: 60%;
    top: 20%;
}
.checkout-payment-card-box-2-ccv{
    position: absolute;
    top: 45%;
    right: 6%;
    left: 70%;
}
.checkout-payment-card-box-1-image{
    width: 40%;
}
.checkout-payment-card-button{
    background-color: #00a651;
    padding: 20px 0;
    width: 230px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    align-self: flex-end;
    border: unset;
}
.checkout-payment-card-button:hover{
    background-color: #007338;
}
.checkout-payment-backButton{
    display:flex;
    align-items: center;
    grid-gap: 20px;
}
.checkout-payment-purchaseSummary-title{
    background-color: #102945;
    padding: 20px 30px;
}
.checkout-payment-purchaseSummary-body{
    padding: 30px 30px 40px;
    background-color: #e2e2e2;
}
.checkout-payment-purchaseSummary-body-row{
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 50px;
}
.checkout-payment-purchaseSummary-body-row:first-child{
    margin-top: unset;
}
.checkout-payment-purchaseSummary-body-totalPrice{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #000000;
}
.checkout-payment-purchaseSummary-body-img1{
    margin-top:30px;
}
.checkout-payment-purchaseSummary-body-img2{
    margin-top:30px;
    position: relative;
}
.checkout-payment-purchaseSummary-body-img2-text{
    position: absolute;
    top: -3px;
    font-size: 89%;
    font-weight: bold;
    left: 15%;
}
.checkout-payment-img{
    width: 450px;
}
/* checkout payment successful */
.payment-successful-button{
    background-image: linear-gradient(180deg, #bb9b6a, #9b6f4e);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 50px;
    width: fit-content;
    border-radius: 30px;
    margin: 20px auto 0;
}
.checkout-payment-successful .login__form_layout_tittle_otp{
    display: flex;
    justify-content: center;
}
.checkout-payment-successful .login__form_layout_message_otp{
    margin-top:30px;
}

/* how you review */
.mobile-how-we-review-scale{
    width: 100%;
    padding-top: 199.6632%;
    position: relative;
}
.mobile-how-we-review-scale-image1, .mobile-how-we-review-scale-image2{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

/* you may like this */
.you-may-like-this-item{
    display: grid;
    grid-gap: 10px;
    /* padding: 30px; */
    padding: 30px 0;
    background-color: white;
    justify-items: center;
    background-color: #FFFFFF;
    background-image: linear-gradient(180deg, #ffffff ,#f1f1f1);
    border-radius: 30px;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    /* max-width: 320px; */
}
.you-may-like-this-item-button{
    /* background-image: linear-gradient(180deg, #bb9b6a, #9b6f4e); */
    background-color: var(--colorGold);
    padding: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 250px;
    border-radius: 10px;
}
.you-may-like-this-item-button:hover{
    background-color: var(--colorBlue);
}
.you-may-like-this-item-image{
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.you-may-like-this-list{
    /* margin-top: 30px; */
}
.you-may-like-this-list .swiper-wrapper{
    padding: 30px 0;
}
.you-may-like-this-list .swiper-slide{
    padding: 0 30px;
}

/* why trust us */
.why-trust-us-swiper{
    padding: 30px 10px !important;
}
.why-trust-us-swiper .swiper-slide{
    height: auto;
}
.why-trust-us-swiper-item-outlet{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.why-trust-us-swiper-item{
    background-image: linear-gradient(180deg, #ffffff ,#f1f1f1);
    border-radius: 30px;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    max-width: 320px;
    width: 100%;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    grid-gap: 10px;
}
.why-trust-us-swiper-item-image{
    padding: 0 50px;
}
.why-trust-us-swiper-buttonLocation{
    position: relative;
    margin: 30px auto 0;
    width: 100px; 
    display: flex;
    justify-content: space-between;
}
.why-trust-us-swiper-button-prev, .why-trust-us-swiper-button-next{
    position: relative;
    color: #bb9b6a !important;
}

/* unsubscribe */
.unsubscribe-inner{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1000px;
}
.unsubscribe-image{
    width: 200px;
    align-self: center;
}
.unsubscribe-title{
    align-self: center;
    margin-top: 15px;
}
.unsubscribe-content{
    margin-top: 30px;
    width: 100%;
}
.unsubscribe-btn{
    background-image: linear-gradient(180deg, #bb9b6a, #9b6f4e);
    padding: 20px 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: 30px 0 20px;
}
.unsubscribe-textarea{
    border-radius: 10px !important;
    background-color: white !important;
    font-size: 28px !important;
}
.unsubscribe-prev-btn{
    display: flex;
    align-items: center;
    grid-gap: 5px;
    margin-top: 30px;
}
.unsubscribe-left-icon{
    font-size: 30px;
}

/* why trust */
.why-ugrado-list{
    
}
.why-ugrado-row{
    display: grid;
    grid-template-columns: 40% 30% 30%;
    justify-items: center;
    align-items: center;
    box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 20%);
    margin-top: 30px;
    border-radius: 20px;
    overflow: hidden;
}
.why-ugrado-row-right{
    grid-template-columns: 30% 30% 40%;
    margin-top: 50px;
}

.why-ugrado-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 20px;
    height: 100%;
    justify-content: flex-start;
    padding: 30px;
    border: 1px solid #fde7c6;
    width: 100%;
    background-color: #ffffff;
}
.why-ugrado-item-large{
    grid-row-start: 1;
    grid-row-end: 3;
    background-color: #fde7c6;
    justify-content: center;
    border-radius: 20px 0 0 20px;
}
.why-ugrado-item-large-right{
    grid-column-start: 3;
    border-radius: 0 20px 20px 0;
}
.why-ugrado-item-image{
    width: 80px;
}
.why-ugrado-item-large .why-ugrado-item-image{
    width: 90px;
}
.why-ugrado-item-seeMore{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    color: #bb9b6a;
}
.why-ugrado-item-seeMore-icon{
    font-size: 40px;
}
.why-ugrado-item-content{
    display: flex;
    align-items:flex-start;
    grid-gap: 10px;
}
/* .why-ugrado-item-content-icon{
    font-size: 20px;
    margin-top: 2px;
    color: #43a047;
} */
.why-ugrado-item-content > h4:last-child{
    flex: 1;
}

/* homepage content middle */
.homepage-content-middle-seeMore{
    display: flex;
    align-items: center;
    grid-gap: 10px;
    color: #000000;
    justify-content: center;
    margin-top: 30px;
}
.homepage-content-middle-seeMore-icon{
    font-size: 40px;
}

/* ugrado process */
.ugrado-process-swiper .swiper-slide{
    height: auto;
}
.ugrado-process-item{
    position: relative;
    max-width: 100%;
    height: 100%;
}
.ugrado-process-item-inner{
    max-width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #102945;
    border-radius: 20px;
    height: 100%;
}
.ugrado-process-next{
    position: absolute;
    right: calc(-100% + ((100% + 80%) / 2));
    top: 50%;
    left: calc((100% + 80%) / 2);
    height: 100px;
    background-color: #bb9b6a;
    transform: translateY(-50%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: -1;
}
.ugrado-process-swiper .swiper-slide:last-child .ugrado-process-next{
    display: none
}

/****** SINGLE REVIEW *******/
.single-review-box {
    padding: 40px;
    display: flex;
}
.single-review-box .image-holder {
    /*
    border-radius: 21px;
    background-image: -moz-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    background-image: -webkit-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    background-image: -ms-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.31);
    */
    max-width: 265px;
    width: 100%;
    display: flex;
    /*
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    */
}
.single-review-box .text-box {
    flex: 1;
    padding-left: 60px;
}
.single-review-box .text-box .media {
    display: flex;
    align-items: center;
}
.single-review-box .text-box .media h4 {
    color: #0e1f33;
    font-size: 30px;
    font-weight: bold;
}
.single-review-box .text-box .media p {
    display: flex;
    align-items: center;
    color: #0e1f33;
    font-size: 28px;
    font-weight: normal;
    padding-left: 25px;
    padding-right: 20px;
}
.single-review-box .text-box .media p img {
    margin-right: 8px;
}
.single-review-box .text-box .media span {
    color: #0e1f33;
    font-size: 18px;
    font-weight: normal;
    line-height: 1;
}
.single-review-box-button-list{
    display: flex;
    align-content: center;
    align-items: center;
}
.ratting {
    display: flex;
    align-items: center;
    border-radius: 10px;
    /* background-image: -moz-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    background-image: -webkit-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    background-image: -ms-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%); */
    background-image: linear-gradient(180deg, #ffffff ,#f1f1f1);
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.31);
    max-width: 500px;
    min-height: 60px;
    margin: 21px 30px 0px 0px;
    justify-content: center;
}
.ratting li {
    margin-right: 5px;
}
.ratting li:first-child {
    margin-left: 25px;
}
.ratting li:last-child {
    margin-right: 25px;
}
.visit-btn {
    background-color: #47b459;
    color: #ffffff;
    font-size: 25px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background-color: #47b459;
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.31);
    min-height: 60px;
    max-width: 200px;
    width: 100%;
    margin: 21px 30px 0px 0px;
}
.visit-btn:hover {
    cursor: pointer;
    color: #ffffff;
    background-color: #0f253e;
}
.single-review-box .text-box h6 {
    margin: 20px 0 30px 0;
    color: #0e1f33;
    font-size: 29px;
    font-weight: bold;
    line-height: 1;
    display: flex;
    align-items: center;
}
.single-review-box .text-box h6 span {
    color: #be956b;
    display: inline-block;
    line-height: 1;
    margin-left: 8px;
}
.single-review-box .text-box h6 img {
    height: 26px;
}
.mobile-single-review-box-date{
    display: none;
}
.mobile-single-review-box-h6{
    display: none;
}
.mobile-review-box-h4{
    display: none;
}
.review-progress-field {
    display: flex;
    align-items: center;
    max-width: 680px;
    width: 100%;
}
.review-progress-sec {
    background-color: #0f253e;
    height: 40px;
    border-radius: 30px;
    flex: 1;
    margin-right: 8px;
}
.review-progress-bar {
    background-color: #47b459;
    font-size: 22px;
    font-weight: normal;
    align-items: flex-end;
    padding-right: 5px;
}
.review-progress-bar-percentage {
    font-size : 15px;
    line-height: 1;
}
.review-progress-bar-subtitle {
    font-size: 11px;
    line-height: 1;
}
.rank-diamond {
    display: flex;
    align-items: center;
}
.rank-diamond span {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}
.rank-diamond span img {
    height: 40px;
}
.single-review-pros {
    margin: 43px 0 50px 0;
}
.single-pros-inner .nav-pills .nav-item {
    width: 33.3%;
}
.single-pros-inner .nav-pills .nav-item .nav-link {
    padding: 24px 15px 95px;
    color: #0e1f33;
    font-size: 18px;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border-radius: 15px;
    width: 100%;
}
.single-pros-inner .nav-pills .nav-item .nav-link.active {
    background-color: #102843;
    color: #fff;
}
.single-pros-inner .tab-content {
    border-radius: 15px;
    background-color: #fefefe;
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.21);
    padding: 50px 52px;
    margin: -80px 0 0 0;
    position: relative;
    z-index: 10;
}
.basic-detail ul li {
    color: #0e1f33;
    font-size: 20px;
    font-weight: normal;
    display: flex;
    align-items: center;
    margin: 0 0 30px 0;
}
.basic-detail ul li span {
    display: block;
    width: 100%;
    /* margin-right: 45px; */
}
.basic-detail ul li:last-child {
    margin: 0;
}
.cons-box h4 {
    color: #0e1f33;
    font-size: 25px;
    margin-bottom: 50px;
    font-weight: bold;
}
.cons-box ul li {
    display: flex;
    align-items: center;
    color: #0e1f33;
    font-size: 17px;
    font-weight: normal;
    margin-bottom: 35px;
}
.cons-box ul li img {
    margin-right: 17px;
}
.cons-box ul li:last-child {
    margin: 0;
}
.single-payment-tab {
    background-image: -moz-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    background-image: -webkit-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    background-image: -ms-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.51);
    padding: 0 100px;
    box-sizing: border-box;
    overflow: auto;
    background-color: #ffffff;
}
.single-payment-tab {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.single-payment-tab::-webkit-scrollbar {
    display: none;
}
.single-payment-tab .nav-pills {
    /* width: 2000px; */
    min-width: 1500px;
    margin: 0 auto;
}
.single-payment-tab .nav-pills .nav-item {
    margin-right: 70px;
}
.single-payment-tab .nav-pills .nav-item:last-child {
    margin: 0;
}
.single-payment-tab .nav-pills .nav-item .nav-link {
    padding: 22px 0;
    color: #0e1f33;
    font-size: 17px;
    font-weight: normal;
    line-height: 1;
    border-bottom: 3px solid transparent;
    border-radius: 0;
}
.single-payment-tab .nav-pills .nav-item .nav-link.active {
    background-color: transparent;
    border-bottom-color: #bb9b6a;
}

/***** PAYMENT REVIEW BOX *******/
.payment-review-box {
    padding: 0;
}
.payment-review-box .media {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
}
.payment-review-box .media span {
    position: relative;
    z-index: 1;
    margin: 0;
}
.payment-review-box .media span img {
    height: 66px;
}
.payment-review-box .media .ratting {
    max-width: 333px;
    padding-right: 0;
    padding-left: 10px;
    margin-left: -30px;
}
.payment-review-box .media.ratting li img {
    height: 40px;
}
.payment-review-box .text-box h1 {
    color: #0e1f33;
    font-size: 37px;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 15px;
    position: relative;
    margin-bottom: 15px;
}
.payment-review-box .text-box h1::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    height: 4px;
    max-width: 90px;
    width: 100%;
    background-color: #bb9b6a;
}
.payment-review-box .text-box p {
    color: #0e1f33;
    font-size: 22px;
    font-weight: normal;
    line-height: 1.3;
    margin-bottom: 36px;
}
.payment-review-box .text-box p:last-child {
    margin: 0;
}
.loading-speed {
    border-radius: 15px;
    background-color: #102945;
    padding: 65px 90px 35px;
    margin: 35px 0 35px 0;
    position: relative;
}
.loading-speed span {
    text-align: center;
    display: block;
}
.loading-speed .text-inner h4 {
    color: #fff;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding-top: 20px;
}
.loading-speed .text-inner p {
    text-align: center;
    color: #ffffff;
    font-size: 23px;
    font-weight: normal;
    line-height: 1.1;
    margin: 35px 20px 25px 20px;
}
.loading-speed ul {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    /* justify-content: space-between; */
    padding: 0;
    margin: 40px 0;
}
.loading-speed ul::-webkit-scrollbar {
    display: none;
}
.loading-speed .loading-swiper {
    /* width: 100%; */
    overflow: auto;
}
.loading-speed ul li{
    text-align: center;
}
.loading-speed ul li {
    color: #0e1f33;
    font-size: 23px;
    line-height: 1;
    display: block;
    font-weight: normal;
    padding: 23px 33px;
    white-space: nowrap;
    -moz-user-select:none;
    -webkit-user-select:none;
    user-select:none;
    -ms-user-select:none;
}
.loading-speed ul li.active {
    /* border-radius: 10px; */
    background-color: #bb9b6a;
    color: #fff;
}
.loading-speed .text-inner a {
    color: #bb9b6a;
    font-size: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin: 45px 0px 10px;
    justify-content: center;
}
.loading-speed .swiper-button-prev{
    left: 30px;
    font-weight: bold;
    color: #737373;
}
.loading-speed .swiper-button-prev:after, .loading-speed .swiper-button-next:after{
    font-size: 70px;
}
.loading-speed .swiper-button-next{
    right: 30px;
    font-weight: bold;
    color: #737373;
}
.loading-speed .swiper-counter {
    color: #bb9b6a;
    font-size: 50px;
    font-weight: bold;
    display: flex;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;
    top: 30px;
    align-items: center;
}
.provider-slider {
    padding: 45px 0 0 0;
}
.provider-slider h4 {
    color: #0e1f33;
    font-size: 35px;
    font-weight: bold;
    margin: 0 0 60px 0;
}
.provider-box a {
    border-radius: 15px;
    background-color: rgb(255, 255, 255);
    box-shadow: 1.089px 1.677px 9.4px 0.6px rgba(12, 12, 12, 0.37);
    /* height: 232px; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.providerSwiper {
    padding: 15px 10px;
}
.overall-summary-sec {
    margin-bottom: 84px;
}

/***** SINGLE REVIEW RECOMMENDED *******/
.recommended-inner {
    margin: 0 -15px;
}
.recommended-inner .swiper {
    padding: 15px;
}
.recommended-box {
    border-radius: 10px;
    background-image: -moz-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    background-image: -webkit-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    background-image: -ms-linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgb(242, 242, 242) 100%);
    box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.31);
    text-align: center;
    min-height: 245px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.recommended-box ul {
    display: flex;
    align-items: center;
    margin: 20px;
    justify-content: center;
}
.recommended-box ul li {
    padding: 0 2.5px;
}
.recommended-box .recommended-box-content {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #1e1e20;
    font-size: 19px;
    font-weight: bold;
    line-height: 1;
    padding-bottom: 20px;
}
.recommended-box .recommended-box-content span {
    color: #be956b;
    line-height: 1;
    padding: 0 6px;
}
.recommended-box p img {
    height: 32px;
}

/***** VIDEO MODAL *******/
.video-modalOpened {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1055;
    height: fit-content;
    border-radius: 5%;
}
.modal-video .modal-dialog {
    max-width: 750px;
    margin: 0 auto;
}
.modal-video .modal-content {
    border: none;
    border-radius: 15px;
}
.modal-video .modal-body {
    position: relative;
    padding: 40px 34px 20px 34px;
}
.modal-video .modal-body h4 {
    color: #bb9b6a;
    font-weight: bold;
    font-size: 50px;
    margin: 0 0 70px 0;
}
.modal-video .btn-close {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    color: #fff;
    background: #bb9b6a none;
    position: absolute;
    top: -20px;
    right: -15px;
}
.modal-video .next-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #b09063;
    font-size: 23px;
    font-weight: bold;
    width: 100%;
    margin: 0;
}
.article-content table {
    overflow-x: auto;
}
.article-content tbody, .article-content td, .article-content tfoot, .article-content th, .article-content thead, .article-content tr {
    border-width: 1px;
}

.article-content video {
    background-color: black;
}

.cert-section {
    background-image: url(../images/certificate/certificate-template-desktop.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 36.38%;
    position: relative;
    box-shadow: 1px 7px 11px 1px rgba(0, 0, 0, 0.29);
    -webkit-box-shadow: 1px 7px 11px 1px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 1px 7px 11px 1px rgba(0, 0, 0, 0.29);
}

.cert-content {
    position: absolute;
    width: 30.30%;
    height: 75%;
    top: 13.24%;
    left: 6.14%;
    display: flex;
    flex-direction: column;
}

.cert-shield-icon {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.cert-detail {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 20px 5px 0;
}

.cert-shield-icon .cert-shield-image {
    width: 65%;
    /* height: 122.54%; */
    /* padding-top: 20%; */
}

.cert-contract-detail {
    font-size: 17px;
    margin-left: 20px;
    /* margin-top: 30px; */
    width: 100%;
}

.cert-contract-text {
    margin-right: 40px;
}

.cert-contract-active {
    color: green;
}

.cert-contract-expired {
    color: red;
}

.cert-license-code {
    font-size: 14px;
    /* margin-left: 18px;
    margin-top: 60px; */
    width: 100%;
}

.cert-license-text {
    margin-right: 50px;
}

.cert-casino {
    position: absolute;
    width: 55.30%;
    height: 66.88%; 
    top: 17.21%;
    right: 5.06%;
    display: flex;
    flex-direction: column;
}

.cert-casino-detail {
    display: flex;
    align-content: center; 
    justify-content: flex-start;
    align-items: center;
}

.cert-casino-detail-logo {
   width: 15%;
    /* height: 15%; */
}

.cert-casino-detail-title {
    font-size: 27px;
    margin-left: 12px;
    line-height: 25px;
}
.cert-casino-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 20px 5px 0;
}

.cert-casino-status {
    font-size: 20px;
    /* margin-top: 40px; */
}

.cert-casino-content {
    line-height: 25px;
}
.cert-casino-content-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
    text-align: left;
}

.cert-casino-url {
    padding-top: 15px; 
    line-height: 25px;
}

.cert-casino-url-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
    text-align: left;
}

.cert-casino-url-copy {
    margin-left: 5px;
    width: 25px;
    height: 25px;
}

.cert-casino-disclaimer {
    font-size: 17px;
    /* margin-top: 45px; */
}

.cert-section-mobile {
    background-image: url(../images/certificate/certificate-template-mobile.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    padding-top: 181%;
    position: relative;
    box-shadow: 1px 7px 11px 1px rgba(0, 0, 0, 0.29);
    -webkit-box-shadow: 1px 7px 11px 1px rgba(0, 0, 0, 0.29);
    -moz-box-shadow: 1px 7px 11px 1px rgba(0, 0, 0, 0.29);
}

.cert-content-mobile {
    position: absolute;
    width: 77.64%;
    height: 42.30%;
    top: 20.48%;
    left: 10.80%;
    display: flex;
    flex-direction: column;
}

.cert-detail-mobile {
    padding-top: 7.8%;
}

.cert-casino-detail-mobile {
    top: 5.80%;
    position: absolute;
    text-align: center;
    width: 100%;
    font-size: 16px;
    height: 13%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cert-contract-mobile {
    position: absolute;
    text-align: center;
    top: 62.5%;
    width: 100%;
    line-height: 21px;
    font-size: 16px;
    bottom: 3%;
}

.cert-casino-license-mobile {
    font-size: 13px;
}

.cert-casino-status-mobile {
    display: flex;
    flex-wrap: wrap; 
    flex-direction: column;
    padding-top: 15px;
    height: 100%;
    justify-content: space-around;
}

.cert-casino-status-title-mobile {
    padding-top: 10px;
}

.cert-casino-disclaimer-mobile {
    padding-top: 15px;
    font-size: 11px;
    line-height: 10px;
    padding-left: 25px;
    padding-right: 25px;
}

.create-certificate-button {
    background-color: #bb9b6a;
    height: 40px;
    font-size: 17px;
    font-style: normal;
    display: flex;
    align-items: center;
    border-radius: 10px;
    color: #fff;
    justify-content: center;
    padding: 0 15px;
}
.create-certificate-button:hover {
    background-color: #102945 !important;
    color: #fff !important;
}
.tooltip-cert {
    width: 200px;
    font-size: 12px;
    white-space: normal;
}
.tooltip-cert u{
    color: green;
}

/* Grow */
.hvr-grow {
    vertical-align: middle;
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
}
.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    transform: scale(1.3);
}
/* Social media footer image */
.social-media-facebook {
    background-image: url("/assets/images/social-media/svg/facebook.svg") !important;   /* First Image URL */
    background-size: cover;
    background-repeat: no-repeat;
    width: 34px;
    height: 34px;
    transition: 0.4s;
}
.social-media-facebook:hover {
    background-image: url("/assets/images/social-media/svg/facebook-hover.svg") !important;   /* Second Image URL */
}
.social-media-instagram {
    background-image: url("/assets/images/social-media/svg/instagram.svg") !important;   /* First Image URL */
    background-size: cover;
    background-repeat: no-repeat;
    width: 34px;
    height: 34px;
    transition: 0.4s;
}
.social-media-instagram:hover {
    background-image: url("/assets/images/social-media/svg/instagram-hover.svg") !important;   /* Second Image URL */
}
.social-media-pinterest {
    background-image: url("/assets/images/social-media/svg/pinterest.svg") !important;   /* First Image URL */
    background-size: cover;
    background-repeat: no-repeat;
    width: 34px;
    height: 34px;
    transition: 0.4s;
}
.social-media-pinterest:hover {
    background-image: url("/assets/images/social-media/svg/pinterest-hover.svg") !important;   /* Second Image URL */
}
.social-media-tiktok {
    background-image: url("/assets/images/social-media/svg/tiktok.svg") !important;   /* First Image URL */
    background-size: cover;
    background-repeat: no-repeat;
    width: 34px;
    height: 34px;
    transition: 0.4s;
}
.social-media-tiktok:hover {
    background-image: url("/assets/images/social-media/svg/tiktok-hover.svg") !important;   /* Second Image URL */
}
.social-media-twitter {
    background-image: url("/assets/images/social-media/svg/twitter.svg") !important;   /* First Image URL */
    background-size: cover;
    background-repeat: no-repeat;
    width: 34px;
    height: 34px;
    transition: 0.4s;
}
.social-media-twitter:hover {
    background-image: url("/assets/images/social-media/svg/twitter-hover.svg") !important;   /* Second Image URL */
}
.social-media-youtube {
    background-image: url("/assets/images/social-media/svg/youtube.svg") !important;   /* First Image URL */
    background-size: cover;
    background-repeat: no-repeat;
    width: 34px;
    height: 34px;
    transition: 0.4s;
}
.social-media-youtube:hover {
    background-image: url("/assets/images/social-media/svg/youtube-hover.svg") !important;   /* Second Image URL */
}
.social-media-telegram {
    background-image: url("/assets/images/social-media/svg/telegram.svg") !important;   /* First Image URL */
    background-size: cover;
    background-repeat: no-repeat;
    width: 34px;
    height: 34px;
    transition: 0.4s;
}
.social-media-telegram:hover {
    background-image: url("/assets/images/social-media/svg/telegram-hover.svg") !important;   /* Second Image URL */
}
.verify-phone-button{
    background-color: #bb9b6a;
    font-size: 25px;
    align-items: center;
    border-radius: 4px;
    color: #fff;
    border: none;
}

/* BONUS */
.bonus-filter {
    display: flex;
    flex: 1;
    padding-bottom: 10px;
}
.bonus-filter-type {
    flex: 0.5;
    padding-bottom: 10px;
    padding-right: 10px;
}
.bonus-filter-inner {
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    background-image: linear-gradient(rgba(255, 255, 255) 0%, rgba(241, 241, 241) 30%);
    box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.3);
    -webkit-box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.3);
}
.bonus-filter-header {
    color: var(--primaryColor);
    height: 50px;
    cursor: pointer;
}
.bonus-filter-title {
    display: flex;
    align-items: center;
}
.bonus-filter-title-image {
    width: 30px;
    padding-right: 5px;
}
.bonus-filter-box-height {
    height: 220px;
    overflow: auto;
}
.bonus-filter-checkbox {
    display: flex;
    align-items: center;
    padding-left: 25px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
}
.bonus-filter-checkbox-content {
    display: flex;
    align-items: center;
}
.bonus-filter-checkbox label {
    padding: 0 10px;
    white-space: nowrap;
}
.bonus-filter-action {
    display: flex;
    justify-content: flex-end; 
}
.bonus-action-button {
    border: none;
    color: white;
    background-color: var(--primaryColor);
    border-radius: 10px;
    padding: 3px 10px;
}
.bonus-action-button:hover {
    color: white;
    background-color: var(--secondaryColor);
}
.bonus-filter-company {
    flex: 0.3;
    padding-bottom: 10px;
    padding-right: 10px;
}
.bonus-filter-company-title {
    display: flex;
    align-items: flex-start;
}
.bonus-filter-company-list {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-top: 10px;
}
.bonus-filter-company-list-content {
    display: flex;
    flex-direction: column;
}
.bonus-filter-company-list-content span {
    padding-bottom: 5px;
    cursor: pointer;
}
.bonus-filter-company-list-content .active {
    background-color: #d1d1d1;
}
.bonus-filter-best {
    flex: 0.3;
    padding-bottom: 10px; 
}

.bonus-inner-box-green {
    border-radius: 15px;
    border: 2px solid #00d100;
    box-shadow: 5px 5px 10px #00d100;
    margin-bottom: 15px;
    background-color: white;
}
.bonus-inner-box-yellow {
    border-radius: 15px;
    border: 2px solid #d1d100;
    box-shadow: 5px 5px 10px #d1d100;
    margin-bottom: 15px;
    background-color: white;
}
.bonus-inner-box-red {
    border-radius: 15px;
    border: 2px solid #d10000;
    box-shadow: 5px 5px 10px #d10000;
    margin-bottom: 15px;
    background-color: white;
}
.bonus-inner-box-white {
    border-radius: 15px;
    margin-bottom: 15px;
    background-color: white;
    box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.3);
    -webkit-box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.3);
}
.bonus-inner-box-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.bonus-inner-box-left {
    padding: 20px 30px;
    display: flex;
    flex: 1;
}
.bonus-inner-box-logo {
    width: 150px;
}
.bonus-inner-box-text {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    flex: 1;
    justify-content: space-evenly;
}
.bonus-inner-box-title {
    color: red;
}
.bonus-inner-box-detail {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}
.bonus-inner-field {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.bonus-inner-text-field {
    color: red;
    font-weight: 700;
    padding-top: 5px;
}
.bonus-inner-tag {
    border: 2px solid #bb9b6a;
    border-radius: 10px;
    padding: 2px 10px;
}
.bonus-inner-game-type-image {
    display: flex;
}
.bonus-inner-game-type-image-field {
    width: 35px;
    padding-top: 5px;
    padding-right: 5px;
}
.bonus-inner-box-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 250px;
    align-items: center;
}
.bonus-inner-box-turnover {
    padding-bottom: 10px;
}
.bonus-inner-box-button {
    border: none;
    color: white;
    background-color: green;
    padding: 10px 20px;
    border-radius: 10px;
    width: 120px;
}
.bonus-inner-box-view-more {
    padding-top: 10px;
    font-weight: 700;
    cursor: pointer;
}
.bonus-inner-extra-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ececec;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%
}
.bonus-inner-extra-content-left {
    padding: 20px 20px 20px 80px;
}
.bonus-inner-extra-content-right {
    display: flex;
    flex-direction: column;
    width: 250px;
}
.bonus-inner-extra-content-row {
    display: flex;
    align-items: center;
}
.bonus-inner-extra-content-box1 {
    display: flex;
    align-items: center;
    padding: 0 30px 5px;
    border-right: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    width: 350px;
}
.bonus-inner-extra-content-box2 {
    display: flex;
    align-items: center;
    padding: 0 30px 5px;
    border-bottom: 1px solid #d1d1d1;
    width: 350px;
}
.bonus-inner-extra-content-box3 {
    display: flex;
    align-items: center;
    padding: 0 30px;
    border-right: 1px solid #d1d1d1;
    width: 350px;
}
.bonus-inner-extra-content-box4 {
    display: flex;
    align-items: center;
    padding: 0 30px;
    width: 350px;
}
.bonus-inner-extra-content-image {
    width: 35px;
    padding-top: 5px;
    padding-right: 5px;
}
.bonus-inner-extra-content-right-image {
    width: 30px;
    padding-right: 10px;
}
.bonus-subscribe-sec {
    position: relative;
}
.bonus-inner-box-white-hidden {
    border-radius: 15px;
    margin-bottom: 15px;
    background-color: white;
    box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.3);
    -webkit-box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.3);
    -moz-box-shadow: 1px 2px 5px 2px rgba(0,0,0,0.3);
    filter: blur(10px);
    user-select: none;
}
.bonus-subscribe-box {
    position: absolute;
    z-index: 99;
    top: 10px;
    left: 30%;
    right: 30%;
}
.bonus-subscribe-box-inner {
    background-color: #ffffff;
    /* width: 500px; */
    border-radius: 5px;
    border: 2px solid var(--primaryColor);
    padding: 20px;
}
.bonus-subscribe-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}
.bonus-subscribe-title {
    padding-top: 10px;
}
.bonus-subscribe-button {
    border: none;
    border-radius: 10px;
    color: white;
    background-color: var(--primaryColor);
    padding: 10px 20px;
    margin-top: 30px;
}
.bonus-subscribe-button:hover {
    background-color: var(--secondaryColor);
}