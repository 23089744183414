@media (max-width:1920px) {
    /****** HEADER *****/
    header {
        padding: 30px 0;
    }

    /****** GENERAL *****/
    .page-content {
        /* padding-top: 150px; */
    }

    /***** SEARCH BOX *****/
    .search-dropdown {
        top: 100px;
    }
}

@media (min-width:1600px) {
    /***** USER PROFILE *******/
    .profile-mid-section {
        margin-left: 120px;  
    }
    .profile-tab {
        top: 470px;
        min-width: 280px;
        min-height: 280px;
    }
}

@media (max-width:1600px) {
    /***** Custom Use *****/
    .custom_title_icon_size{
        width: 60px;
    }
    .custom_icon_size{
        width: 75px;
    }
    .custom_h2{
        font-size: 40px;
    }
    .custom_h3{
        font-size: 32px;
    }
    .custom_h4{
        font-size: 24px;
    }
    .custom_p{
        font-size: 22px;
    }
    .custom_p2{
        font-size: 18px;
    }
    .custom_p3{
        font-size: 14px;
    }
    .custom_border_gold_withWidth{
        width: 120px;
    }


    /****** HEADER *****/
    .navbar-collapse {
        justify-content: center;
    }
    header {
        padding: 25px 0;
    }
    .navbar-nav {
        padding-left: 0;
    }
    .navbar-nav .nav-item {
        margin-right: unset;
    }
    .navbar-brand img {
        width: 175px;
    }
    .navbar-brand {
        margin: 0 70px;
    }
    /* .navbar-nav .nav-item .nav-link {
        font-size: 20px;
    } */
    .submenu {
        padding: 30px 0px 10px;
    }
    
    /****** GENERAL *****/
    .page-content {
        /* padding-top: 150px; */
    }
    .close-btn img {
        height: 25px;
    }
    .btn-default {
        height: 55px;
        width: 160px;
        /* font-size: 20px; */
        margin-left: 15px;
    }
    .general-box {
        padding: 51px 60px 40px;
    }
    .general-box h1 {
        font-size: 35px;
    }
    .general-box p {
        font-size: 20px;
        line-height: unset;
    }

    /****** BREADCRUMB *****/
    .breadcrumb .breadcrumb-item,
    .breadcrumb .breadcrumb-item a {
        font-size: 20px;
    }

    /****** HOMEPAGE *****/
    .homepage_title {
        font-size: 35px;
    }
    .homepage-img-trust {
        width: 70%;
    }
    .homepage-content-top-title {
        font-size: 35px;
    }
    .homepage-content-top {
        font-size: 21px;
    }
    .homepage-content-middle-title {
        font-size: 35px;
    }
    .homepage-content-middle-subtitle {
        font-size: 30px;
    }
    .homepage-content-middle-text {
        font-size: 15px;
    }
    .homepage-check {
        font-size: 15px;
        line-height: 1.6;
    }
    .homepage-faq {
        font-size: 22px;
    }
    .homepage-faq-ans {
        font-size: 18px;
    }

    /****** ABOUT US / CONTACT US / TERMS & CONDITIONS *****/
    .about-us-inner h1,
    .contact-us-inner h1,
    .tnc-inner h1 {
        font-size: 35px;
    }
    .about-us-inner .about-us-subtitle h2,
    .contact-us-inner .contact-us-subtitle h2,
    .tnc-inner .tnc-subtitle h2 {
        font-size: 30px;
    }
    .about-us-inner p,
    .contact-us-inner p,
    .tnc-inner p {
        font-size: 20px;
    }

    /****** NEWSLETTER *****/
    .call-action-sec {
        padding: 50px 0;
    }
    .call-action-left form {
        padding: 15px 0;
    }
    .call-action-left form .form-control {
        font-size: 16px;
        height: 55px;
        padding-left: 20px;
    }
    .call-action-left-button {
        height: 55px !important;
        width: 160px !important;
        font-size: 20px !important;
        margin-left: 15px !important;
    }
    .follow-us h4 {
        font-size: 20px;
    }
    .follow-us ul li img {
        height: 55px;
    }

    /***** SEARCH BOX *****/
    .search-dropdown {
        top: 83px;
    }

    /***** GUIDE LISTING PAGE *******/
    .guide-sport {
        padding: 60px 40px;
    }
    .guide-sport-text .media {
        margin: 0 0 15px 0;
    }
    .guide-sport-text .media .guide-media-text {
        max-width: 50px;
    }
    .guide-sport-text p {
        font-size: 20px;
        line-height: 28px;
    }
    .guide-sport-text .media h2 {
        font-size: 40px;
    }
    .guide-sport-text .media a {
        font-size: 22px;
    }
    .guide-swiper-btn .swiper-button-next img, .guide-swiper-btn .swiper-button-prev img {
        height: 25px;
    }
    .guide-swiper-btn .swiper-button-next, .guide-swiper-btn .swiper-button-prev {
        width: 62px;
        height: 53px;
        border-radius: 10px;
        margin-left: 15px;
        border: 3px solid #bb9b6a;
    }
    .guide-swiper-btn {
        margin: 20px 0;
    }
    .guide-sport-box .text-box .text-box-inner {
        padding: 10px 15px;
    }
    .guide-sport-box .text-box .text-box-inner h3 {
        font-size: 22px;
        margin: 8px 0;
    }
    .guide-sport-box .text-box .text-box-inner p {
        font-size: 16px;
        line-height: 24px;
    }
    .more-history {
        margin: 20px 0 0 0;
    }
    .more-history a img {
        height: 35px;
    }

    /***** MONTHLY SPONSORED *******/
    .marketing-parnership-content h2 {
        font-size: 30px;
    }
    .marketing-parnership-content p {
        font-size: 20px;
    }

    /***** USER PROFILE *******/
    .profile-icon img {
        height: 100px;
    }
    .profile-mid-section {
        margin-left: 120px;
        padding: 50px 40px;
    }

    /***** PROFILE CHANGE PASSOWRD *******/
    .profile-password h2 {
        font-size: 35px;
    }
    .change-password-form .password-form-input {
        font-size: 28px;
    }
    .change-password-form .form-control {
        font-size: 17px;
    }

    /***** PROFILE BUY DIAMOND *******/
    .profile-buy-diamond h2 {
        font-size: 35px;
    }

    /***** PROFILE HISTORY *******/
    .history-diamond h2 {
        font-size: 35px;
    }
    .history-diamond-inner table thead tr th {
        font-size: 25px;
    }
    .history-diamond-inner table tbody tr td {
        font-size: 19px;
    }
    .more-history a {
        font-size: 25px;
    }

    /***** PROFILE TAB *******/
    .profile-tab {
        top: 470px;
        min-width: 300px;
        min-height: 300px;
    }
    .profile-tab-list {
        padding: 30px;
        font-size: 22px;
    }
    .profile-tab-list li {
        padding-left: 0px;
        border-bottom: 2px solid #ba9b6a;
        padding: 10px;
    }
    .profile-tab-list li:last-child {
        border-bottom: none;
    }
    .profile-tab-list li a {
        color: #ffffff;
    }
    .profile-tab-list li .active {
        color: #ba9b6a;
    }

    /****** SINGLE ARTICLE LEFT *******/
    .article-text h1 {
        font-size: 32px;
        margin: 0 0 15px 0;
    }
    .article-text ul li {
        font-size: 16px;
    }
    .article-content p {
        font-size: 20px;
        line-height: 1.2;
        margin: 0 0 20px 0;
    }
    .article-content table, .article-content tr, .article-content td {
        font-size: 20px;
    }
    .sponsord-tag li a {
        font-size: 20px;
        height: 35px;
        padding: 0 15px;
    }
    .accordion-button {
        font-size: 22px;
    }
    .accordion-body {
        font-size: 18px;
    }
    .sponsored-add .media h4 {
        font-size: 23px;
    }
    .sponsored-survey-form h2 {
        font-size: 22px;
    }
    .survey-form-question-btm {
        font-size: 21px;
    }

    /* single review */
    .single-payment-tab .nav-pills .nav-item .nav-link{
        font-size: 14px;
    }
    .single-review-box .text-box .media span {
        font-size: 16px;
    }
    .loading-speed {
        padding: 25px 30px 25px;
        margin: 35px 50px;
    }
    .loading-speed .swiper-counter {
        font-size: 36px;
    }
    .loading-speed .text-inner p {
        font-size: 20px;
        margin: 35px 70px 25px;
    }
    .loading-speed .text-inner a {
        font-size: 24px;
    }
    .loading-speed ul li {
        font-size: 20px;
        padding: 20px 30px;
        white-space: nowrap;
    }

    /* grade-ranking */
    .award-best-casino-item-imageCasino{
        width: 170px;
        height: 170px;
    }
    .ranking-casino-item-image{
        height: 150px;
        width: 220px;
    }
    .ranking-casino-item-content-left{
        height: 150px;
    }
    .ranking-casino-item-content-right{
        height: 150px;
    }
    .ranking-casino-item-content-right-button{
        padding: 20px 30px;
    }

    /* Unsubscribe */
    .unsubscribe-btn{
        padding: 15px 40px;
        margin: 25px 0 15px;
    }
    .unsubscribe-textarea{
        font-size: 20px !important;
    }
    .unsubscribe-left-icon {
        font-size: 25px;
    }

    /* why ugrado */
    .why-ugrado-item-content > .custom_h4{
        font-size: 18px;
    }

    /* why trust us */
    .why-trust-us-swiper-item{
        max-width: 280px;
        padding: 30px 10px;
    }

    /** Added By CM Start **/
    .cert-contract-detail {
        font-size: 13px;
        /* margin-left: 15px;
        margin-top: 5%; */
        padding: 0 10px;
        margin: 10px;
    }
    .cert-casino-url-text-mobile {
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 450px;
        margin: 0 auto;
    }

    .cert-license-code {
        font-size: 12px;
        /* margin-top: 50px;
        margin-left: 3px; */
    }

    .cert-casino-disclaimer {
        font-size: 13px;
        /* margin-top: 20px; */
    }
    /** Added By CM End **/
}

@media (max-width:1400px) {
    /***** Custom Use *****/
    .custom_container, .custom_container_withoutPadding{
        max-width: 1190px;
    }
    .custom_container2 {
        max-width: 1050px;
    }
    /*
    .container{
        max-width: 1080px;
    }
    */

    /***** USER PROFILE *******/
    .profile-name {
        margin-left: -25px;
    }
    .profile-status {
        margin-left: -10px;
    }

    /***** PROFILE TAB *******/
    .profile-tab {
        top: 470px;
        min-width: 230px;
        min-height: 230px;
    }
    .profile-tab-list {
        padding: 20px;
        font-size: 15px;
    }

    /***** NAVIGATION TAB *******/
    .navigation-box span {
        padding-left: 10px;
        font-size: 23px;
    }

    /****** SINGLE ARTICLE LEFT *******/
    .article-text h1 {
        font-size: 30px;
    }

    /****** SINGLE ARTICLE RIGHT *******/
    .latest-news h2{
        font-size: 22px;
    }
    .latest-news-box .text-box h4{
        font-size: 18px;
        -webkit-line-clamp: 3;
    }

    /* grade-ranking */
    .ranking-year-category-box .swiper-button-prev{
        left: 5px;
    }
    .ranking-year-category-box .swiper-button-next{
        right:5px;
    }
    .ranking-casino-item-image{
        height: 160px;
        width: 220px;
    }
    .ranking-casino-item-content-right{
        height: 160px;
    }
    .ranking-casino-item-content-left{
        height: 160px;
    }
    .award-best-casino-item-imageCasino{
        width: 150px;
        height: 150px;
    }

    /* why ugrado */
    .why-ugrado-item{
        padding: 30px 20px;
    }

    /* Unsubscribe */
    .unsubscribe-image {
        width: 150px;
    }
    
    /** Added By CM Start **/
    .cert-contract-detail {
        font-size: 13px;
        /* margin-left: 8px; */
    }

    .cert-casino-status {
        font-size: 18px;
        /* margin-top: 30px; */
    }

    .cert-license-code {
        font-size: 13px;
        /* margin-left: 10px;
        margin-top: 45px; */
    }

    .cert-license-text {
        margin-right: 15px;
    }

    .cert-casino-disclaimer {
        font-size: 13px;
        margin-top: 14px;
    }
    /** Added By CM End **/
}

@media (max-width:1300px) {
    /***** NAVIGATION TAB *******/
    .navigation-box span {
        padding-left: 10px;
        font-size: 16px;
    }
    .custom_container2 {
        max-width: 950px;
    }
    /** Added By CM Start **/
    .cert-contract-detail {
        font-size: 14px;
        margin-left: 18px;
    }

    .cert-license-code {
        font-size: 15px;
        margin-left: 10px;
        margin-top: 50px;
    }

    .cert-casino-detail-title {
        font-size: 22px;
        line-height: 22px;
    }

    .cert-casino-status {
        font-size: 18px;
        margin-top: 18px;
    }

    .cert-casino-url {
        padding-top: 12px; 
        line-height: 22px;
    }

    .cert-casino-url-copy {
        width: 20px;
        height: 20px;
    }

    .cert-casino-disclaimer {
        font-size: 14px;
        margin-top: 35px;
    }
    /** Added By CM End **/

    /* BONUS */
    .bonus-inner-tag {
        font-size: 12px;
    }
    .bonus-inner-game-type-image-field {
        width: 30px;
    }
    .bonus-inner-box-right {
        width: 180px;
    }
    .bonus-inner-extra-content-box1, .bonus-inner-extra-content-box2, .bonus-inner-extra-content-box3, .bonus-inner-extra-content-box4 {
        padding: 0 20px 5px;
        width: 250px;
    }
    .bonus-inner-extra-content-text {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width:1199px) {
    /***** Custom Use *****/
    .custom_container {
        max-width: 990px;
        padding: 0 30px;
    }
    .custom_container_withoutPadding{
        max-width: 990px;
    }
    .custom_container2{
        max-width: 800px;
        padding: 0 30px;
    }
    /****** HEADER *****/
    .container{
        max-width: 100%
    }
    header {
        padding: 20px 0;
    }
    .navbar-mobile {
        flex: 1;
    }
    .navbar-toggler {
        width: 60px;
    }
    .navbar-brand {
        margin: 0;
        flex: 1;
        display: flex;
        justify-content: center;
    }
    .navbar-brand img {
        width: 175px;
    }
    .navbar-collapse {
        position: absolute;
        top: 84px;
        left: 0;
        background-color: #fff;
        width: 100%;
        padding: 15px 0;
    }
    .navbar-nav {
        padding-left: 20px;
        align-items: flex-start;
    }
    .navbar-nav .nav-item .nav-link {
        font-size: 40px;
    }
    .navbar-nav-left {
        flex-direction: column;
    }
    .navbar-nav-left .nav-item {
        justify-content: unset;
        padding-bottom: 10px;
    }
    .navbar-nav-right {
        flex-direction: column;
    }
    .navbar-nav-right .nav-item {
        justify-content: unset;
        padding-bottom: 10px;
    }
    .submenu {
        padding: 30px 0px 0px;
        position: static;
        transform: translate(0, 0);
        width: 100%;
    }
    .submenu ul li a {
        font-size: 20px;
        padding: 10px 0;
        line-height: 1;
    }

    /****** GENERAL *****/
    .page-content {
        /* padding-top: 230px; */
    }
    .btn-default {
        height: 70px;
        width: 190px;
        /* font-size: 31px; */
        border-radius: 18px
    }

    /****** BREADCRUMB *****/
    .breadcrumb .breadcrumb-item, .breadcrumb .breadcrumb-item a {
        font-size: 26px;
    }
    
    /****** HOMEPAGE *****/
    .homepage-content-top-title {
        font-size: 30px;
    }
    .homepage-content-top {
        font-size: 19px;
    }
    .homepage-content-middle-title {
        font-size: 28px;
    }
    .homepage-content-middle-subtitle {
        font-size: 25px;
    }
    .homepage-content-middle-text {
        font-size: 15px;
    }
    .homepage-check {
        font-size: 15px;
        line-height: 1.6;
    }
    .homepage-faq {
        font-size: 20px;
    }
    .homepage-faq-ans {
        font-size: 17px;
    }
    .homepage-swiper .swiper-pagination-bullet{
        width: 5px;
        height: 5px;
        margin: 0 7px;
    }
    .homepage-latest-article-highlight{
        flex-direction: column;
    }
    .homepage-recent-company-title{
        font-size: 26px;
    }
    .homepage-recent-company-star li {
        width: 20px;
    }
    .homepage-recent-company-diamond{
        width: 35px;
    }
    .homepage-recent-company-button{
        padding: 10px 15px;
        font-size: 15px;
    }

    /****** ABOUT US / CONTACT US / TERMS & CONDITIONS *****/
    .about-us-inner,
    .tnc-inner {
        margin-bottom: 275px;
    }
    .about-us-inner h1,
    .contact-us-inner h1,
    .tnc-inner h1 {
        font-size: 50px;
        margin: 0px 0 50px 0;
    }
    .about-us-inner p,
    .contact-us-inner p,
    .tnc-inner p {
        font-size: 35px;
    }

    /***** NEWSLETTER *****/
    .call-action-mobile {
        padding-bottom: 300px;
    }
    .call-action-mobile h3 {
        text-align: center;
        font-size: 26px;
        padding-bottom: 10px;
        font-weight: 500;
        padding: 0 22px;
    }
    .call-action-mobile form {
        padding: 10px 0;
    }
    .call-action-mobile form .form-control {
        border: 1px solid #b7b7b7;
        color: #6a6a6a;
        font-size: 26px;
        font-weight: normal;
        border-radius: 5px;
        height: 76px;
        box-shadow: none;
        padding-left: 32px;
        flex: 1;
        background-color: #f2f2f2;
        text-align: center;
    }
    .call-action-mobile form button {
        background-color: #bb9b6a;
        margin-top: 20px;
        height: 80px;
        width: 100%;
        font-size: 26px;
        font-style: normal;
        display: flex;
        align-items: center;
        border-radius: 10px;
        color: #fff;
        justify-content: center;
        border: none;
    }
    .call-action-mobile form button:hover {
        background-color: #102945;
        color: #fff;
    }
    .call-action-mobile form button:disabled {
        opacity: 0.7;
        background-color: #bb9b6a;
        color: #fff;
    }
    .call-action-mobile p {
        font-size: 18px;
    }

    /****** MOBILE FOOTER ********/
    .mobile-footer {
        /* padding: 48px 40px 28px; */
        padding: 20px 20px;
        background-color: rgb(252, 250, 247);
        box-shadow: 0px 0px 16px 0px rgb(0 0 0 / 26%);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        position: fixed;
        left: 0;
        z-index: 200;
        bottom: 0; 
        width: 100%;
    }
    .mobile-footer ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .mobile-footer ul li{
        width: 120px;
    }
    .mobile-footer ul li:last-child {
        margin: 0;
    }
    .mobile-footer ul li a {
        display: flex;
        align-items: center;
        line-height: 1;
        flex-direction: column;
    }
    .mobile-footer ul li a img {
        height: 50px;
    }
    .mobile-footer ul li.footer-review a img{
        height: 150px;
    }
    .mobile-footer ul li a span {
        color: #8e6b51;
        font-weight: bold;
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 22px;
        margin: 10px 0 0 0;
    }
    .mobile-footer ul li.footer-review {
        margin-top: -95px;
        width: auto;
    }
    .mobile-footer ul li.footer-review a span {
        margin: 5px 0 0 0;
    }

    /***** SEARCH BOX *****/
    .search-dropdown {
        top: 134px;
    }
    .search-dropdown form .form-control {
        font-size: 36px;
    }
    .search-dropdown .text-box h4 {
        font-size: 35px;
        margin-bottom: 20px;
    }
    .search-dropdown .text-box ul li a img {
        margin-right: 15px;
        height: 26px;
    }
    .search-dropdown .text-box ul li a {
        height: 55px;
        border-radius: 10px;
        font-size: 22px;
        padding: 0px 35px;
    }
    .search-dropdown form span img {
        height: 40px;
    }

    /****** CONTACT US *****/
    .contact-us-inner {
        margin-bottom: 315px;
    }

    /***** GUIDE LISTING PAGE *******/
    .guide-inner {
        padding-bottom: 270px;
    }
    .guide-sport-box .text-box .text-box-inner h3 {
        font-size: 22px;
    }
    .guide-sport-text .media {
        margin: 0 0 20px 0;
    }
    .guide-sport-text .media h2 {
        font-size: 52.29px;
    }
    .guide-sport-text .media a {
        font-size: 30px;
    }
    .guide-sport-text p {
        font-size: 30px;
        line-height: 1.2;
    }
    .guide-sport {
        padding: 35px 48px;
    }
    .guide-swiper-btn .swiper-button-next, .guide-swiper-btn .swiper-button-prev {
        width: 82px;
        height: 75px;
        border-radius: 20px;
        margin-left: 22px;
        border: 3px solid #bb9b6a;
    }
    .guide-sport-box .text-box .text-box-inner h3 {
        font-size: 30px;
        margin: 8px 0;
    }
    .guide-sport-box .text-box .text-box-inner p {
        font-size: 20px;
        line-height: 24px;
        line-height: 1.2;
    }
    /* .guide-sport-list ul li {
        margin-right: 15px;
    } */
    .guide-sport-list ul li a {
        font-size: 13.53px;
    }
    .guide-sport-box .text-box .text-box-inner {
        padding: 13px 20px;
    }
    .guide-sport-inner {
        margin: 0 -85px 0 -5px;
    }
    .guide-swiper-btn {
        padding: 0 85px 0 0px;
        margin: 0px 0 30px;
    }
    .all-article-sec {
        padding: 35px 5px;
    }
    .all-article-sec .guide-sport-text {
        padding: 0 0 0 45px;
    }
    .all-article-inner {
        margin: 23px 0 0 0;
    }
    .all-article-inner .guide-sport-box {
        display: flex;
        background-color: #fff;
    }
    .all-article-inner .guide-sport-box .image-holder {
        max-width: 360px;
        width: 100%;
    }
    .all-article-inner .guide-sport-box .image-holder img {
        width: 100%;
        border-radius: 45px 0px 0 45px;
    }
    .all-article-inner .guide-sport-box .text-box {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 30px;
    }
    .all-article-inner .guide-sport-box .text-box .text-box-inner {
        padding: 0;
    }
    .all-article-inner .guide-sport-box .text-box .text-box-inner ul li {
        font-size: 20px;
    }
    .all-article-inner .guide-sport-box .text-box .text-box-inner h3 {
        font-size: 30px;
    }
    .all-article-inner .guide-sport-box .text-box .text-box-inner p {
        font-size: 20px;
        -webkit-line-clamp: 3;
    }
    .all-article-inner .guide-sport-list {
        border: none;
        padding: 0;
        margin: 27px 0 0 0;
    }
    .all-article-inner .guide-sport-list ul li a {
        font-size: 20px;
        height: 39px;
        border-radius: 8px;
        color: #7f7f7f;
        padding: 0 8px;
    }
    .more-history a img {
        height: 58px;
    }
    .more-history a {
        font-size: 30px;
    }
    .more-history {
        margin: 20px 0 130px 0;
    }

    /***** MONTHLY SPONSORED *******/
    .monthly-sponsored-sec {
        padding-bottom: 280px;
    }
    .marketing-parnership-content h2 {
        font-size: 35px;
    }
    .marketing-parnership-content p {
        font-size: 23px;
    }

    /***** REGISTER / LOGIN *******/
    .register-sec {
        padding-bottom: 290px;
    }
    .login-inputs .signup-checkbox-info {
        width: 400px;
    }

    /***** USER PROFILE *******/
    .profile-tab-content {
        padding-top: 210px;
        /* padding-bottom: 190px; */
    }
    .profile-top-section-mobile {
        background: #fefefe;
        min-height: 200px;
        border-radius: 30px;
        box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.11);
        position: relative;
    }
    .profile-detail-mobile {
        display:flex;
        justify-content: center;
        flex-direction: column;
    }
    .profile-icon-mobile {
        border: 5px solid #ffffff;
        background-color: red;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin: 0 auto;
        width: 250px;
        height: 250px;
        margin-top: -130px;
    }
    .profile-icon-mobile img {
        height: 150px;
    }
    .profile-icon-mobile .profile-edit img {
        height: 80px !important;
    }
    .profile-status-mobile {
        padding: 30px 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .verify-user {
        padding-left: 270px;
        justify-content: space-around;
    }
    .verify-phone-verify {
        width: 100px;
        height: 100px;
        font-size: 60px;
    }
    .verify-phone-verified {
        font-size: 30px;
    }
    .verify-phone-not-verify {
        width: 100px;
        height: 100px;
        font-size: 60px;
    }
    .verify-text-right {
        position: relative;
        display: flex;
        align-items: center;
    }
    .verify-text {
        width: 210px;
        font-size: 23px;
    }
    .profile-name {
        padding-top: 30px;
        width: 50%;
        text-align: center;
        margin-left: 0px;
    }
    .diamond-login-mobile {
        padding-top: 10px;
        width: 120px;
    }
    .diamond-user {
        font-size: 26px;
    }
    .diamond-user .diamond-icon {
        margin-left: -38px;
    }
    .diamond-user .diamond-icon img {
        width: 100px;
    }

    /***** PROFILE TAB *******/
    .profile-mobile-tab {
        width: 100%;
        padding-top: 30px;
    }
    .profile-mobile-tab .nav {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .profile-mobile-tab .nav-pills .nav-item .nav-link {
        background-color: #253d56;
        color: #fff;
        padding: 22px 35px 43px;
        font-size: 25px;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0;
    }
    .profile-mobile-tab .nav-pills .nav-item .border-left-curve {
        border-top-left-radius: 30px;
    }
    .profile-mobile-tab .nav-pills .nav-item .border-right-curve {
        border-top-right-radius: 30px;
    }
    .profile-mobile-tab .nav-pills .nav-item .nav-link.active {
        background-color: #bb9b6a;
    }
    .profile-mobile-content {
        border-radius: 25px;
        background: #fefefe;
        min-height: 200px;
        box-shadow: 0.418px 2.971px 8.64px 0.36px rgba(12, 12, 12, 0.11);
        margin: -30px 0 0 0;
        position: relative;
        z-index: 1;
    }
    .profile-mobile-content h2 {
        font-size: 50px;
        color: #bb9b6a;
        padding-bottom: 20px;
    }

    /***** PROFILE CHANGE PASSOWRD *******/
    .change-password-form .form-control {
        padding-top: 20px;
        padding-bottom: 20px;
        font-size: 27px;
        line-height: 33px;
        padding-left: 23px;
        padding-right: 10px;
    }
    .change-password-form .form-error-content {
        font-size: 25px;
    }
    .change-password-form .relative {
        position: relative;
    }
    .change-password-form .checkbox-not-verify {
        font-size: 30px;
        color: #cccccc;
        position: absolute;
        top: 15px;
        right: 35px;
    }
    .change-password-form .checkbox-verify {
        font-size: 30px;
        color: #43a047;
        position: absolute;
        top: 15px;
        right: 35px;
    }
    .profile-button-pill {
        width: 300px;
        height: 95px;
        font-size: 30px;
    }

    /***** PROFILE BUY DIAMOND *******/
    .diamond-package-text-box h3 {
        font-size: 30px;
    }
    .diamond-package-timer {
        top: 55px;
        width: 170px;
        font-size: 26px;
    }
    .diamond-package-box img {
        width: 100%;
        height: auto;
    }
    .diamond-package-flash-sales {
        font-size: 25px;
    }
    .diamond-package-price {
        font-size: 25px;
    }
    .diamond-package-price .strikethrough {
        font-size: 19px;
    }

    /***** PROFILE HISTORY *******/
    .history-diamond-inner table thead tr th {
        font-size: 28px;
    }
    .history-diamond-inner table tbody tr td {
        font-size: 24px;
    }
    .history-diamond-inner table tbody tr td:last-child {
        text-align: right;
    }
    .history-diamond-inner table thead tr th:last-child {
        text-align: right;
    }

    /***** NAVIGATION TAB *******/
    .profile-bottom-section-mobile {
        padding: 20px 20px;
    }
    .profile-bottom-section-mobile h3 {
        color: #b09063;
        font-size: 50px;
        font-weight: normal;
        margin: 30px 0 20px 0;
    }
    .navigation-box {
        padding: 40px 30px;
        margin-bottom: 20px;
    }
    .navigation-box span {
        font-size: 35px;
    }

    /****** SINGLE ARTICLE LEFT *******/
    .article-text h1 {
        font-size: 45px;
    }
    .article-text ul li {
        font-size: 27px;
    }
    .article-content p {
        font-size: 18px;
        line-height: 1.3;
        margin: 0 0 20px 0;
    }
    .article-content table, .article-content tr, .article-content td{
        font-size: 18px;
    }
    .article-content h2 {
        font-size: 35px;
    }
    .sponsord-tag li a {
        font-size: 27px;
        height: 50px;
        padding: 0 15px;
    }
    .accordion-button {
        font-size: 27px;
    }
    .accordion-body {
        font-size: 21px;
    }
    .sponsord-add .media h4 {
        font-size: 23px;
    }
    .sponsord-survey-form h2 {
        font-size: 22px;
    }
    .survey-form-question-btm {
        font-size: 21px;
    }
    .article-latest-bonus-mobile {
        display: flex;
        justify-content: center;
    }
    .article-latest-bonus-mobile-box {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 30px 50px;
    }
    .article-latest-bonus-mobile-inner {
        padding: 10px 20px;
        border-radius: 10px;
        max-width: 420px;
        background-color: var(--colorWhite);
        width: 100%;
    }
    .article-latest-bonus-mobile-box-green {
        border: 3px solid #00d100;
    }
    .article-latest-bonus-mobile-box-yellow {
        border: 3px solid #d1d100;
    }
    .article-latest-bonus-mobile-box-red {
        border: 3px solid #d10000;
    }
    .article-latest-bonus-mobile-box-black {
        border: 3px solid #d1d1d1;
    }
    .article-latest-bonus-mobile-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .article-latest-bonus-mobile-logo {
        width: 80px;
    }
    .article-latest-bonus-mobile-title {
        font-size: 26px;
        font-weight: bold;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        text-align: right;
    }
    .article-latest-bonus-mobile-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 20px;
        font-weight: bold;
    }
    .article-latest-bonus-mobile-button {
        border: none;
        background-color: var(--colorGreen);
        color: white;
        font-weight: bold;
        border-radius: 5px;
        padding: 5px;
        font-size: 16px; 
    }
    .article-latest-bonus-mobile-button:hover {
        background-color: var(--colorDarkGreen);
    }
    .article-latest-bonus-mobile .swiper-button-prev2, .article-latest-bonus-mobile .swiper-button-next2 {
        color: #d1d1d1;
    }

    /****** SINGLE ARTICLE POLL LEFT *******/
    .survey-form-float {
        display: none;
        width: 480px;
    }
    .survey-form-float-title {
        font-size: 34px;
    }
    .survey-form-float-question {
        font-size: 32px;
    }
    .survey-form-float-options {
        font-size: 25px;
    }
    .survey-form-float-mobile-gesture {
        position: fixed;
        /* left: 20px; */
        top: 40%;
        cursor: pointer;
        animation: blink 1s infinite;
    }
    @keyframes blink {
        0% {
            opacity: 0;
        }
        50% {
            opacity: .75;
        }
        100% {
            opacity: 1;
        }
    }
    .survey-form-float-mobile-gesture-close {
        position: absolute;
        right: -110px;
        top: 40%;
        cursor: pointer;
    }
    .survey-form-float-options input {
        height: 15px;
        width: 15px;
    }
    .survey-form-float-triangle-click img, .survey-form-float-triangle-click-close img {
        height: 60px;
    }
    .survey-form-float-triangle-click, .survey-form-float-triangle-click-close {
        right: -35px;
    }
    .survey-form-float-hide {
        display: none !important;
    }

    /****** SINGLE ARTICLE RIGHT *******/
    .single-article-right {
        padding-top: 30px;
    }
    .latest-news-box .text-box h4 {
        font-size: 38px;
    }

    /***** Vote Ranking *****/
    .vote-ranking-whatisdiamond {
        padding: 20px 0;
    }
    .vote-ranking-diamondRankingBoard-title{
        flex-direction: column;
    }
    .vote-ranking-diamondRankingBoard-board{
        width: 100%;
        box-shadow: unset;
        border-radius: unset;
    }
    .vote-ranking-diamondRankingBoard-board-list{
        margin-top: unset;
        display: flex;
        flex-wrap: wrap;
    }
    .vote-ranking-diamondRankingBoard-title-icon{
        display: block;
    }
    .vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-remainWallet{
        margin-top: unset;
    }
    .vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-remainWallet-image{
        top: -3px;
    }
    .vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-confirm-button{
        padding: 10px 40px;
        margin-top: 10px;
    }
    .vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-column:last-child{
        align-items: flex-start;
    }
    .vote-ranking-diamondRankingBoard-title-image{
        margin-right:unset;
    }

    /* grade-ranking */
    .ranking-casino-list{
        margin-top:30px;
    }
    .ranking-year-top10{
        padding: 35px 0 0;
    }
    .ranking-year-category-box > .custom_container{
        padding: 0 70px;
    }
    .ranking-year-category-box .swiper-button-prev{
        left: 15px;
    }
    .ranking-year-category-box .swiper-button-next {
        right: 15px;
    }
    .ranking-casino-item{
        width: calc(50% - 40px);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;
    }
    .ranking-casino-item-content{
        width: 100%;
        flex: unset;
    }
    .ranking-casino-item-image{
        border-right: unset;
        width: 200px;
        height: 200px;
        padding: unset;
        margin-bottom: 20px;
    }
    .ranking-casino-item-content-inner{
        justify-content: center;
        flex-direction: column;
        padding: unset
    }
    .ranking-casino-item-content-left{
        padding: unset;
        align-items: center;
    }
    .ranking-casino-item-content-right{
        align-items: center;
        width: 100%;
    }
    .ranking-casino-item-content-right-button{
        width: 100%;
        text-align: center;
        padding: 20px 40px;
        border-radius: 10px;
    }
    .ranking-casino-item-content-right{
        height: unset;
        margin-top: 10px;
    }
    .ranking-casino-item-content-vote-star{
        width: 40px;
        height: 40px;
    }
    .ranking-casino-item-content-vote-box{
        margin: 10px 0;
    }
    .ranking-casino-list{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
    .award-best-casino-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .award-best-casino-item-outer{
        width: calc(100% - 40px);
    }
    .award-best-casino{
        padding: 35px 0 0;
    }
    .award-best-casino-item-imageCasino{
        width: 210px;
        height: 210px;
    }
    .award-best-casino-swiper{
        padding: 0 7.5% 50px;
        padding-bottom: 50px;
        width: 100%;
    }
    .award-best-section.custom_container{
        max-width: unset;
        padding: unset;
    }
    .award-best-casino .custom_h2{
        padding: 0 30px;
        width: 100%;
        max-width: 990px;
        margin: 0 auto;
    }
    .award-best-casino-swiper .swiper-pagination-bullet{
        width: 15px;
        height: 15px;
        margin: 0 10px;
    }
    .award-best-casino-swiper .swiper-pagination-bullet-active{
        background-color: #b99b60;
    }
    .ranking-casino-item-content-left-amount{
        align-items: center;
    }

    /* compaint - list */
    .complaintList-page-complaint-button-row{
        justify-content: center;
        margin-bottom: 20px;
    }
    .complaintList-page-complaint-search-row{
        justify-content: center;
    }
    .complaintList-page-complaint-button{
        width: 100%;
        max-width: 768px;
        border-radius: 10px;
    }
    .complaintList-page-complaint-search-input{
        border-radius: 10px !important;
    }
    .complaintList-page-complaint-search {
        width: 100%;
        max-width: 768px;
    }
    .complaintList-page-list{
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;
    }
    .complaintList-page-item{
        display: flex;
        grid-template-columns: none;
        flex-direction: column;
        width: calc(50% - 40px);
        padding: 30px;
        margin: 20px;
        border-radius: 20px;
    }
    .complaintList-page-item-image{
        margin-right: unset;
        padding: unset
    }
    .complaintList-page-item-content{
        flex: unset;
        width: 100%;
    }
    .complaintList-page-item-topContent{
        flex-direction: column;
        align-items: center;
        flex: unset;
        padding: unset;
    }
    .complaintList-page-item-center{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }
    .complaintList-page-item-right {
        width: 100%;
        margin: 10px;
    }
    .complaintList-page-item-right-button{
        text-align: center; 
        width: 100%;
        border-radius: 10px;
    }
    .complaintList-page-item-bottomContent{
        border-top: unset;
        text-align: center;
        justify-content: center;
    }
    .complaintSingle-content-item-right-title-row > .custom_p2{
        width: auto; 
        padding-left: unset;
        text-align: center;
    }
    .complaintSingle-content-item-right-title-row > .custom_h4{
        text-align: center;
    }

    /* complaint - submit */
    .complaint-submit {
        width: 100%;
    }
    .complaint-submit-list{
        display: flex;
        grid-gap: unset;
        grid-template-columns: unset;
        flex-direction: column;
        justify-items: unset;
        align-items: stretch;
        margin-top: 10px;
    }
    .complaint-submit-list > .custom_p {
        margin-top: 30px;
    }
    .complaint-submit-button{
        width: 100%;
        border-radius: 5px;
    }
    /****** SINGLE REVIEW *******/
    .single-pros-inner {
        padding-bottom: 30px;
    }
    .single-review-box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .single-review-box .text-box{
        padding-left: unset;
        width: 100%;
        max-width: 520px;
    }
    .single-review-box .text-box .media{
        align-items: center;
        flex-direction: column;
    }
    .single-review-box .text-box h6{
        justify-content: center;
        display: none;
    }
    .single-review-box .review-progress-field{
        margin-top: 70px;
        position: relative;
    }
    .single-review-box .review-progress-sec{
        margin-right: unset;
    }
    .single-review-box .review-progress-bar {

    }
    .single-review-box .rank-diamond {
        position: absolute;
        flex-direction: column-reverse;
        top: -40px;
        transform: translateX(-15px);
    }
    .single-review-box .rank-diamond > img {
        transform: rotate(270deg);
        width: 10px;
        height: 10px;
    }
    .single-review-box .rank-diamond > span {
        margin-left: unset;
        width: 30px;
        height: 30px;
    }
    .single-review-box .rank-diamond > span > img {
        height: unset;
        width: 100%;
    }
    .single-review-box-button-list{
        flex-direction: column;
    }
    .single-review-box-button-list .ratting, .single-review-box-button-list .visit-btn{
        margin: 15px auto 0;
    }
    .single-review-box .text-box .media h1{
        display: none;
    }
    .single-review-box .text-box .media span{
        display: none;
    }
    .single-review-box .image-holder{
        margin: 10px;
        /*
        padding: 30px;
        background-image: linear-gradient(180deg, #ffffff ,#f1f1f1);
        */
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .mobile-review-box-h4{
        color: #0e1f33;
        font-size: 30px;
        font-weight: 700;
        display: block;
    }
    .mobile-single-review-diamond {
        width: 30px;
    }
    .mobile-single-review-box-date{
        color: #0e1f33;
        font-size: 18px;
        font-weight: 400;
        line-height: 1;
        display: block;
    }
    .mobile-single-review-box-h6{
        margin: 20px 0 30px;
        color: #0e1f33;
        font-size: 29px;
        font-weight: 700;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .mobile-single-review-box-h6 span {
        color: #be956b;
    }

    /***** PAYMENT REVIEW BOX *******/
    .payment-review-box {
        padding-top: 30px;
    }
    .loading-speed .text-inner h4 {
        font-size: 30px;
    }
    .loading-speed .text-inner p {
        font-size: 21px;
    }
    .loading-speed .swiper-counter {
        font-size: 39px;
        top: 20px;
    }
    .loading-speed .text-inner a {
        font-size: 30px;
        margin: 40px 0px 10px;
    }
    .loading-speed .text-inner a img{
        width: 75px;
    }
    .loading-speed ul li {
        font-size: 21px;
    }

    /* complaint - single */
    .complaintSingle-header-box{
        display: flex;
        grid-template-columns: unset;
        flex-direction: column;
        justify-content: center;
        grid-gap: unset;
        /* max-width: 590px; */
        margin: 40px auto 0;
        border-radius: 10px;
    }
    .complaintSingle-header-content{
        flex-direction: column;
    }
    .complaintSingle-header-content-left{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 520px;
    }
    .complaintSingle-header-content-left > .custom_h2{
        font-size: 18px;
    }
    .complaintSingle-header-content-left > .custom_p {
        /* font-size: 18px; */
        text-align: left;
        width: 100%;
    }
    .complaintSingle-header-left-image {
        width: 300px;
        height: 300px;
    }
    .complaintSingle-header-content-right{
        width: 100%;
        max-width: 560px;
    }
    .complaintSingle-header-content-right-button{
        margin: 20px 0;
        width: 100%;
        text-align: center;
        padding: 10px 0;
        border-radius: 10px;
    }
    .mobile-complaintSingle-content-status-category-position{
        display: grid;
        grid-template-columns: 200px 1fr;
        justify-content: flex-start;
        align-items: start;
        padding-right: 30px;
        margin-top:10px;
    }
    .complaintSingle-content-status-category-list{
        /* display: none; */
        flex-direction: column;
        padding: 0 30px 0 0;
        /* margin-top: 50px; */
        margin-top: 20px;
    }
    .complaintSingle-content-status-category-item {
        width: 100%;
        margin: 10px 0;
        padding: 10px;
        border-radius: 0 10px 10px 0;
    }
    .complaintSingle-content-status-category-item > .custom_h4 {
        font-size: 20px;
    }
    .complaintSingle-content-item{
        display: flex;
        grid-template-columns: unset;
        flex-direction: column;
        margin-top: 30px;
        border-radius: 10px;
    }
    .complaintSingle-content-item-left{
        padding: unset;
    }
    .complaintSingle-content-item-right-title-row{
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 30px 0;
        border-bottom: unset;
    }
    .complaintSingle-content-item-right-contentBox{
        display: flex;
        flex-direction: row-reverse;
        grid-template-columns:unset;
    }
    .complaintSingle-content-item-right-contentBox-inner{
        margin-left: unset;
        margin-right: 20px;
    }
    .complaintSingle-content-item-right-contentBox-inner-bottom{
        /* display: none; */
    }
    .complaintSingle-content-viewMore{
        display: none;
    }
    .mobile-complaintSingle-content-item-right-contentBox-inner-bottom{
        display:flex;
        align-items: center;
        margin-top: 30px;
        cursor: pointer;
        justify-content: center;
    }
    .complaintSingle-content-item-collapse-box{
        border-radius: 0 0 10px 10px;
    }
    .complaintSingle-content-item-collapse-box-content {
        display: flex;
        grid-template-columns: unset;
        grid-gap: 20px;
        align-items: flex-start;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 15px;
    }
    .complaintSingle-content-item-collapse-box-content > .custom_p{
        flex: 1;
    }
    .complaintSingle-content-item-collapse-box-content-image{
        width: 150px;
        max-height: 200px;
    }

    /* checkout payment */
    .checkout-payment{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 30px;
    }
    .checkout-payment-purchaseSummary{
        max-width: 600px;
        margin-top:30px;
    }
    .checkout-payment-card-button{
        width: 100%;
        max-width: 320px;
        border-radius: 10px;
        align-self: center;
    }
    .checkout-payment-card-box-1-inner{
        padding: 20px;
    }
    .checkout-payment-card {
        width: 100%;
        max-width: 600px;
    }

    /* you may like this */
    .you-may-like-this-item{
        border-radius: 10px;
    }

    /* unsubscribe */
    .unsubscribe-inner{
        align-items: center;
        max-width: 600px;
    }
    .unsubscribe-image{
        width: 140px;
    }
    .unsubscribe-title{
        text-align: center;
    }
    .unsubscribe-content > .custom_p{
        text-align: center;
    }

    /* why ugrado */
    .why-ugrado-row{
        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto auto;
        border-radius: 10px;
        overflow: unset;
    }
    .why-ugrado-item-large{
        grid-row-start: unset;
        grid-row-end: unset;
        grid-column-start: 1;
        grid-column-end: 3;
        border-radius: 10px 10px 0 0;
    }
    .why-ugrado-item-large-right{
        grid-row-start: 1;
        grid-row-end: 2;
        border-radius: 10px 10px 0 0;
    }

    /* why trust us */
    .why-trust-us-swiper-item{
        border-radius: 10px;
    }
    /** Added By CM Start **/
    .cert-casino-detail-logo {
        /* width: 25%;
        height: 25%; */
        width: 100%;
    }

    .cert-casino-detail-mobile {
        font-size: 33px;
    }

    .cert-contract-mobile {
        line-height: 40px;
        font-size: 26px;
    }

    .cert-content-mobile-fontsize {
        font-size: 30px;
    }
    
    .cert-casino-license-mobile {
        font-size: 26px;
        text-align: center;
    }

    .cert-casino-status-mobile {
        height: 100%;
        padding-top: 10px;
        font-size: 32px;
    }

    .cert-casino-status-title-mobile {
        padding-top: 15px;
    }

    .cert-casino-disclaimer-mobile {
        padding-top: 18px;
        font-size: 20px;
        line-height: 24px;
        padding-left: 9%;
        padding-right: 9%;
    }
    .certificate-action-button-mobile-sec {
        width: 100%;
        justify-content: center;
        padding: 20px 200px;
        flex-direction: column;
    }
    .certificate-button-mobile {
        background-color: #bb9b6a;
        height: 70px;
        margin-bottom: 30px;
        border: 0px;
        border-radius: 15px;
        font-size: 20px;
        text-transform: uppercase;
        color: #fff;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .certificate-button-mobile:hover {
        background-color: #102945;
        color: #fff;
    }

    /* BONUS */
    .bonus-filter {
        flex-direction: column;
    }
    .bonus-filter-inner {
        padding: 10px 30px;
    }
    .bonus-filter-type {
        flex: 1;
        padding-right: 0;
    }
    .bonus-filter-company {
        flex: 1;
        padding-right: 0;
    }
    .bonus-filter-best {
        flex: 1;
    }
    .bonus-filter-box-height-mobile {
        height: 100px;
    }
    .bonus-inner-extra-content-left {
        padding: 20px;
    }
    .bonus-inner-extra-content-max-bonus {
        display: flex;
        flex-direction: column;
    }
    /** Added By CM End **/


}

@media (max-width:991px) {
    /****** HEADER *****/
    header {
        padding: 20px 0;
    }
    .navbar-collapse {
        top: 54px;
    }

    /****** GENERAL *****/
    .close-btn img {
        height: 15px;
    }
    .btn-default {
        height: 60px;
        max-width: 170px;
        /* font-size: 24px; */
        border-radius: 15px;
    }

    /****** BREADCRUMB *****/
    .breadcrumb .breadcrumb-item, .breadcrumb .breadcrumb-item a {
        font-size: 26px;
    }
    
    /****** HOMEPAGE *****/
    .homepage-img-trust {
        width: 60%;
    }
    .homepage-content-top-title {
        font-size: 30px;
    }
    .homepage-content-top {
        font-size: 20px;
    }
    .homepage-content-middle-title {
        font-size: 28px;
    }
    .homepage-content-middle-subtitle {
        font-size: 25px;
    }
    .homepage-content-middle-text {
        font-size: 15px;
    }
    .homepage-check {
        font-size: 15px;
        line-height: 1.6;
    }
    .homepage-content-middle-box-left {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
    }
    .homepage-content-feature-order-0 {
        border-top-left-radius: 0px !important;
    }
    .homepage-content-feature-order-1 {
        border-top-right-radius: 0px !important;
    }
    .homepage-content-feature-order-2 {
        border-bottom-left-radius: 0px !important;
    }
    .homepage-content-feature-order-3 {
        border-bottom-right-radius: 0px !important;
    }
    .homepage-content-middle-box-right {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }
    .homepage-faq {
        font-size: 20px;
    }
    .homepage-faq-ans {
        font-size: 17px;
    }
    .homepage_title{
        font-size: var(--fontSize1);
    }
    .homepage-rank-box-diamond{
        width: 100px;
    }
    .homepage-rank-box-diamond-img{
        max-width: 35px;
    }
    .homepage-rank-box-rank-company{
        font-size: 20px;
    }
    .homepage-rank-box-diamond h4{
        font-size: 20px;
    }
    .homepage-bonus-swiper-mobile .swiper {
        max-height: 190px;
    }
    .homepage-bonus-latest-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #ffffff;
        width: 100%;
        border: 2px solid #ececec;
        border-radius: 10px;
        padding: 20px 30px;
    }
    .homepage-bonus-latest-mobile-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .homepage-bonus-latest-mobile-img {
        width: 70px;
    }
    .homepage-bonus-latest-mobile-header {
        padding-right: 20px;
        font-size: 20px;
        font-weight: bold;
    }
    .homepage-bonus-latest-mobile-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .homepage-bonus-latest-mobile-left {
        display: flex;
        flex-direction: column;
    }
    .homepage-bonus-latest-mobile-part {
        display: flex;
        width: 100%;
    }
    .homepage-bonus-latest-mobile-text-left {
        text-align: left;
        width: 150px;
    }
    .homepage-bonus-mobile-left-align {
        text-align: left;
    }
    .homepage-bonus-latest-mobile-right {
        display: flex;
    }
    .homepage-bonus-latest-mobile {
        border: none;
        border-radius: 5px;
        padding: 5px 10px;
        background-color: var(--colorGreen);
        font-size: 18px;
        color: var(--colorWhite);
    }
    .homepage-bonus-latest-mobile:hover {
        background-color: var(--colorDarkGreen);
    }

    /****** ABOUT US / CONTACT US / TERMS & CONDITIONS *****/
    .about-us-inner,
    .contact-us-inner,
    .tnc-inner {
        margin-top: 30px;
        margin-bottom: 215px;
    }
    .about-us-inner h1,
    .contact-us-inner h1,
    .tnc-inner h1 {
        font-size: 36px;
        margin: 0px 0 20px 0;
    }
    .about-us-inner p,
    .contact-us-inner p,
    .tnc-inner p {
        font-size: 22px;
    }

    /***** NEWSLETTER *****/
    .call-action-mobile {
        padding-bottom: 250px;
    }
    .call-action-mobile h3 {
        font-size: 22px;
    }
    .call-action-mobile form .form-control {
        font-size: 23px;
        height: 70px;
    }
    .call-action-mobile form button {
        font-size: 23px;
    }
    .call-action-mobile p {
        font-size: 16px;
    }

    /****** MOBILE FOOTER *****/
    .mobile-footer ul li {
        width: 116px;
    }
    .mobile-footer ul li a span {
        font-size: 22px;
        margin: 10px 0 0 0;
    }
    .mobile-footer ul li a img {
        height: 38px;
    }
    .mobile-footer ul li.footer-review a img {
        height: 140px;
    }
    .mobile-footer {
        padding: 20px 20px;
    }
    .mobile-footer ul li.footer-review {
        margin-top: -77px;
        margin-right: 0;
        width: auto;
    }
    .mobile-footer ul {
        width: 100%;
        justify-content: space-between;
    }
    .mobile-footer ul li {
        margin-right: 0;
    }

    /***** SEARCH BOX *****/
    .search-dropdown {
        padding: 30px 30px;
        max-width: 100%;
        top: 75px;
    }
    .search-dropdown form .form-control {
        font-size: 22px;
        padding: 0 0 0 40px;
    }
    .search-dropdown .text-box h4 {
        font-size: 22px;
    }
    .search-dropdown .text-box {
        margin: 0 0 20px 0;
    }
    .search-dropdown .text-box ul li a {
        height: 30px;
        border-radius: 5px;
        font-size: 14px;
        padding: 0px 15px;
    }
    .search-dropdown .text-box ul li a img {
        margin-right: 15px;
        height: 14px;
    }
    .search-dropdown form span img {
        height: 22px;
    }

    /***** GUIDE LISTING PAGE *******/
    .guide-inner {
        padding-bottom: 200px;
    }
    .monthly-sponsored-box p,
    .guide-sport-text p {
        font-size: 22px;
        line-height: 1.5;
    }
    .guide-sport {
        padding: 10px 0px;
    }
    .guide-sport-text .media h2 {
        font-size: 38px;
    }
    .guide-sport-text .media a {
        font-size: 22px;
    }
    .guide-sport-inner {
        margin: 0 0 0 -5px;
    }
    .guide-swiper-btn .swiper-button-next, 
    .guide-swiper-btn .swiper-button-prev {
        width: 60px;
        height: 60px;
        border-radius: 10px;
        margin-left: 15px;
    }
    .guide-swiper-btn {
        padding: 0;
        margin: 0px 0 20px;
    }
    .guide-sport-box .text-box .text-box-inner {
        padding: 10px 15px;
    }
    .guide-sport-box .text-box .text-box-inner h3 {
        font-size: 22px;
        margin: 10px 0;
    }
    .guide-sport-box .text-box .text-box-inner p {
        font-size: 16px;
        line-height: 1.5;
    }
    .guide-sport-text .media h2 {
        font-size: 22px;
    }
    .guide-sport-list ul {
        max-height: 66px;
    }
    .all-article-sec .guide-sport-text {
        padding: 0;
    }
    .all-article-inner .guide-sport-box .text-box .text-box-inner ul li {
        font-size: 14px;
    }
    .all-article-inner .guide-sport-box .text-box .text-box-inner h3 {
        font-size: 22px;
    }
    .all-article-inner .guide-sport-box .text-box .text-box-inner p {
        font-size: 16px;
        -webkit-line-clamp: 2;
    }
    .all-article-inner .guide-sport-list ul li a {
        font-size: 18px;
        height: 35px;
        border-radius: 4px;
        color: #7f7f7f;
        padding: 0 5px;
    }
    .all-article-inner .guide-sport-box .image-holder {
        max-width: 290px;
        width: 100%;
    }
    .more-history a img {
        height: 45px;
    }
    .all-article-sec .more-history a {
        font-size: 18px;
    }
    .more-history {
        margin: 20px 0 0 0;
    }

    /***** MONTHLY SPONSORED *******/
    .monthly-sponsored-sec {
        padding-bottom: 200px;
    }
    .monthly-sponsored-img {
        max-width: 80px;
    }
    .monthly-sponsored-title h2 {
        font-size: 27px;
    }
    .monthly-sponsored-content {
        padding: 40px 50px;
    }
    .marketing-parnership-section {
        padding: 0 50px;
    }
    .marketing-parnership-content h2 {
        font-size: 27px;
    }
    .marketing-parnership-content p {
        font-size: 20px;
    }

    /***** REGISTER / LOGIN *******/
    .login-form-layout-title {
        font-size: 60px;
        margin-bottom: 31px;
    }
    .login-btn-pill {
        width: 200px;
        height: 65px;
        border-radius: 42px;
        font-size: 25px;
        line-height: 36px;
        margin-bottom: 20px;
        margin-top: 20px;
    }
    .login-form-layout-message-title-otp {
        font-size: 24px;
    }

    /***** OTP FORM *******/
    .otp-form {
        margin-top: 35px;
    }
    .otp-input-fields input {
        width: 53px;
        height: 64px;
        font-size: 25px;
    }
    .otp-input-fields {
        width: fit-content;
        margin-bottom: 35px;
    }

    /***** USER PROFILE *******/
    .profile-icon-mobile {
        width: 200px;
        height: 200px;
    }
    .profile-icon-mobile img {
        height: 100px;
    }
    .profile-icon-mobile .profile-edit img {
        height: 60px !important;
    }
    .profile-status-mobile {
        padding: 25px 50px;
    }
    .verify-phone-verify {
        width: 80px;
        height: 80px;
        font-size: 50px;
    }
    .verify-phone-verified {
        font-size: 25px;
        right: 0;
    }
    .verify-phone-not-verify {
        width: 80px;
        height: 80px;
        font-size: 50px;
    }
    .verify-text {
        font-size: 22px;
    }
    .profile-name h2 {
        font-size: 25px;
    }
    .diamond-user {
        font-size: 24px;
    }
    .diamond-user .diamond-icon {
        margin-left: -28px;
    }
    .diamond-user .diamond-icon img {
        width: 55px;
    }

    /***** PROFILE TAB *******/
    .profile-mobile-tab .nav-pills .nav-item .nav-link {
        padding-left: 30px;
        padding-right: 30px;
        font-size: 18px;
    }
    .profile-mobile-content h2 {
        font-size: 36px;
    }

    /***** PROFILE CHANGE PASSOWRD *******/
    .change-password-form .password-form-input {
        font-size: 27px;
    }
    .change-password-form .form-control {
        font-size: 25px;
        padding-left: 26px;
    }
    .change-password-form .form-error-content {
        font-size: 23px;
    }
    .change-password-form .checkbox-not-verify {
        font-size: 28px;
        top: 17px;
    }
    .change-password-form .checkbox-verify {
        font-size: 28px;
        top: 17px;
    }
    .profile-button-pill {
        width: 290px;
        height: 85px;
        font-size: 30px;
    }

    /***** PROFILE BUY DIAMOND *******/
    .diamond-package-text-box h3 {
        font-size: 25px;
    }
    .diamond-package-timer {
        top: 45px;
        width: 160px;
        font-size: 23px;
    }
    .diamond-package-flash-sales {
        font-size: 22px;
    }
    .diamond-package-price {
        font-size: 22px;
    }
    .diamond-package-price .strikethrough {
        font-size: 17px;
    }

    /***** PROFILE HISTORY *******/
    .history-diamond-inner table thead tr th {
        font-size: 25px;
    }
    .history-diamond-inner table tbody tr td {
        font-size: 22px;
    }
    .more-history a {
        font-size: 23px;
    }

    /***** NAVIGATION TAB *******/
    .profile-bottom-section-mobile h3 {
        font-size: 36px;
    }
    .navigation-box {
        padding: 35px 25px;
        margin-bottom: 20px;
    }
    .navigation-box span {
        font-size: 21.5px;
        margin-left: 15px;
    }

    /****** SINGLE ARTICLE LEFT *******/
    .article-text h1 {
        font-size: 27px;
    }
    .article-text ul li {
        font-size: 18px;
    }
    .article-content p {
        font-size: 20px;
        line-height: 1.3;
        margin: 0 0 20px 0;
    }
    .article-content table, .article-content tr, .article-content td{
        font-size: 20px;
    }
    .article-content h2 {
        font-size: 22px;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }
    .sponsord-tag li a {
        font-size: 19px;
        height: 45px;
        padding: 0 15px;
    }
    .accordion-button {
        font-size: 20px;
    }
    .accordion-body {
        font-size: 18px;
    }
    .sponsord-add .media h4 {
        font-size: 22px;
    }
    .sponsord-survey-form h2 {
        font-size: 21px;
    }
    .survey-form-question-btm {
        font-size: 20px;
    }

    /****** SINGLE ARTICLE POLL LEFT *******/
    .survey-form-float {
        display: none;
        width: 420px;
    }
    .survey-form-float-title {
        font-size: 24px;
    }
    .survey-form-float-question {
        font-size: 22px;
    }
    .survey-form-float-options {
        font-size: 20px;
    }
    .survey-form-float-triangle-click img, .survey-form-float-triangle-click-close img {
        height: 60px;
    }
    .survey-form-float-triangle-click, .survey-form-float-triangle-click-close {
        right: -35px;
    }
    .survey-form-float-mobile-gesture {
        width: 45px;
    }
    .survey-form-float-mobile-gesture-close {
        right: -90px;
        top: 45%;
        width: 45px;
    }

    /****** SINGLE ARTICLE RIGHT *******/
    .latest-news-box .text-box h4 {
        font-size: 24px;
    }

    /***** Vote Ranking *****/
    .vote-ranking-diamondRankingBoard-mobile-modal-closeButton-absolute{
        top: -2px;
    }

    /* grade-ranking */
    .ranking-casino-item{
        width: 100%;
        max-width: 500px;
    }
    /* .ranking-casino-item-content-vote-star{
        width: 40px;
        height: 40px;
    } */
    .award-best-casino-item-imageCasino{
        width: 51%;
        height: 51%;
        padding: unset;
    }

    /* complaint - list */
    .complaintList-page-item{
        width: calc(50% - 30px);
        padding: 30px 10px;
        margin: 15px;
    }
    .complaintSingle-content-item-right-contentBox-image, .complaintSingle-content-item-collapse-box-content-image{
        max-height: 150px;
        width: 100px;
        border: 2px solid #b99b60;
    }

    /* you may like this */
    .you-may-like-this-list .swiper-slide{
        padding: 0 10px;
    }

    /* cert */
    .certificate-action-button-mobile-sec {
        padding: 20px 120px;
    }

    /* BONUS */
    .bonus-inner-box-title {
        font-size: 26px;
    }
    .bonus-inner-box-text{
        padding-left: 10px;
    }
    .bonus-inner-box-right {
        width: 180px;
    }
    .bonus-inner-extra-content-left {
        padding: 20px;
    }
    .bonus-inner-extra-content-box1, .bonus-inner-extra-content-box2, .bonus-inner-extra-content-box3, .bonus-inner-extra-content-box4 {
        padding: 0 10px 5px;
        width: 220px;
    }
    .bonus-inner-extra-content-text {
        display: flex;
        flex-direction: column;
    }
    .bonus-inner-extra-content-right {
        width: 230px;
    }
    .bonus-subscribe-box {
        left: 25%;
        right: 25%;
    }
}

@media (max-width:819px) {
    /** Added By CM Start **/
    .cert-casino-disclaimer-mobile {
        font-size: 23px;
        line-height: 20px;
    }
    .bonus-inner-box-left {
        padding: 20px 0;
    }
    .bonus-inner-tag {
        padding: 2px 5px;
    }
    /** Added By CM End **/
}

@media (max-width:767px) {
    :root{
        --fontSize1: 26px;
        --fontSize2: 22px;
        --fontSize3: 20px;
        --fontSize4: 16px;
        --fontSize5: 14px;
        --fontSize6: 12px;
    }
    /***** Custom Use *****/
    .custom_h2{
        font-size: 36px;
    }
    .custom_h3{
        font-size: 28px;
    }
    .custom_border_gold_withWidth {
        width: 100px;
    }
    
    /****** GENERAL *****/
    .general-box {
        padding: 45px 40px 30px;
    }
    .general-box h1 {
        font-size: 36px;
    }
    .general-box p {
        font-size: 22px;
    }

    /****** HOMEPAGE *****/
    .homepage-sec {
        /* width: 80%; */
    }
    .homepage_title{
        font-size: var(--fontSize1);
    }
    .homepage-recent-company-box-inner{
        padding: 15px 10px;
    }
    .homepage-recent-company-title{
        font-size: 20px
    }
    .homepage-recent-company-logo{
        width: 130px;
        margin-right: 10px;
    }
    .homepage-recent-company-star li{
        width: 15px;
    }
    .homepage-recent-company-diamond{
        max-width: 20px;
    }
    .homepage-recent-company-diamond-amt{
        font-size: 24px;
    }
    .homepage-recent-company-button{
        font-size: 12px;
    }
    .homepage-recent-company-tag{
        width: 85px;
        font-size: 14px;
    }
    .homepage-ranking-sec{
        flex-direction: column;
    }
    .homepage-rank-box-diamond{
        width: 130px;
    }
    .homepage-rank-box-diamond-img{
        max-width: 40px;
    }
    .homepage-rank-box-rank-company{
        font-size: 25px;
    }
    .homepage-rank-box-diamond h4{
        font-size: 25px;
    }

    /****** ABOUT US / CONTACT US / TERMS & CONDITIONS *****/
    .about-us-inner .about-us-subtitle h3,
    .contact-us-inner .contact-us-subtitle h3,
    .tnc-inner .tnc-subtitle h3 {
        margin-bottom: 20px;
    }
    .about-us-inner p,
    .contact-us-inner p,
    .tnc-inner p {
        padding-bottom: 20px;
    }

    /***** NEWSLETTER *****/
    .call-action-mobile {
        padding-bottom: 200px;
    }
    .call-action-mobile h3 {
        font-size: 17px;
        padding: 0 15px;
    }
    .call-action-mobile form .form-control {
        font-size: 21px;
        height: 60px;
    }
    .call-action-mobile form button {
        font-size: 21px;
        height: 70px;
    }
    .call-action-mobile p {
        font-size: 15px;
    }

    /****** MOBILE FOOTER *****/
    .mobile-footer {
        padding: 20px 5px;
    }
    .mobile-footer ul li a span {
        font-size: 16px;
        margin: 10px 0 0 0;
    }

    /***** SEARCH BOX *****/
    .search-dropdown {
        top: 74px;
        padding: 30px 20px;
    }
    .search-dropdown form {
        margin: 0 0 30px 0;
    }
    .search-dropdown .text-box ul li a {
        padding: 0px 10px;
    }

    /***** CONTACT FORM *****/
    .contact-form .form-group {
        margin-bottom: 30px;
    }
    .contact-form-input {
        padding-bottom: 20px;
    }
    .contact-form-input:last-child {
        padding-bottom: 0px;
    }
    .contact-form .relative {
        margin-bottom: 20px;
    }
    .contact-form .form-control {
        height: 50px;
        font-size: 18px;
    }
    .contact-form .form-error-content {
        font-size: 16px;
    }

    /****** CONTACT US *****/
    .contact-us-inner {
        margin-bottom: 265px;
    }

    /***** GUIDE LISTING PAGE *******/
    .guide-sport-text .media .guide-media-text {
        max-width: 40px;
    }
    .guide-sport-text .media h2 {
        padding-right: 5px;
    }
    .monthly-sponsored-box p, 
    .guide-sport-text p {
        font-size: 20px;
        line-height: 1.5;
    }
    .guide-swiper-btn .swiper-button-next,
    .guide-swiper-btn .swiper-button-prev {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        margin-left: 15px;
    }
    .guide-swiper-btn .swiper-button-next img,
    .guide-swiper-btn .swiper-button-prev img {
        height: 18px;
    }
    .all-article-inner {
        margin: 0;
    }
    .all-article-inner .guide-sport-box {
        margin: 15px 0;
    }
    .all-article-inner .guide-sport-box {
        flex-direction: column;
    }
    .all-article-inner .guide-sport-box .image-holder {
        max-width: 100%;
        width: 100%;
    }
    .all-article-inner .guide-sport-box .image-holder img {
        width: 100%;
        border-radius: 0;
    }
    .all-article-inner .guide-sport-box .text-box {
        padding: 25px 25px 30px;
    }
    
    /***** MONTHLY SPONSORED *******/
    .monthly-sponsored-sec {
        padding-bottom: 180px;
    }
    .marketing-parnership-content h2 {
        font-size: 25px;
    }
    .marketing-parnership-content p {
        font-size: 20px;
    }

    /***** REGISTER / LOGIN *******/
    .login-form-layout-title {
        font-size: 47px;
        margin-bottom: 21px;
    }
    .login-form-layout-message {
        font-size: 27px;
    }
    .login-btn-pill-bg {
        border-radius: 42px;
        width: 263px;
        height: 70px;
        font-size: 29px;
        line-height: 30px;
        margin-top: 30px;
        margin-bottom: 14px;
    }
    .login-inputs .form-check-label {
        font-size: 16px;
    }
    .login-inputs .form-check-label a {
        font-size: 16px;
    }
    .login-inputs .signup-checkbox-info {
        width: 330px;
    }
    .verification-content {
        font-size: 22px;
        line-height: 24px;
    }
    .login-form-layout-message-title-otp {
        font-size: 21px;
    }

    /***** USER PROFILE *******/
    .profile-tab-content {
        padding-top: 120px;
    }
    .profile-icon-mobile {
        width: 180px;
        height: 180px;
    }
    .profile-icon-mobile img {
        height: 90px;
    }
    .profile-icon-mobile .profile-edit img {
        height: 50px !important;
    }
    .verify-phone-verify {
        width: 70px;
        height: 70px;
        font-size: 45px;
    }
    .verify-phone-verified {
        right: -5px;
    }
    .verify-phone-not-verify {
        width: 70px;
        height: 70px;
        font-size: 45px;
    }
    .verify-text {
        font-size: 18px;
    }
    .profile-name {
        padding-top: 20px;
    }
    .profile-name h2 {
        font-size: 22px;
    }
    .diamond-user {
        font-size: 22px;
    }
    .diamond-user .diamond-icon img {
        width: 50px;
    }
    .diamond-user .diamond-icon {
        margin-left: -26px;
    }

    /***** PROFILE TAB *******/
    .profile-mobile-tab .nav-pills .nav-item .nav-link {
        padding-left: 25px;
        padding-right: 25px;
        font-size: 18px;
    }
    .profile-mobile-content h2 {
        font-size: 32px;
    }
    
    /***** PROFILE CHANGE PASSOWRD *******/
    .change-password-form .password-form-input {
        font-size: 23px;
    }
    .change-password-form .form-control {
        font-size: 22px;
    }
    .change-password-form .form-error-content {
        font-size: 20px;
    }
    .profile-button-pill {
        width: 240px;
        height: 75px;
        font-size: 25px;
    }

    /***** PROFILE MANAGE PAYMENT *******/
    .profile-card-list-title {
        font-size: 20px;
    }
    .profile-card-list-content {
        font-size: 18px;
    }
    .profile-card-default {
        font-size: 13px;
    }
    .add-payment-box-title {
        font-size: 20px;
    }

    /***** PROFILE MANAGE SUBSCRIPTION *******/
    .profile-subscription-detail {
        font-size: 13px;
    }
    .profile-subscription-detail-subtitle {
        font-size: 12px;
    }
    .profile-subscription-cancel-tag {
        font-size: 13px;
    }

    /***** PROFILE BUY DIAMOND *******/
    .diamond-package-box {
        margin-left: 30px;
        margin-right: 30px;
    }
    .diamond-package-text-box h3 {
        font-size: 36px;
    }
    .diamond-package-timer {
        top: 70px;
        width: 230px;
        font-size: 36px;
    }
    .diamond-package-flash-sales {
        font-size: 35px;
    }
    .diamond-package-price {
        font-size: 35px;
    }
    .diamond-package-price .strikethrough {
        font-size: 25px;
    }

    /***** PROFILE HISTORY *******/
    .history-diamond-inner table thead tr th {
        font-size: 18px;
    }
    .history-diamond-inner table tbody tr td {
        font-size: 15px;
    }
    .more-history a {
        font-size: 18px;
    }

    /***** NAVIGATION TAB *******/
    .profile-bottom-section-mobile h3 {
        font-size: 32px;
    }
    .navigation-box {
        padding: 30px 22px;
    }
    .navigation-box span {
        font-size: 19px;
    }

    /****** SINGLE ARTICLE LEFT *******/
    .sponsord-tag li {
        padding: 0 5px;
    }
    .sponsord-tag li a {
        font-size: 18px;
        height: 40px;
    }
    .survey-form-options-btm {
        width: 100%;
    }
    .article-latest-bonus-mobile-inner {
        max-width: 380px;
    }
    .article-latest-bonus-mobile-logo {
        width: 70px;
    }
    .article-latest-bonus-mobile-title {
        font-size: 23px;
    }
    .article-latest-bonus-mobile-bottom {
        font-size: 18px;
    }
    .article-latest-bonus-mobile-button {
        font-size: 14px; 
    }

    /****** SINGLE ARTICLE POLL LEFT *******/
    .survey-form-float {
        display: none;
        width: 380px;
    }
    .survey-form-float-title {
        font-size: 21px;
    }
    .survey-form-float-question {
        font-size: 20px;
    }
    .survey-form-float-options {
        font-size: 18px;
    }

    /***** Vote Ranking *****/
    .vote-ranking-diamondRankingBoard-board-mobileItem{
        width: 100%;
        padding: 20px 0;
    }

    /* grade-ranking */
    .ranking-casino-item{
        margin: 20px 0;
    }
    .award-best-casino-item{
        padding: 5px;
        border-radius: 20px;
    }
    .award-best-casino-swiper{
        padding: 0 25px 50px;
    }
    .award-best-casino-item-outer{
        width: calc(100% - 20px);
        margin: 10px;
    }

    /* complaint - list */
    .complaintList-page-item{
        width: 100%;
        margin: 20px 0;
        max-width: 425px;
        padding: 30px;
        border-radius: 10px;
    }
    .complaintList-page-complaint-button{
        padding: 15px 20px;
        border-radius: 5px;
    }
    .complaintList-page-complaint-search-input-icon {
        font-size: 25px;
        margin-right: 0px;
    }
    .complaintList-page-complaint-search-input{
        font-size: 20px !important;
        padding: 10px 45px 10px 15px !important;
        border-radius: 5px !important;
    }
    .complaintList-page-item-right-button{
        padding: 10px 0;
        border-radius: 5px;
    }
    /***** PAYMENT REVIEW BOX *******/
    .loading-speed .text-inner h4 {
        font-size: 28px;
    }
    .loading-speed .text-inner p {
        font-size: 20px;
    }
    .loading-speed .swiper-counter {
        font-size: 28px;
        top: 20px;
    }
    .loading-speed .text-inner a {
        font-size: 28px;
        margin: 30px 0px 10px;
    }
    .loading-speed .text-inner a img{
        width: 65px;
    }
    .loading-speed ul li {
        font-size: 19px;
    }

    /* complaint - single */
    .mobile-complaintSingle-content-status-category-position{
        grid-template-columns: 150px 1fr;
        padding-right: 30px;
    }
    .complaintSingle-content-status-category-list{
        padding: 0 20px 0 0;
    }
    .complaintSingle-content-status-category-item-image {
        width: 45px;
        height: 45px;
    }
    .complaintSingle-content-status-category-item > .custom_h4 {
        font-size: 16px;
    }
    .complaintSingle-content-item-right-contentBox-image, .complaintSingle-content-item-collapse-box-content-image{
        width: 80px;
        /* border: 3px solid #b99b60; */
        max-height: 120px;
    }
    .complaintSingle-content-item-right-contentBox-inner{
        margin-right: 5px;
    }
    .complaintSingle-content-item-collapse-box-content{
        grid-gap: 5px;
    }
    .complaintSingle-content-item-right-contentBox{
        min-height: 100px;
    }
    .complaintSingle-content-item-left-image{
        width: 160px;
        height: 160px;
    }
    .complaintSingle-content-item-left-text{
        font-size: 110px;
    }
    .complaintSingle-content-item{
        border-radius: 10px;
    }
    .complaintSingle-header-left-image {
        width: 250px;
        height: 250px;
    }

    /* checkout payment */
    .checkout-payment-img{
        width: 300px;
    }
    .checkout-payment-purchaseSummary-body-img2-text{
        top: -8px;
    }

    /* vote-star */
    .ranking-casino-item-content-vote-star {
        width: 30px;
        height: 30px;
    }

    /* you may like this */
    .you-may-like-this-item-image{
        width: 170px;
        height: 170px;
    }
    .you-may-like-this-item{
        padding: 20px;
    }
    .you-may-like-this-item .custom_h3, .you-may-like-this-item .custom_h4 {
        font-size: 22px;
    }
    .you-may-like-this-item .custom_icon_size_small {
        width: 30px;
    }

    /* unsubscribe */
    .unsubscribe-image {
        width: 110px;
    }

    /* why ugrado */
    .why-ugrado-item-content > .custom_h4 {
        font-size: 16px;
    }
    .why-ugrado-item-image {
        width: 70px;
    }
    .why-ugrado-item-large .why-ugrado-item-image{
        width: 80px;
    }
    .why-ugrado-item-seeMore-icon {
        font-size: 25px;
    }

    .homepage-content-middle-seeMore-icon{
        font-size: 25px;
    }

    /* why trust us */
    .why-trust-us-swiper-item {
        max-width: 220px;
        padding: 20px 10px;
    }

    /** Added By CM Start **/
    .cert-casino-detail-logo {
        /* width: 23%;
        height: 23%; */
    }

    .cert-casino-detail-mobile {
        font-size: 19px;
    }

    .cert-shield-icon .cert-shield-image {
        width: 65%;
        /* padding-top: 8%; */
    }

    .cert-content-mobile-fontsize {
        font-size: 25px;
    }

    .cert-contract-mobile {
        top: 62%;
        line-height: 1.3;
        font-size: 18px;
    }

    .cert-casino-license-mobile {
        font-size: 25px;
    }

    .cert-casino-status-mobile {
        font-size: 25px;
        padding-top: 10px;
    }

    .cert-casino-status-title-mobile {
        padding-top: 4px;
    }

    .cert-casino-disclaimer-mobile {
        font-size: 16px;
        line-height: 1.3;
        padding-top: 5px;
    }

    /* BONUS */
    .bonus-inner-box-content {
        flex-direction: column;
    }
    .bonus-inner-box-left {
        align-items: center;
        flex-direction: column;
    }
    .bonus-inner-box-logo {
        width: 150px;
    }
    .bonus-inner-box-title {
        text-align: center;
    }
    .bonus-inner-tag {
        font-size: 20px;
        font-weight: 700;
    }
    .bonus-inner-game-type-image-field {
        width: 40px;
    }
    .bonus-inner-text-field {
        font-size: 20px;
    }
    .bonus-inner-box-text {
        padding-left: 0;
        width: 100%;
    }
    .bonus-inner-box-detail-mobile {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
    }
    .bonus-inner-box-detail-mobile-row1 {
        display: flex;
        padding-top: 10px;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .bonus-inner-box-detail-mobile-row2 {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .bonus-inner-box-detail-mobile-box1 {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100px;
        border-right: 1px solid #d1d1d1;
        border-bottom: 1px solid #d1d1d1;
    }
    .bonus-inner-box-detail-mobile-box2 {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100px; 
        border-bottom: 1px solid #d1d1d1;
    }
    .bonus-inner-box-detail-mobile-box3 {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100px;
        border-right: 1px solid #d1d1d1;
    }
    .bonus-inner-box-detail-mobile-box4 {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100px;
    }
    .bonus-inner-box-expand-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 10px;
        width: 100%;
    }
    .bonus-inner-extra-content-mobile-expand {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px 60px;
        background-color: #d1d1d1;
        font-size: 20px;
    }
    .bonus-inner-extra-content-mobile-row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 5px;
    }
    .bonus-inner-extra-content-mobile-button-sec {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .bonus-inner-box-button-mobile {
        border: none;
        color: white;
        background-color: green;
        padding: 15px 25px;
        border-radius: 10px;
        width: 200px;
        font-size: 25px;
        font-weight: 700;
    }
    .bonus-subscribe-box {
        left: 5%;
        right: 5%;
    }
    .bonus-subscribe-box-inner {
        padding: 70px 30px;
    }
    .bonus-subscribe-title {
        font-size: 20px;
    }

    /** Added By CM End **/
}

@media (max-width:575px) {
    /***** Custom Use *****/
    .custom_title_icon_size {
        width: 40px;
    }
    .custom_h2{
        font-size: 26px;
    }
    .custom_h3{
        font-size: 24px;
    }
    .custom_h4{
        font-size: 22px;
    }
    .custom_p{
        font-size: 16px;
    }
    .custom_p2{
        font-size: 14px;
    }
    .custom_p3{
        font-size: 12px;
    }
    .custom_border_gold_withWidth {
        width: 80px;
    }

    /****** HEADER *****/
    header {
        padding: 15px 0;
    }
    .navbar-expand-xl .navbar-collapse {
        flex: unset;
    }
    .navbar-collapse {
        top: 47px;
    }
    .navbar-nav {
        flex-direction: column;
    }
    .navbar-toggler {
        width: 50px;
    }
    .navbar-brand img {
        width: 125px;
    }
    .navbar-right-menu {
        min-width: 50px;
        padding-right: 10px;
    }
    .navbar-right-menu-icon {
        width: 25px;
        margin-left: 25px;
    }
    .navbar-nav .nav-item .nav-link {
        font-size: 16px;
    }
    .submenu ul li a {
        font-size: 16px;
    }

    /****** GENERAL *****/
    .page-content {
        /* padding-top: 90px; */
    }
    .close-btn img {
        height: 10px;
    }
    .btn-default {
        height: 50px;
        max-width: 150px;
        /* font-size: 20px; */
        border-radius: 10px;
    }
    .general-box {
        padding: 45px 40px 30px;
    }
    .general-box h1 {
        font-size: 26px;
    }
    .general-box p {
        font-size: 16px;
    }

    /****** BREADCRUMB *****/
    .breadcrumb .breadcrumb-item, .breadcrumb .breadcrumb-item a {
        font-size: 16px;
    }
    
    /****** HOMEPAGE *****/
    .homepage-img-banner {
        width: 80%;
    }
    .homepage-img-trust {
        width: 60%;
    }
    .homepage-content-top-title {
        font-size: 23px;
    }
    .homepage-content-top {
        font-size: 16px;
    }
    .homepage-content-middle-title {
        font-size: 23px;
    }
    .homepage-content-middle-subtitle {
        font-size: 22px;
    }
    .homepage-content-middle-text {
        font-size: 13px;
    }
    .homepage-check {
        font-size: 15px;
        line-height: 1.2;
    }
    .homepage-faq {
        font-size: 18px;
    }
    .homepage-faq-ans {
        font-size: 15px;
    }
    .homepage_title{
        font-size: var(--fontSize1);
    }
    .homepage-recent-company-content{
        flex-direction: column;
    }
    .homepage-article-title{
        -webkit-line-clamp: 1;
    }
    .homepage-recent-company-box-inner{
        padding: 10px 7px;
    }
    .homepage-recent-company-logo{
        width: 55px;
        margin-right: 3px;
    }
    .homepage-recent-company-detail{
        padding-left: 0px;
    }
    .homepage-recent-company-title{
        font-size: 12px;
    }
    .homepage-recent-company-star li{
        width: 10px;
    }
    .homepage-rank-box-diamond{
        width: 110px;
    }
    .homepage-recent-company-diamond{
        max-width: 15px;
    }
    .homepage-recent-company-diamond-amt{
        padding-left: 1px;
        font-size: 15px;
    }
    .homepage-recent-company-button{
        padding: 5px;
        font-size: 8px;
    }
    .homepage-recent-company-tag{
        font-size: 8px;
    }
    .homepage-rank-box-diamond{
        width: 100px;
    }
    .homepage-rank-box-diamond-img{
        max-width: 30px;
    }
    .homepage-rank-box-rank-company{
        font-size: 20px;
    }
    .homepage-rank-box-diamond h4{
        font-size: 20px;
    }
    .homepage-bonus-latest-mobile-img {
        width: 50px;
    }
    .homepage-bonus-latest-mobile-header {
        font-size: 15px;
    }
    .homepage-bonus-latest-mobile-left {
        font-size: 12px;
    }
    .homepage-bonus-latest-mobile-text-left {
        width: 100px;
    }
    .homepage-bonus-latest-mobile {
        font-size: 12px;
        padding: 5px 8px;
    }

    /****** ABOUT US / CONTACT US / TERMS & CONDITIONS *****/
    .about-us-inner,
    .contact-us-inner,
    .tnc-inner {
        margin-bottom: 135px;
    }
    .about-us-inner .about-us-subtitle h2,
    .contact-us-inner .contact-us-subtitle h2,
    .tnc-inner .tnc-subtitle h2 {
        margin-bottom: 15px;
    }
    .about-us-inner h1,
    .contact-us-inner h1,
    .tnc-inner h1 {
        font-size: 26px;
        margin: 0px 0 15px 0;
    }
    .about-us-inner p,
    .contact-us-inner p,
    .tnc-inner p {
        font-size: 16px;
    }

    /***** NEWSLETTER *****/
    .call-action-mobile {
        padding-bottom: 160px;
    }
    .call-action-mobile h3 {
        font-size: 12px;
        padding: 0 15px;
    }
    .call-action-mobile form .form-control {
        font-size: 18px;
        height: 50px;
    }
    .call-action-mobile form button {
        margin-top: 10px;
        font-size: 18px;
        height: 60px;
    }
    .call-action-mobile p {
        font-size: 13px;
    }

    /****** MOBILE FOOTER *****/
    .mobile-footer ul li a img {
        height: 24px;
    }
    .mobile-footer ul li a span {
        font-size: 13px;
    }
    .mobile-footer ul li {
        width: 74px;
    }
    .mobile-footer ul li.footer-review {
        margin-top: -63px;
        margin-right: 0;
        width: auto;
    }
    .mobile-footer ul li.footer-review a img {
        height: 100px;
    }

    /***** SEARCH BOX *****/
    .search-dropdown {
        top: 63px;
    }
    .search-dropdown .text-box h4 {
        margin-bottom: 10px;
    }
    .search-dropdown .text-box ul {
        margin: 0 -5px;
        flex-wrap: wrap;
    }
    .search-dropdown .text-box ul li {
        padding: 5px 5px;
    }

    /***** CONTACT FORM *****/
    .contact-form .relative {
        margin-bottom: 10px;
    }
    .contact-form .form-control {
        height: 40px;
        font-size: 14px;
    }
    .contact-form .form-error-content {
        font-size: 12px;
    }

    /****** CONTACT US *****/
    .contact-us-inner {
        margin-bottom: 215px;
    }

    /***** GUIDE LISTING PAGE *******/
    .guide-inner {
        padding-bottom: 150px;
    }
    .guide-sport-text .media .guide-media-text {
        max-width: 30px;
    }
    .monthly-sponsored-box p, 
    .guide-sport-text p {
        font-size: 14px;
        line-height: 1.5;
    }
    .guide-sport-text .media a {
        font-size: 16px;
    }
    .guide-swiper-btn .swiper-button-next, .guide-swiper-btn .swiper-button-prev {
        width: 40px;
        height: 40px;
        border: 2px solid #bb9b6a;
        margin-left: 15px;
    }
    .guide-sport-list ul li a {
        font-size: 12px;
    }
    .guide-sport-list ul {
        max-height: 60px;
    }
    .all-article-inner .guide-sport-box {
        margin: 20px 0;
    }
    .guide-sport-box {
        border-radius: 21px;
    }
    .all-article-inner .guide-sport-box .text-box {
        padding: 20px 15px 20px;
    }
    .all-article-inner .guide-sport-box .text-box .text-box-inner p {
        -webkit-line-clamp: 3;
    }
    .all-article-inner .guide-sport-list ul li a {
        font-size: 15px;
        height: 30px;
        border-radius: 8px;
        color: #7f7f7f;
        padding: 0 5px;
    }

    /***** MONTHLY SPONSORED *******/
    .monthly-sponsored-sec {
        padding-bottom: 130px;
    }
    .monthly-sponsored-img {
        max-width: 70px;
    }
    .monthly-sponsored-title h2 {
        font-size: 21px;
    }
    .marketing-parnership-img {
        max-width: 60px;
    }
    .marketing-parnership-content h2 {
        font-size: 18px;
    }
    .marketing-parnership-content p {
        font-size: 14px;
    }

    /***** REGISTER / LOGIN *******/
    .register-sec {
        padding-bottom: 180px;
    }
    .login-form-layout-title {
        font-size: 43px;
        margin-bottom: 11px;
    }
    .login-form-layout-message {
        font-size: 20px;
        margin-bottom: 30px;
    }
    .login-input-outline .form-control {
        font-size: 17px;
        padding-left: 12px;
    }
    .login-inputs .form-control {
        font-size: 18px;
    }
    .login-info {
        font-size: 13px;
    }
    .login-input-error {
        font-size: 14px;
    }
    .login-btn-pill-bg {
        border-radius: 42px;
        width: 175px;
        height: 54px;
        font-size: 20px;
        line-height: 30px;
        margin-top: 20px;
        margin-bottom: 14px;
    }
    .login-inputs .form-check-label {
        font-size: 13px;
    }
    .login-inputs .signup-checkbox-info {
        width: 230px;
    }
    .login-inputs .form-check-label a {
        font-size: 13px;
    }
    .login-btn-pill {
        width: 172px;
        height: 54px;
        border-radius: 42px;
        font-size: 22px;
        line-height: 22px;
        margin-bottom: 20px;
        margin-top: 20px;
        border: 3px solid #ba9b6a;
    }
    .login-form-layout-message-otp {
        font-size: 27px;
        margin-bottom: 10px;
    }
    .login-form-layout-message-title-otp {
        font-size: 17px;
        margin-bottom: 40px;
    }
    .verification-content {
        font-size: 13px;
        line-height: 19px;
    }
    .terms-style li a {
        font-size: 14px;
    }

    /***** OTP FORM *******/
    .otp-input-fields input {
        width: 36px;
        height: 40px;
        font-size: 20px;
    }
    .title h3 {
        font-size: 20px;
        line-height: 21px;
    }
    .otp-input-fields {
        width: auto;
        gap: 10px;
        padding: 0 5px;
    }

    /***** USER PROFILE *******/
    .profile-icon-mobile {
        width: 160px;
        height: 160px;
        margin-top: -100px;
    }
    .profile-icon-mobile img {
        height: 80px;
    }
    .profile-icon-mobile .profile-edit img {
        height: 40px !important;
    }
    .verify-user {
        padding-left: 20px;
        margin: 0 auto;
    }
    .verify-phone-verify {
        width: 60px;
        height: 60px;
        font-size: 40px;
    }
    .verify-phone-verified {
        right: -8px;
    }
    .verify-phone-not-verify {
        width: 60px;
        height: 60px;
        font-size: 40px;
    }
    .verify-text {
        width: 220px;
        font-size: 13px;
    }
    .profile-name {
        padding-top: 15px;
    }
    .profile-name h2 {
        font-size: 16px;
    }
    .diamond-login-mobile {
        width: 100px;
    }
    .diamond-user {
        font-size: 20px;
    }
    .diamond-user .diamond-icon img {
        width: 40px;
    }
    .diamond-user .diamond-icon {
        margin-left: -19px;
    }

    /***** PROFILE TAB *******/
    .profile-mobile-tab .nav-pills .nav-item .nav-link {
        padding-left: 17px;
        padding-right: 17px;
        font-size: 11px;
        padding-top: 20px;
        padding-bottom: 50px;
        margin-bottom: -5px;
    }
    .profile-mobile-content h2 {
        font-size: 25px;
    }
    
    /***** PROFILE CHANGE PASSOWRD *******/
    .change-password-form .password-form-input {
        font-size: 18px;
    }
    .change-password-form .form-control {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 19px;
    }
    .change-password-form .form-error-content {
        font-size: 15px;
    }
    .change-password-form .checkbox-verify {
        font-size: 22px;
        top: 13px;
    }
    .change-password-form .checkbox-not-verify {
        font-size: 22px;
        top: 13px;
    }
    .profile-button-pill {
        width: 190px;
        height: 60px;
        font-size: 20px;
    }

    /***** PROFILE MANAGE PAYMENT *******/
    .profile-card-list-box {
        padding: 20px;
    }
    .profile-card-list-title {
        font-size: 15px;
    }
    .profile-card-list-content {
        font-size: 12px;
    }
    .profile-card-default {
        font-size: 10px;
    }
    .profile-remove-card-pill {
        width: 50px;
        height: 25px;
        font-size: 10px;
        margin: 1px;
        padding: 0 5px;
    }
    .profile-set-card-pill {
        width: 70px;
        height: 25px;
        font-size: 10px;
        margin: 1px;
        padding: 0 5px;
    }
    .add-payment-box {
        padding: 20px;
    }
    .add-payment-box-title {
        font-size: 15px;
    }
    .add-payment-box-content {
        flex-direction: column;
    }
    .add-payment-box-field1 {
        width: 100%;
        padding-right: 0;
        padding-bottom: 10px;
    }
    .add-payment-box-field2 {
        width: 100%;
        padding-right: 0;
        padding-bottom: 10px;
    }
    .add-payment-box-field3 {
        width: 100%;
        padding-right: 0;
        padding-bottom: 10px;
    }
    .profile-save-payment-pill {
        font-size: 15px;
        width: 100px;
        height: 35px;
        margin: 10px 0;
    }

    /***** PROFILE MANAGE SUBSCRIPTION *******/
    .profile-subscription-detail {
        font-size: 10px;
    }
    .profile-subscription-detail-subtitle {
        font-size: 8px;
    }
    .profile-subscription-cancel-tag {
        font-size: 9px;
    }

    /***** PROFILE BUY DIAMOND *******/
    .diamond-package-text-box h3 {
        font-size: 18px;
    }
    .diamond-package-timer {
        top: 38px;
        width: 115px;
        font-size: 15px;
    }
    .diamond-package-flash-sales {
        font-size: 15px;
    }
    .diamond-package-price {
        font-size: 15px;
    }
    .diamond-package-price .strikethrough {
        font-size: 13px;
    }

    /***** PROFILE HISTORY *******/
    .history-diamond-inner table thead tr th {
        font-size: 18px;
    }
    .history-diamond-inner table tbody tr td {
        font-size: 15px;
    }
    .more-history a {
        font-size: 18px;
    }

    /***** NAVIGATION TAB *******/
    .profile-bottom-section-mobile h3 {
        font-size: 25px;
    }
    .navigation-box {
        padding: 30px 20px;
    }
    .navigation-box span {
        font-size: 12.5px;
        margin-left: 3px;
        padding-left: 0;
    }

    /****** SINGLE ARTICLE LEFT *******/
    .article-text h1 {
        font-size: 15px;
    }
    .article-text ul li {
        font-size: 13px;
    }
    .article-content p {
        font-size: 15px;
        line-height: 1.3;
        margin: 0 0 20px 0;
    }
    .article-content h2 {
        font-size: 18px;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    .article-content figcaption {
        text-align: center;
        padding-bottom: 36px;
        font-size: 15px;
    }
    .sponsord-tag li a {
        font-size: 13px;
        height: 34px;
        padding: 0 10px;
    }
    .accordion-button {
        font-size: 13.5px;
    }
    .accordion-body {
        font-size: 12px;
    }
    .sponsord-add .media h4 {
        font-size: 17px;
    }
    .sponsord-survey-form h2 {
        font-size: 14px;
    }
    .survey-form-question-btm {
        font-size: 12px;
    }
    .survey-form-options-btm {
        font-size: 12px;
    }
    .article-latest-bonus-mobile-box {
        padding: 20px 40px;
    }
    .article-latest-bonus-mobile-inner {
        max-width: 360px;
        padding: 10px;
    }
    .article-latest-bonus-mobile-logo {
        width: 40px;
    }
    .article-latest-bonus-mobile-title {
        font-size: 15px;
    }
    .article-latest-bonus-mobile-bottom {
        font-size: 12px;
    }
    .article-latest-bonus-mobile-button {
        padding: 2px;
        font-size: 10px; 
    }

    /****** SINGLE ARTICLE POLL LEFT *******/
    .survey-form-float {
        display: none;
        width: 270px;
    }
    .survey-form-float-title {
        font-size: 19px;
    }
    .survey-form-float-question {
        font-size: 18px;
    }
    .survey-form-float-options {
        font-size: 17px;
    }
    .survey-form-float-triangle-click img, .survey-form-float-triangle-click-close img {
        height: 40px;
    }
    .survey-form-float-triangle-click, .survey-form-float-triangle-click-close {
        right: -20px;
    }
    .survey-form-float-options input {
        height: 9px;
        width: 9px;
    }
    .survey-form-float-mobile-gesture {
        width: 35px;
    }
    .survey-form-float-mobile-gesture-close {
        right: -60px;
        top: 45%;
        width: 35px;
    }

    /****** SINGLE ARTICLE RIGHT *******/
    .latest-news h2 {
        font-size: 17px;
    }
    .latest-news-box .text-box h4 {
        font-size: 12px;
        -webkit-line-clamp: 2;
    }

    /***** Vote Ranking *****/
    .vote-ranking-diamondRankingBoard{
        margin-top: 30px;
    }
    .vote-ranking-diamondRankingBoard-board{
        margin-top: unset;
    }
    .vote-ranking-diamondRankingBoard-title-image{
        width: 60px;
    }
    .vote-ranking-diamondRankingBoard-title-icon{
        font-size: 28px;
        margin-left: 5px;
    }
    .vote-ranking-diamondRankingBoard-board-mobileItem-bottom{
        padding: 10px;
    }
    .vote-ranking-diamondRankingBoard-board-mobileItem-image{
        width: 200px;
        height: 200px;
    }
    .vote-ranking-diamondRankingBoard-mobile-modal-voteConfirm{
        padding: 0 0px;
    }
    .vote-ranking-diamondRankingBoard-mobile-modal-closeButton-absolute {
        top: -8px;
    }
    .vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-remainWallet{
        margin-left: 10px;
        padding: 5px 0px 5px 12px;
        width: 120px;
    }
    .vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-remainWallet-image{
        left: -23.5px;
        width: 47px;
    }

    /***** VIDEO MODAL *******/
    .modal-video .next-btn {
        font-size: 15px;
    }

    /* grade-ranking */
    .ranking-casino-item-image{
        margin-bottom: 5px;
    }
    .award-best-casino-item-outer{
        width: calc(100% - 10px);
        margin: 5px;
    }
    .award-best-casino-swiper{
        padding: 10px 10px 50px;
    }
    .ranking-year-category-box > .custom_container {
        padding: 0 40px;
    }
    .ranking-year-category-box-item{
        padding: 5px 0;
    }
    .ranking-year-category-box .swiper-button-next{
        right: 5px;
        padding: 0 15px;
    }
    .ranking-year-category-box .swiper-button-prev{
        left: 5px;
        padding: 0 15px;
    }
    .ranking-year-category-box .swiper-button-next:after, .ranking-year-category-box .swiper-button-prev:after{
        font-size: 20px;
    }
    .ranking-casino-item-content-vote-star{
        width: 30px;
        height: 30px;
    }
    .ranking-casino-item-rank{
        width: 55px;
        height: 55px;
    }
    .ranking-casino-item{
        padding: 35px 25px 25px;
    }
    .ranking-casino-item-content-right-button{
        padding: 15px 0;
    }

    /* complaint - list */
    .complaintList-page-item{
        padding: 20px;
        margin: 10px 0;
    }
    .complaintList-page-complaint-search-input{
        padding: 0px 45px 0px 15px !important;
    }
    /****** SINGLE REVIEW *******/
    .single-payment-tab .nav-pills .nav-item .nav-link {
        padding: 22px 0;
        font-size: 18px;
    }
    .basic-detail ul li {
        font-size: 16px;
    }
    .basic-detail ul li span {
        margin-right: 0;
    }
    .mobile-single-review-box-date{
        font-size: 15px;
    }

    /***** PAYMENT REVIEW BOX *******/
    .loading-speed {
        padding: 50px 45px;
        margin: 35px 10px;
    }
    .loading-speed .text-inner h4 {
        margin-top: 15px;
        font-size: 25px;
    }
    .loading-speed .text-inner p {
        font-size: 20px;
        margin: 35px 30px 25px;
    }
    .loading-speed .swiper-counter {
        font-size: 27px;
        top: 10px;
    }
    .loading-speed .text-inner a {
        font-size: 20px;
        margin: 30px 0px 10px;
    }
    .loading-speed .text-inner a img{
        width: 65px;
    }
    .loading-speed ul li {
        font-size: 19px;
    }
    .loading-speed .swiper-button-prev:after, .loading-speed .swiper-button-next:after {
        font-size: 45px;
    }

    /* complaint - single */
    .complaintSingle-content-status-category-item-image{
        width: 30px;
        height: 30px
    }
    .complaintSingle-content-status-category-item > .custom_h4 {
        font-size: 10px;
        text-align: center;
    }
    .complaintSingle-content-status-category-list {
        padding: 0 10px 0 0;
        margin: 15px 0 0 0
    }
    .mobile-complaintSingle-content-status-category-position{
        grid-template-columns: 90px 1fr;
    }
    .complaintSingle-content-status-category-item{
        margin: 5px 0;
        padding: 10px 5px;
    }
    .complaintSingle-content-item{
        padding: 20px 10px;
        margin-top:20px;
    }
    .complaintSingle-content-item-right-title-row{
        margin: 20px 0;
    }
    .complaintSingle-content-item-right-contentBox-image, .complaintSingle-content-item-collapse-box-content-image{
        width: 50px;
    }
    .complaintSingle-content-item-collapse-box{
        padding: 20px 10px;
    }
    .complaintSingle-content-item-left-image{
        width: 120px;
        height: 120px;
    }
    .complaintSingle-content-item-left-text{
        font-size: 80px;
    }
    .complaintSingle-header-left-image {
        width: 170px;
        height: 170px;
    }

    /* complaint submit */
    .complaint-submit-modal-inner{
        padding: 20px !important; 
    }
    .dropzone-input-box-inner{
        padding: 16px 5px !important;
    }

    /* checkout payment */
    .checkout-payment-card-box-1-inner {
        padding: 10px;
    }
    .checkout-payment-card-inner .form-control{
        padding: 2px 10px;
        font-size: 14px;
    }
    .checkout-payment-card-box-1-row1{
        grid-gap: 5px;
    }
    .checkout-payment-card-box-1, .checkout-payment-card-box-2{
        width: 80%;
    }
    .checkout-payment-card-box-1{
        left: -10px
    }
    .checkout-payment-card-box-2{
        right: -10px;
    }
    .checkout-payment-card-box-2-ccv{
        right: 2%;
        left: 68%;
    }
    .checkout-payment-purchaseSummary-body {
        padding: 20px 20px 30px;
        background-color: #e2e2e2;
    }
    .checkout-payment-purchaseSummary-title{
        padding: 15px 20px;
    }
    .checkout-payment-purchaseSummary-body-img2-text{
        font-size: 70%;
    }
    .checkout-payment{
        grid-gap: 30px;
        margin-top: unset;
    }
    /* checkout payment v2 */
    .checkout-payment-img{
        width: 200px;
    }
    .checkout-payment-card-button {
        width: 200px;
        max-width: unset;
    }
    .checkout-payment-card-button {
        padding: 10px 0;

    }

    /* star vote */
    .ranking-casino-item-content-vote-star {
        width: 20px;
        height: 20px;
        display: flex;
    }

    /* you may like this */
    .you-may-like-this-item{
        padding: 10px;
        grid-gap: 5px;
    }
    .you-may-like-this-item-image {
        width: 100px;
        height: 100px;
    }
    .you-may-like-this-item .custom_h3, .you-may-like-this-item .custom_h4 {
        font-size: 14px;
    }

    /* unsubscribe */
    .unsubscribe-image {
        width: 80px;
    }

    /* why ugrado */
    .why-ugrado-item-image {
        width: 55px;
    }
    .why-ugrado-item-large .why-ugrado-item-image{
        width: 65px;
    }
    .why-ugrado-item-content > .custom_h4 {
        font-size: 14px;
    }
    .why-ugrado-item-content-icon {
        font-size: 15px;
    }
    .why-ugrado-item {
        padding: 15px 10px;
        grid-gap: 10px;
    }
    .why-ugrado-item-content{
        grid-gap: 5px;
    }

    /* why trust us */
    .why-trust-us-swiper {
        padding: 30px 5px !important;
    }
    .why-trust-us-swiper-item {
        max-width: 85%;
        padding: 20px 5px 30px;
    }
    .why-trust-us-swiper-item-image {
        padding: 0 20%;
    }
    .why-trust-us-swiper-buttonLocation{
        width: 80px;
    }
    .why-trust-us-swiper-button-next:after, .why-trust-us-swiper-button-prev:after {
        font-size: 30px !important;
    }

    .call-action-sec {
        padding-top: 0;
    }

    /** Added By CM Start **/
    .cert-casino-title-mobile {
        font-size: 13px;
    }
    .cert-content-mobile-fontsize { 
        font-size: 18px;
    }
    .cert-casino-license-mobile {
        font-size: 18px;
    }
    .cert-casino-status-mobile {
        font-size: 17px;
    }
    .cert-casino-disclaimer-mobile {
        font-size: 8px;
    }
    .cert-casino-url-text-mobile {
        width: 200px;
    }
    .certificate-action-button-mobile-sec {
        padding: 10px 75px;
    }
    .certificate-button-mobile {
        height: 60px;
        font-size: 13px;
        margin-bottom: 20px;
    }
    /* BONUS */
    .bonus-inner-box-logo {
        width: 130px;
    }
    .bonus-inner-tag {
        font-size: 12px;
    }
    .bonus-inner-text-field {
        font-size: 15px;
    }
    .bonus-inner-game-type-image-field {
        width: 30px;
    }
    .bonus-inner-box-detail-mobile-box1, .bonus-inner-box-detail-mobile-box2, .bonus-inner-box-detail-mobile-box3, .bonus-inner-box-detail-mobile-box4 {
        height: 80px;
    }
    .bonus-inner-extra-content-mobile-expand {
        font-size: 12px;
        padding: 10px 30px;
    }
    .bonus-inner-extra-content-image {
        width: 30px;
    }
    .bonus-inner-box-button-mobile {
        width: 140px;
        padding: 10px 20px;
        font-size: 14px;
    }
    .bonus-subscribe-title {
        font-size: 15px;
    }
    /** Added By CM End **/
}

@media (max-width:374px) {
    :root{
        --fontSize1: 24px;
        --fontSize2: 20px;
        --fontSize3: 18px;
        --fontSize4: 14px;
        --fontSize5: 12px;
        --fontSize6: 10px;
    }
    .custom_title_icon_size {
        width: 35px;
        margin: unset;
    }
    .custom_container{
        padding: 0 25px;
    }
    .custom_border_gold_withWidth {
        width: 65px;
    }
    /****** GENERAL *****/
    .general-box {
        padding: 35px 27px 30px;
    }
    .general-box h1 {
        font-size: 26px;
    }
    .general-box p {
        font-size: 14px;
    }

    /****** HOMEPAGE *****/
    .homepage-img-trust {
        width: 80%;
    }
    .homepage-content-top-title {
        font-size: 17px;
    }
    .homepage-content-top {
        font-size: 11px;
    }
    .homepage-content-middle-title {
        font-size: 17px;
    }
    .homepage-content-middle-subtitle {
        font-size: 15px;
    }
    .homepage-content-middle-text {
        font-size: 9px;
    }
    .homepage-check {
        font-size: 10px;
        line-height: 1.1;
    }
    .homepage-faq {
        font-size: 12px;
    }
    .homepage-faq-ans {
        font-size: 11px;
    }
    .homepage_title{
        font-size: var(--fontSize1);
    }
    .homepage-recent-company-title{
        font-size: 11px;
    }
    .homepage-rank-box-rank-company{
        font-size: 18px;
    }
    .homepage-rank-box-diamond{
        width: 90px;
    }
    .homepage-rank-box-diamond h4{
        font-size: 18px;
    }

    /****** ABOUT US / CONTACT US / TERMS & CONDITIONS *****/
    .about-us-inner,
    .contact-us-inner,
    .tnc-inner {
        margin-bottom: 95px;
    }
    .about-us-inner .about-us-subtitle h2,
    .contact-us-inner .contact-us-subtitle h2,
    .tnc-inner .tnc-subtitle h2 {
        margin-bottom: 15px;
    }
    .about-us-inner h1,
    .contact-us-inner h1,
    .tnc-inner h1 {
        font-size: 26px;
        margin: 0px 0 15px 0;
    }
    .about-us-inner p,
    .contact-us-inner p,
    .tnc-inner p {
        font-size: 14px;
    }

    /***** NEWSLETTER *****/
    .call-action-mobile {
        padding-bottom: 90px;
    }
    .call-action-mobile h3 {
        font-size: 14px;
        padding: 0 10px;
    }
    .call-action-mobile form .form-control {
        font-size: 15px;
        height: 40px;
    }
    .call-action-mobile form button {
        margin-top: 10px;
        font-size: 15px;
        height: 50px;
    }
    .call-action-mobile p {
        font-size: 9px;
    }

    /****** MOBILE FOOTER *****/
    .mobile-footer ul li a img {
        height: 18px;
    }
    .mobile-footer ul li a span {
        font-size: 12px;
        margin: 5px 0 0 0;
    }
    .mobile-footer ul li.footer-review a img {
        height: 55px;
    }
    .mobile-footer {
        padding: 20px 5px;
    }
    .mobile-footer ul li.footer-review {
        margin-top: -37px;
    }

    /****** CONTACT US *****/
    .contact-us-inner {
        margin-bottom: 185px;
    }

    /****** GUIDE LISTING PAGE *****/
    .guide-inner {
        padding-bottom: 90px;
    }
    .guide-sport-box .text-box .text-box-inner p {
        font-size: 14px;
        line-height: 1.2;
    }
    .guide-swiper-btn .swiper-button-next, 
    .guide-swiper-btn .swiper-button-prev {
        width: 30px;
        height: 32px;
    }
    .guide-sport-box .text-box .text-box-inner ul li {
        font-size: 14px;
    }
    .guide-sport-list ul li a {
        font-size: 12px;
    }
    /* .guide-sport-list ul li a {
        height: 28px;
    } */
    .more-history {
        margin: 10px 0 0;
    }

    /***** MONTHLY SPONSORED *******/
    .monthly-sponsored-sec {
        padding-bottom: 60px;
    }
    .monthly-sponsored-title {
        padding-top: 0px;
    }
    .monthly-sponsored-img {
        max-width: 40px;
    }
    .monthly-sponsored-title h2 {
        font-size: 15px;
    }
    .monthly-sponsored-content {
        padding: 20px;
    }
    .marketing-parnership-section {
        padding: 0 20px;
    }
    .marketing-parnership-img {
        max-width: 30px;
        padding-left: 5px;
    }
    .marketing-parnership-content h2 {
        font-size: 13px;
    }
    .marketing-parnership-content p {
        font-size: 11px;
    }

    /***** REGISTER / LOGIN *******/
    .register-sec {
        padding-bottom: 120px;
    }
    .login-form-layout-title {
        font-size: 23px;
        line-height: 50px;
        margin-bottom: 0px;
    }
    .login-form-layout-message {
        font-size: 15px;
        margin-bottom: 20px;
        line-height: 22px;
    }
    .login-input-outline {
        margin-bottom: 15px;
    }
    .login-btn-pill-bg {
        width: 170px;
        height: 51px;
        font-size: 22px;
        line-height: 22px;
        margin-top: 20px;
        margin-bottom: 12px;
    }
    .login-input-outline .form-label {
        font-size: 17px;
    }
    .login-input-outline .form-control {
        line-height: 22px;
        font-size: 10px;
        padding-left: 12px;
    }
    .login-input-error {
        font-size: 10px;
    }
    .login-inputs .signup-checkbox-info {
        width: 220px;
    }
    .login-inputs .form-check-label {
        font-size: 10px;
    }
    .login-input-outline .select-left-input-right {
        font-size: 13px;
    }
    .login-input-outline .form-select {
        font-size: 13px;
    }
    .login-form-layout-message-otp {
        font-size: 25px;
        line-height: 25px;
        margin-bottom: 15px;
    }
    .login-form-layout-message-title-otp {
        font-size: 16px;
    }

    /***** USER PROFILE *******/
    .profile-tab-content {
        padding-top: 50px;
    }
    .profile-icon-mobile {
        width: 120px;
        height: 120px;
        margin-top: -70px;
    }
    .profile-icon-mobile img {
        height: 50px;
    }
    .profile-icon-mobile .profile-edit img {
        height: 40px !important;
    }
    .profile-status-mobile {
        padding: 20px 50px;
    }
    .verify-user {
        padding-left: 20px;
        margin: 0 auto;
    }
    .verify-phone-verify {
        width: 50px;
        height: 50px;
        font-size: 30px;
    }
    .verify-phone-verified {
        right: -11px;
    }
    .verify-phone-not-verify {
        width: 50px;
        height: 50px;
        font-size: 30px;
    }
    .profile-name {
        width: 100%;
    }
    .profile-name h2 {
        font-size: 16px;
    }
    .diamond-login-mobile {
        width: 100px;
    }
    .diamond-user {
        font-size: 20px;
    }
    .diamond-user .diamond-icon img {
        width: 40px;
    }
    .diamond-user .diamond-icon {
        margin-left: -19px;
    }

    /***** PROFILE TAB *******/
    .profile-mobile-tab .nav-pills .nav-item .nav-link {
        padding-left: 15px;
        padding-right: 15px;
        font-size: 8px;
        padding-top: 15px;
        padding-bottom: 45px;
    }
    .profile-mobile-content h2 {
        font-size: 17px;
    }
    
    /***** PROFILE CHANGE PASSOWRD *******/
    .change-password-form .password-form-input {
        font-size: 14px;
    }
    .change-password-form .form-control {
        padding-top: 5px;
        padding-bottom: 5px;
        font-size: 14px;
        padding-left: 15px;
    }
    .change-password-form .form-error-content {
        font-size: 11px;
    }
    .change-password-form .checkbox-verify {
        font-size: 18px;
        top: 9px;
        right: 30px;
    }
    .change-password-form .checkbox-not-verify {
        font-size: 18px;
        top: 9px;
        right: 30px;
    }
    .profile-button-pill {
        width: 127px;
        height: 50px;
        font-size: 12px;
    }

    /***** PROFILE MANAGE PAYMENT *******/
    .profile-card-list-box {
        padding: 15px;
    }
    .profile-card-list-title {
        font-size: 13px;
    }

    /***** PROFILE MANAGE SUBSCRIPTION *******/
    .profile-subscription-detail {
        font-size: 9px;
    }
    .profile-subscription-detail-subtitle {
        font-size: 7px;
    }
    .profile-subscription-cancel-tag {
        font-size: 7px;
    }

    /***** PROFILE BUY DIAMOND *******/
    .diamond-package-text-box h3 {
        font-size: 16px;
    }
    .diamond-package-timer {
        top: 35px;
        width: 100px;
        font-size: 13px;
    }
    .diamond-package-flash-sales {
        font-size: 13px;
        padding-left: 10px;
    }
    .diamond-package-price {
        font-size: 13px;
    }
    .diamond-package-price .strikethrough {
        font-size: 11px;
        margin-left: 5px;
    }

    /***** PROFILE HISTORY *******/
    .history-diamond-inner table thead tr th {
        font-size: 13px;
    }
    .history-diamond-inner table tbody tr td {
        font-size: 12px;
    }
    .more-history a img {
        height: 20px;
    }
    .more-history a {
        font-size: 13px;
    }

    /***** NAVIGATION TAB *******/
    .profile-bottom-section-mobile h3 {
        font-size: 17px;
    }
    .navigation-box {
        padding: 20px 10px;
    }
    .navigation-box span {
        font-size: 11px;
        margin-left: 0;
    }

    /***** 404 page *******/
    .fof-text { /* Four O Four */
        font-size: 100px !important;
    }

    /****** SINGLE ARTICLE LEFT *******/
    .article-text {
        padding: 0 0 10px 0;
    }
    .article-text h1 {
        font-size: 15px;
    }
    .article-text ul li {
        font-size: 12px;
    }
    .article-content p {
        font-size: 12px;
        line-height: 1.2;
        margin: 0 0 20px 0;
    }
    .article-content h2 {
        font-size: 13px;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }
    .article-content figcaption {
        text-align: center;
        padding-bottom: 26px;
        font-size: 13px;
    }
    .sponsord-tag li a {
        font-size: 12px;
        height: 25px;
        padding: 0 8px;
    }
    .accordion-button {
        font-size: 13px;
    }
    .accordion-body {
        font-size: 11px;
    }
    .sponsord-add {
        padding: 8px 15px 20px 15px;
    }
    .sponsord-add .media h4 {
        font-size: 10px;
    }
    .sponsord-survey-form {
        padding: 8px 20px 30px 20px;
    }
    .sponsord-survey-form h2 {
        font-size: 12px;
    }
    .survey-form-question-btm {
        font-size: 11px;
    }
    .survey-form-options-btm {
        font-size: 11px;
    }

    /****** SINGLE ARTICLE POLL LEFT *******/
    .survey-form-float {
        display: none;
        width: 230px;
    }
    .survey-form-float-title {
        font-size: 15px;
    }
    .survey-form-float-question {
        font-size: 14px;
    }
    .survey-form-float-triangle-click img, .survey-form-float-triangle-click-close img {
        height: 30px;
    }
    .survey-form-float-triangle-click, .survey-form-float-triangle-click-close {
        right: -15px;
    }
    .survey-form-float-options input {
        height: 7px;
        width: 7px;
    }
    .survey-form-float-options {
        font-size: 12px;
    }
    .survey-form-float-mobile-gesture {
        width: 35px;
    }
    .survey-form-float-mobile-gesture-close {
        right: -50px;
        top: 45%;
        width: 35px;
    }

    /****** SINGLE ARTICLE RIGHT *******/
    .latest-news h2 {
        font-size: 13px;
    }
    .latest-news-box .text-box h4 {
        font-size: 10px;
        -webkit-line-clamp: 2;
    }
    .latest-news-box .text-box {
        padding-top: 7px;
        padding-bottom: 9px;
        padding-right: 14px;
    }

    /***** Vote Ranking *****/
    .vote-ranking-diamondRankingBoard-board-mobileItem-image{
        width: 180px;
        height: 180px;
    }
    .vote-ranking-diamondRankingBoard-board-item-column-dropdown-box-vote-button{
        width: 52px;
    }
    .vote-ranking-diamondRankingBoard-mobile-modal-closeButton {
        font-size: 18px;
    }
    .vote-ranking-diamondRankingBoard-mobile-modal-closeButton-absolute{
        right: -15px;
    }

    /* grade-ranking */
    .ranking-year-top10 .custom_h2{
        font-size: 25px;
    }
    /****** SINGLE REVIEW *******/
    .single-payment-tab .nav-pills .nav-item .nav-link {
        padding: 22px 0;
        font-size: 18px;
    }

    /***** PAYMENT REVIEW BOX *******/
    .payment-review-box .media .ratting {
        max-width: 180px;
    }
    .payment-review-box .text-box h1 {
        font-size: 27px;
    }
    .payment-review-box .text-box p {
        font-size: 19px;
    }
    .loading-speed {
        padding: 45px 40px;
    }
    .loading-speed .text-inner h4 {
        font-size: 16px;
    }
    .loading-speed .text-inner p {
        font-size: 15px;
    }
    .loading-speed .swiper-counter {
        font-size: 15px;
        top: 10px;
    }
    .loading-speed .text-inner a {
        font-size: 15px;
        margin: 30px 0px 10px;
    }
    .loading-speed .text-inner a img{
        width: 45px;
    }
    .loading-speed ul li {
        font-size: 12px;
        padding: 12px 22px;
    }
    .loading-speed .swiper-button-prev:after, .loading-speed .swiper-button-next:after {
        font-size: 35px;
    }
    .single-review-box{
        padding: 0;
    }

    /* complaint - single */ 
    .complaintSingle-content-status-category-item-image {
        width: 25px;
        height: 25px;
    }
    .complaintSingle-content-status-category-item {
        margin: 5px 0px;
        padding: 5px 2px;
    }
    .complaintSingle-header-box{
        padding: 15px;
    }
    .complaintSingle-header-content-left > .custom_p{
        font-size: 14px;
    }

    /* complaint - submit */
    .complaint-submit-icon {
        font-size: 100px;
    }

    /* checkout payment */
    .checkout-payment-purchaseSummary-body-img2-text {
        font-size: 65%;
    }
    .checkout-payment{
        margin-top: unset;
        grid-gap: 30px;
    }
    .checkout-payment-card-box-1-inner {
        padding: 5px;
    }
    .checkout-payment-card-box-2{
        bottom: -15%;
    }
    .checkout-payment-card-box-2-ccv{
        top: 45%;
        right: 4%;
        left: 60%;
    }

    /* you may like this */
    .you-may-like-this-item-button{
        max-width: 200px;
    }

    .homepage-content-middle-seeMore > .custom_h4{
        font-size: 16px; 
    }
    .homepage-content-middle-seeMore-icon {
        font-size: 18px;
    }

    /* why trust us */
    .why-trust-us-swiper-item {
        max-width: 200px;
        padding: 20px 20px 30px;
    }
    /** Added By CM Start **/
    .cert-casino-detail-logo {
        /* width: 20%;
        height: 20%; */
    }
    .cert-casino-url-text-mobile {
        width: 250px;
    }
    .cert-casino-title-mobile {
        font-size: 12px;
    }
    .cert-content-mobile-fontsize { 
        font-size: 12px;
    }
    .cert-casino-license-mobile {
        font-size: 12px;
    }
    .cert-casino-status-mobile {
        font-size: 12px;
    }
    .cert-casino-disclaimer-mobile {
        font-size: 6px;
    }
    /* cert */
    .certificate-action-button-mobile-sec {
        padding: 20px 50px;
    }
    .certificate-button-mobile {
        height: 50px;
        margin-bottom: 15px;
        font-size: 13px;
    }
    /** Added By CM End **/
}
