.odometer.odometer-auto-theme {
    display: inline-block;
    vertical-align: middle; 
}

.odometer.odometer-auto-theme .odometer-digit,
.odometer.odometer-auto-theme .odometer-formatting-mark {
    margin-right: 5px;
 }

.odometer.odometer-auto-theme .odometer-digit {
   border-radius: 4px;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-value {
    padding-top: 2px;
    font-size: 48px;
 }

.odometer.odometer-auto-theme .odometer-formatting-mark {
    color: #fff;
    padding-left: 2px;
    padding-right: 2px;
    font-size: 30px;
 }

 .odometer.odometer-auto-theme, .odometer.odometer-theme-default{
   line-height: 1 !important;
 }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-default .odometer-digit .odometer-digit-inner{
    position: unset;
}

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-default .odometer-digit .odometer-digit-spacer{
    /* position: absolute; */
}